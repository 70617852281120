import { Typography } from '@imagene/components';
import classes from './reportText.module.css';

interface ReportTextProps {
  report: string;
}

export function ReportText(props: ReportTextProps) {
  return (
    <Typography
      class={classes.container}
      component="pre"
      aria-label="report_content"
    >
      {props.report}
    </Typography>
  );
}
