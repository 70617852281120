import { WsiState } from '@prisma/client';
import { createEffect, createSignal } from 'solid-js';
import { createStore } from 'solid-js/store';
import UploadWorker from '../../../utils/upload-web-worker?worker';
import { UploadOutput } from '../../../utils/upload-worker-utils';
import { useToast } from '@imagene/components';
import { useUpload } from '../../../utils/use-upload';
import { useBatches } from '../../Batches/use-batches';
import { datadogLogs } from '@datadog/browser-logs';
import { usePreventPageClose } from '../../../utils/use-prevent-page-close';
import { useExtendSession } from '../../../utils/use-extend-session';
import { useAnalysisCreation } from '../use-create-analysis';

export interface BatchUploadProps {
  images: {
    name: string;
    state: WsiState;
    wsi_id: string;
    total?: number;
    completed?: number;
    percentage?: number;
  }[];
  dataset_id: string;
  onSubmitError: VoidFunction;
}

export function useBatchUpload(props: BatchUploadProps) {
  const [store, setStore] = createStore<BatchUploadProps>(props);
  const [details] = useAnalysisCreation();
  const [currentUploadIndex, setCurrentUploadIndex] = createSignal(0);
  const { toast } = useToast();
  const { setIsUploading } = useUpload();
  const { submitBatch } = useBatches({
    onSubmitError: props.onSubmitError,
  });
  let errorToastIndex: number | null = null;
  usePreventPageClose();
  useExtendSession();

  const isLastIndex = () => currentUploadIndex() === store.images.length;

  const worker = new UploadWorker();

  createEffect(() => {
    if (store.images[currentUploadIndex()]?.state === 'INIT') {
      const wsi = store.images[currentUploadIndex()];
      const file = details.files.find((f) => f.name === wsi.name);
      worker.postMessage({ wsi_id: wsi.wsi_id, file });

      setIsUploading(true);

      datadogLogs.logger.info(`starting upload for file ${file?.name}`, {
        dataset_id: details.dataset_id,
        wsi_id: wsi.wsi_id,
      });

      setStore('images', currentUploadIndex(), 'state', 'IN_PROGRESS');
      return;
    }

    if (
      store.images.every(
        (wsi) => wsi.state === 'ERROR' || wsi.state === 'TIMEOUT'
      )
    ) {
      if (errorToastIndex === null) {
        errorToastIndex = toast({
          title: 'Upload error',
          type: 'error',
          onDismiss: () => (errorToastIndex = null),
        });
        datadogLogs.logger.error(
          `tried to upload ${store.images.length} slides but none were uploaded`,
          {
            dataset_id: details.dataset_id,
          }
        );
        setIsUploading(false);
      }
      return;
    }
    if (
      store.images.every(
        (wsi) => wsi.state !== 'INIT' && wsi.state !== 'IN_PROGRESS'
      ) &&
      isLastIndex()
    ) {
      const successfulUploads = store.images.filter((i) => i.state === 'DONE');
      datadogLogs.logger.log(
        `${successfulUploads.length} slides uploaded successfully out of ${store.images.length}`,
        {
          dataset_id: details.dataset_id,
        }
      );
      submitBatch.mutate({
        dataset_id: store.dataset_id,
        panel_id: details.panel.id,
        biomarkers: details.panel.biomarkers?.map((b) => b.id) ?? [],
      });
    }
  });

  worker.onmessage = (ev: MessageEvent<UploadOutput>) => {
    if (!ev.data) return;
    const { type, wsi_id } = ev.data;
    const wsi = store.images.find((i) => i.wsi_id === wsi_id);
    if (!wsi) return;
    const index = store.images.indexOf(wsi);

    if (type === 'DONE') {
      datadogLogs.logger.log(`wsi [${wsi_id}] upload done`, {
        wsi_id,
        dataset_id: details.dataset_id,
      });
      setStore('images', index, 'state', 'DONE');
      setCurrentUploadIndex(currentUploadIndex() + 1);
    }

    if (type === 'ERROR') {
      datadogLogs.logger.error(`wsi [${wsi_id}] upload error`, {
        wsi_id,
        dataset_id: details.dataset_id,
      });
      setStore('images', index, 'state', 'ERROR');
      setCurrentUploadIndex(currentUploadIndex() + 1);
    }

    if (type === 'PROGRESS') {
      setStore('images', index, 'percentage', ev.data.percentage);
    }

    if (type === 'TIMEOUT') {
      datadogLogs.logger.error(`wsi [${wsi_id}] upload timedout`, {
        wsi_id,
        dataset_id: details.dataset_id,
      });
      setStore('images', index, 'state', 'TIMEOUT');
      setCurrentUploadIndex(currentUploadIndex() + 1);
    }

    if (type === 'PART_DONE') {
      const { total, partNumber, success } = ev.data;
      datadogLogs.logger.info(
        `uploaded part ${partNumber}/${total} ${
          success ? 'successfully' : 'with errors'
        } for wsi [${wsi_id}]`,
        {
          wsi_id,
          dataset_id: details.dataset_id,
        }
      );
    }
  };

  return store;
}
