import { Transition } from 'solid-transition-group';
import { Typography } from '@imagene/components';
import classes from './about.module.css';

export function About() {
  return (
    <Transition appear name="fade">
      <div
        classList={{
          [classes.container]: true,
          ['page-margin']: true,
        }}
      >
        <div class={classes.section}>
          <Typography weight="semi-bold" component="h1" class={classes.title}>
            About ImageneOI
          </Typography>
          <Typography weight="thin" component="div">
            ImageneOI is an AI-based platform designed for rapid biomarker
            profiling directly from digital images of H&E-stained tissue. Gene
            alteration detection is performed within minutes from uploading the
            whole slide image, with no specific equipment or complex training
            required.
            <br />
            <br />
            ImageneOI currently includes NSCLC biomarker profiling. Intended for
            use with H&E images of FFPE NSCLC tumor specimens obtained by biopsy
            or surgical procedures.
          </Typography>
        </div>
        <div class={classes.section}>
          <Typography weight="semi-bold" component="h1" class={classes.title}>
            About Imagene
          </Typography>
          <Typography weight="thin" component="div">
            Imagene is an emerging leader in AI-based precision oncology
            leveraging AI to profile, within minutes, a broad range of cancer
            biomarkers from a digitized biopsy image alone. Imagene empowers
            physicians with immediate oncology intelligence to facilitate
            optimal treatment for more cancer patients. Our success derives from
            our cutting-edge technology and multidisciplinary team of data
            scientists, biologists, software engineers, and medical experts.
            With a commitment to scientific excellence and collaboration,
            Imagene is dedicated to improving patient outcomes and advancing
            precision oncology.
          </Typography>
        </div>
        <div classList={{ [classes.section]: true, [classes.address]: true }}>
          <Typography weight="thin">
            ImageneOI and the Lung (NSCLC) AI-models are developed by Imagene AI
            Inc.
            <br />
            2400 Market St., No.200 Philadelphia, PA 19103, USA
          </Typography>
          <Typography weight="thin" class={classes.links}>
            <a href="mailto:info@imagene-ai.com" target="_blank">
              info@imagene-ai.com
            </a>
            <a href="https://imagene-ai.com/" target="_blank">
              www.imagene-ai.com
            </a>
          </Typography>
        </div>
        <div class={classes.footer}>
          <Typography weight="normal">
            For Research Use Only. Not for use in diagnostic procedures
          </Typography>
        </div>
      </div>
    </Transition>
  );
}
