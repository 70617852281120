import { createSignal } from 'solid-js';
import { CreateDatasetResponse } from '@imagene/api-interfaces';
import { Input, Typography } from '@imagene/components';
import { createMutation, useQueryClient } from '@tanstack/solid-query';
import { datadogLogs } from '@datadog/browser-logs';
import { axiosClient } from '../../../../utils/axios';
import {
  PageCardNavigation,
  PageCardNavigationEvents,
} from '../../../../components/PageCardNavigation';
import { InclusionCriteriaModal } from '../../../../components/InclusionCriteriaModal';
import { useTenant } from '../../../../utils/use-tenant';

import classes from '../../analysis.module.css';
import { useAnalysisCreation } from '../../use-create-analysis';
import { UploadBatchDetails } from '../../types';

export type BatchNamingProps = PageCardNavigationEvents & {
  onDecline: VoidFunction;
};

export function BatchNaming(props: BatchNamingProps) {
  let form: HTMLFormElement;
  const config = useTenant();
  const [details, setDetails] = useAnalysisCreation();
  const [criteriaOpen, setCriteriaOpen] = createSignal(false);
  const queryClient = useQueryClient();

  const createDataset = createMutation(
    () =>
      axiosClient
        .post<CreateDatasetResponse>('/api/v1/dataset', {
          name: details.datasetName,
          files: details.files.map((f) => ({
            name: f.name,
            size: f.size,
          })),
        })
        .then((res) => res.data),
    {
      onSuccess: (data: CreateDatasetResponse) => {
        setDetails('images', data.images);
        setDetails('dataset_id', data.dataset_id);
        props.onNext?.();
        datadogLogs.logger.info(
          `user submitted dataset "${details.datasetName}" [${data.dataset_id}] and started upload of ${data.images.length} files`,
          {
            dataset_id: data.dataset_id,
          }
        );

        queryClient.invalidateQueries(['quota']);
      },
    }
  );

  const isValid = () =>
    !createDataset.isLoading &&
    Boolean(details.datasetName) &&
    form.reportValidity();

  function onNext() {
    if (config().criteria_confirmation_enabled) {
      setCriteriaOpen(true);
    } else {
      onSubmit();
    }
  }

  function onSubmit() {
    createDataset.mutate();
  }

  return (
    <>
      <PageCardNavigation
        title="Add slides"
        description="Name the batch"
        subheader={<BatchNameSubheader {...details} />}
        nextLabel="Submit"
        onNext={onNext}
        valid={isValid()}
        onBack={props.onBack}
      >
        <form
          class={classes.naming}
          ref={(r) => (form = r)}
          onSubmit={(e) => e.preventDefault()}
        >
          <Typography weight="bold" component="h2">
            Name the batch
          </Typography>
          <Input
            label="Batch name"
            name="datasetName"
            placeholder="e.g. EGFR validation"
            required
            value={details.datasetName}
            onInput={(e) => {
              e.currentTarget.setCustomValidity('');
              setDetails('datasetName', e.currentTarget.value);
            }}
            onInvalid={(e) => {
              if (e.currentTarget.validity.patternMismatch) {
                e.currentTarget.setCustomValidity(
                  'Batch name can have maximum 50 characters'
                );
              }
            }}
            pattern=".{1,50}"
          />
        </form>
      </PageCardNavigation>
      <InclusionCriteriaModal
        open={criteriaOpen()}
        headline="Please confirm that selected slides meet all criteria"
        onClose={() => setCriteriaOpen(false)}
        onConfirm={onSubmit}
        onDecline={props.onDecline}
      />
    </>
  );
}

export function BatchNameSubheader(dataset: UploadBatchDetails) {
  return (
    <div class={classes.subheader}>
      <div class={classes['subheader-item']}>
        <Typography weight="semi-bold">Selected files</Typography>
        <Typography>{dataset.files.length}</Typography>
      </div>
      <div class={classes['subheader-item']}>
        <Typography weight="semi-bold">Panel</Typography>
        <Typography>{dataset.panel.label}</Typography>
      </div>
      <div class={classes['subheader-item']}>
        <Typography weight="semi-bold">Biomarkers</Typography>
        <Typography>
          {dataset.completePanel
            ? 'Complete panel'
            : dataset.panel?.biomarkers?.map((b) => b.label).join(', ')}
        </Typography>
      </div>
    </div>
  );
}
