export default function LogoIcon() {
  return (
    <svg
      id="Layer_2"
      data-name="Layer 2"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 71.07 112.24"
    >
      <g id="Layer_1-2" data-name="Layer 1">
        <g>
          <g>
            <path
              style={{ fill: 'currentcolor' }}
              d="m59.02,13.55c1.3,3.75,3.37,7.39,3.76,11.22.84,8.37-2.76,16.49-9.63,21.38-3.2,2.27-7.02,3.88-10.9,4.51-2.46.4-4.93.54-7.43.49-3.49-.08-9.08-.78-10.93,3.09-2.04,4.28,2.54,6.62,5.89,7.63,4.55,1.37,9,3.05,13.58,4.33,4.54,1.28,9.22,2.19,13.63,3.92,11.9,4.67,15.52,16.47,8.63,27.35-4.29,6.77-10.79,10.64-18.29,12.72-9.87,2.72-19.86,2.72-29.78.08C8.01,107.73,1.11,100.66.19,92.64c-.8-7.04.99-12.84,5.34-17.48,2.94-3.15,8.23-.09,6.96,4.02-.02.03-.02.06-.03.09-3.4,10.72,1.23,19.09,12.09,22.17,7.08,2.01,14.2,1.92,21.16-.45,2.51-.85,5.04-2.23,7.06-3.96,5.54-4.75,5.25-11.53-1.19-14.87-6.29-3.27-13.33-5.08-19.85-7.96-5.29-2.35-10.55-4.97-15.36-8.17-3.73-2.47-3.86-6.51-1.73-10.38,4.26-7.71,10.94-11.89,19.56-13.16,1.98-.3,4.01-.37,5.93-.87,10.16-2.68,14.87-13.03,10.16-22.77-.27-.57-.55-1.14-.74-1.74-2.17-6.74-.14-10.88,6.12-13.17,2.98-1.09,5.92-2.3,9.01-3.57,2.84-1.16,5.86.32,6.34,3.36.56,3.6-3.07,6.31-11.99,9.8Z"
            />
            <path
              style={{ fill: 'currentcolor' }}
              d="m42.73,2.48C24.33,9.51,10.97,19.98,9.58,40.63c-5.7-6.64-6.17-18.42-1.11-26.51C15.03,3.64,28.42-1.14,42.73,2.48Z"
            />
          </g>
          <path
            style={{ fill: 'currentcolor' }}
            d="m31.34,51.14c.49-.03.99.01,1.47.08-.49-.01-1-.12-1.47-.08Z"
          />
        </g>
      </g>
    </svg>
  );
}
