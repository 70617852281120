interface SearchIconProps {
  class?: string;
}

export default function SearchIcon(props: SearchIconProps) {
  return (
    <svg
      class={props.class ?? ''}
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.3396 17.5135L9.99062 11.1885C9.52743 11.5878 8.97639 11.8993 8.3375 12.1229C7.69861 12.3465 7.01979 12.4583 6.30104 12.4583C4.5441 12.4583 3.05469 11.8514 1.83281 10.6375C0.610937 9.42361 0 7.95417 0 6.22917C0 4.50417 0.610937 3.03472 1.83281 1.82083C3.05469 0.606945 4.52813 0 6.25313 0C7.97813 0 9.44757 0.606945 10.6615 1.82083C11.8753 3.03472 12.4823 4.50417 12.4823 6.22917C12.4823 6.91597 12.3705 7.57882 12.1469 8.21771C11.9233 8.8566 11.5958 9.45556 11.1646 10.0146L17.5375 16.3156L16.3396 17.5135ZM6.27708 10.8292C7.53889 10.8292 8.61302 10.378 9.49948 9.47552C10.3859 8.57309 10.8292 7.49097 10.8292 6.22917C10.8292 4.96736 10.3859 3.88524 9.49948 2.98281C8.61302 2.08038 7.53889 1.62917 6.27708 1.62917C4.98333 1.62917 3.88924 2.08038 2.99479 2.98281C2.10035 3.88524 1.65312 4.96736 1.65312 6.22917C1.65312 7.49097 2.10035 8.57309 2.99479 9.47552C3.88924 10.378 4.98333 10.8292 6.27708 10.8292Z"
        fill="white"
      />
    </svg>
  );
}
