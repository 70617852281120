import { createSignal } from 'solid-js';
import { Typography } from '@imagene/components';
import { CopyIcon } from '@imagene/components/icons/CopyIcon';
import classes from './copyReportButon.module.css';

interface CopyReportButtonProps {
  report: string;
  disable: boolean;
}

export function CopyReportButton(props: CopyReportButtonProps) {
  const [clicked, setClicked] = createSignal<boolean>(false);

  const handleClick = async (e: MouseEvent) => {
    e.stopPropagation();
    await navigator.clipboard.writeText(props.report);
    setClicked(true);
  };

  return (
    <div
      class={classes.container}
      classList={{
        [classes.black]: !props.disable && !!props.report,
        [classes.clicked]: clicked() && !props.disable && !!props.report,
        [classes.hover]: !clicked(),
      }}
      onClick={handleClick}
      aria-label="copy_report_btn"
    >
      <CopyIcon />
      <Typography
        class={classes.label}
        component="div"
        aria-label="copy_report_btn_text"
      >
        {clicked() ? 'Report Copied To Clipboard' : 'Copy Report'}
      </Typography>
    </div>
  );
}
