import type {
  InferenceResult,
  OfficialResultValue,
  Result,
} from '@prisma/client';

interface SortItem {
  sort_order?: number;
}

type ResultText = Partial<
  Pick<Result, 'probability_text' | 'result'> & {
    raw_probability: number;
  }
>;

export type SortableResult = SortItem & ResultText;
export type DisplayableResult = { visible?: boolean } & ResultText;
export type QuestionableResult = Omit<ResultText, 'raw_probability'>;

export interface ResultDisplayOptions {
  probabilityEnabled?: boolean;
  inconclusiveProbabilityEnabled?: boolean;
  isSectra?: boolean;
  isExport?: boolean;
}

export type AccuracyType = 'TP' | 'TN' | 'FP' | 'FN' | 'P' | 'N';
export type AccuracyTypeDisplay =
  | 'True Positive'
  | 'True Negative'
  | 'False Positive'
  | 'False Negative'
  | 'Positive'
  | 'Negative'
  | 'Equivocal Positive'
  | 'Equivocal Negative';

export function displayProbability(probability: number | null | undefined) {
  return Math.round((probability ?? 0) * 100);
}

export function displayText(
  data: DisplayableResult,
  options: ResultDisplayOptions
) {
  const { probabilityEnabled, inconclusiveProbabilityEnabled } = options;
  if (data.visible) {
    if (probabilityEnabled && data.probability_text) {
      return data.probability_text;
    }

    if (inconclusiveProbabilityEnabled) {
      if (data.result === 'EQUIVOCAL' && data.raw_probability) {
        const probability = displayProbability(data.raw_probability);
        if (options.isSectra) return `Equivocal: (${probability}%)`;
        if (options.isExport) return `${probability}%`;
        return `Equivocal: likelihood of positive result is ${probability}%`;
      }

      if (options.isExport) {
        return '';
      }
    }
    return transformReportResult(data.result);
  }
  if (
    options.isExport &&
    (probabilityEnabled || inconclusiveProbabilityEnabled)
  ) {
    return '';
  }

  return 'Not Tested';
}

export function transformReportResult(
  result: InferenceResult | null | undefined
): string {
  switch (result) {
    case 'POSITIVE':
      return 'Positive';
    case 'NEGATIVE':
      return 'Negative';
    case 'EQUIVOCAL':
      return '-';
    default:
      return '';
  }
}

export function isPositive(
  result: QuestionableResult,
  withBins: boolean | undefined
) {
  return withBins
    ? result.probability_text?.startsWith('>')
    : result.result?.toUpperCase() === 'POSITIVE';
}

export function isNegative(
  result: QuestionableResult,
  withBins: boolean | undefined
) {
  return withBins
    ? result.probability_text?.startsWith('<')
    : result.result?.toUpperCase() === 'NEGATIVE';
}

export function sortResults<T extends SortableResult>(
  results: T[],
  withBins: boolean | undefined
): T[] {
  const positives = results
    .filter((r) => isPositive(r, withBins))
    .sort(sortResultByOrder);

  const notPositives = results
    .filter((r) => !isPositive(r, withBins))
    .sort(sortResultByOrder);

  return positives.concat(notPositives);
}

export function sortResultByOrder(a: SortItem, b: SortItem) {
  if (
    typeof a.sort_order === 'undefined' ||
    typeof b.sort_order === 'undefined'
  )
    return 0;
  if (a.sort_order > b.sort_order) return 1;
  if (a.sort_order < b.sort_order) return -1;
  return 0;
}

export function getDefinitiveResult(
  result: QuestionableResult,
  options: ResultDisplayOptions
) {
  if (options.probabilityEnabled) {
    if (isPositive(result, true)) {
      return 'Positive';
    }
    if (isNegative(result, true)) {
      return 'Negative';
    }
    return 'Equivocal';
  }

  // override default behavior of EQUIVOCAL
  if (options.inconclusiveProbabilityEnabled && result.result === 'EQUIVOCAL') {
    return 'Equivocal';
  }
  return transformReportResult(result.result);
}

export function getAccuracy(
  molResult: OfficialResultValue | undefined | null,
  value: number | undefined,
  thresholds: {
    negative: number;
    positive: number;
  }
): AccuracyType | null {
  if (!molResult) return null;

  let aiResult = 'Equivocal';
  if (value && value >= thresholds.positive) {
    aiResult = 'POSITIVE';
  } else if (value && value <= thresholds.negative) {
    aiResult = 'NEGATIVE';
  }

  if (aiResult === 'POSITIVE' && molResult === 'POSITIVE') {
    return 'TP';
  }
  if (aiResult === 'POSITIVE' && molResult === 'NEGATIVE') {
    return 'FP';
  }
  if (aiResult === 'NEGATIVE' && molResult === 'NEGATIVE') {
    return 'TN';
  }
  if (aiResult === 'NEGATIVE' && molResult === 'POSITIVE') {
    return 'FN';
  }

  return molResult === 'POSITIVE' ? 'P' : 'N';
}

export const DEFAULT_THRESHOLDS = {
  egfr: [5, 44],
  alk: [2, 39],
};
