import classes from './accuracy.module.css';
import type { AccuracyType } from '@imagene/biomarker-results';

export function Accuracy(props: { value: AccuracyType }) {
  return (
    <span
      class={classes.accuracy}
      classList={{
        [classes.positive]: props.value === 'P',
        [classes.negative]: props.value === 'N',
        [classes.false]: props.value === 'FN' || props.value === 'FP',
      }}
    >
      {props.value}
    </span>
  );
}
