import { createSignal } from 'solid-js';
import axios from 'axios';
import { createMutation } from '@tanstack/solid-query';
import { ForgotPasswordDto, RecoveryResponse } from '@imagene/auth';
import { ForgotPasswordForm } from './ForgotPasswordForm';

import classes from './forgot-password.module.css';
import { datadogLogs } from '@datadog/browser-logs';

export function ForgotPassword() {
  const [message, setMessage] = createSignal('');

  const forgotPassword = createMutation(
    (data: ForgotPasswordDto) =>
      axios
        .post<RecoveryResponse>('/api/v1/profile/forgot-password', data, {
          baseURL: import.meta.env.BASE_URL,
        })
        .then((res) => res.data),
    {
      onSuccess: (m) => setMessage(m.message),
      onError: (error, data) => {
        setMessage('Something went wrong');

        datadogLogs.logger.error(
          `user failed forgot password, error: ${JSON.stringify(error)}`,
          {
            email: data.email,
          }
        );
      },
    }
  );

  return (
    <section class={classes.container}>
      <ForgotPasswordForm
        onSubmit={forgotPassword.mutate}
        message={message()}
      />
    </section>
  );
}
