import { createMemo, getOwner, onMount, runWithOwner } from 'solid-js';
import {
  Chart,
  Title,
  Tooltip as ChartJsTooltip,
  Legend,
  Colors,
  ChartOptions,
  Plugin,
  LinearScale,
} from 'chart.js';
import { Bar } from 'solid-chartjs';
import { Placement, Tooltip } from '@imagene/components';
import Info from '@imagene/components/icons/InfoIcon';

import classes from './validation.module.css';
import { render } from 'solid-js/web';

export interface KPI {
  expected: number;
  actual: number;
}

export interface PerformanceBarChartProps {
  npv: KPI;
  ppv: KPI;
  cohort: KPI;
  sensitivity: KPI;
  specificity: KPI;
  tenantName: string;
}

export function PerformanceBarChart(props: PerformanceBarChartProps) {
  const owner = getOwner();

  const chartData = createMemo(() => ({
    labels: ['Specificity', 'Sensitivity', 'NPV', 'PPV', 'Conclusive %'],
    datasets: [
      {
        label: 'Expected',
        data: [
          props.specificity.expected,
          props.sensitivity.expected,
          props.npv.expected,
          props.ppv.expected,
          props.cohort.expected,
        ],
        backgroundColor: 'hsla(0, 0%, 85%, 1)',
      },
      {
        label: props.tenantName,
        data: [
          props.specificity.actual,
          props.sensitivity.actual,
          props.npv.actual,
          props.ppv.actual,
          props.cohort.actual,
        ],
        backgroundColor: 'hsla(220, 9%, 46%, 1)',
      },
    ],
  }));

  const htmlLegendPlugin: Plugin = {
    id: 'htmlLegend',
    afterUpdate(chart) {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const ul = document.querySelector('#legend')!;

      // Remove old legend items
      while (ul.firstChild) {
        ul.firstChild.remove();
      }

      const items =
        chart.options.plugins?.legend?.labels?.generateLabels?.(chart);

      const newItems = runWithOwner(owner, () =>
        items?.map((item) => {
          const expected = {
            placement: 'top',
            text: 'Performance in Imagene validation cohort of 4000+ slides',
          };
          const tenant = {
            placement: 'top-end',
            text: 'Current performance on submitted slides with reported molecular results',
          };

          const current = item.text === 'Expected' ? expected : tenant;

          return (
            <li
              onClick={() => {
                if (
                  item.datasetIndex === null ||
                  item.datasetIndex === undefined
                )
                  return;

                chart.setDatasetVisibility(
                  item.datasetIndex,
                  !chart.isDatasetVisible(item.datasetIndex)
                );
                chart.update();
              }}
            >
              <Tooltip
                text={current.text}
                placement={current.placement as Placement}
              >
                <span
                  class={classes['legend-box']}
                  style={{ background: item.fillStyle as string }}
                ></span>
                <span
                  class={classes['legend-text']}
                  style={{
                    'text-decoration': item.hidden ? 'line-through' : '',
                    color: item.fontColor as string,
                  }}
                >
                  {item.text}
                </span>
                <Info />
              </Tooltip>
            </li>
          );
        })
      );

      render(() => newItems, ul);
    },
  };

  const chartOptions: ChartOptions<'bar'> = {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    barThickness: 27,
    scales: {
      y: {
        ticks: {
          callback: (value) => value + '%',
        },
        max: 100,
      },
    },
    plugins: {
      tooltip: {
        callbacks: {
          label: (ctx: { formattedValue: string }) => ` ${ctx.formattedValue}%`,
        },
      },
      legend: {
        display: false,
      },
    },
  };

  onMount(() => {
    Chart.register(
      LinearScale,
      Title,
      ChartJsTooltip,
      Legend,
      Colors,
      htmlLegendPlugin
    );
  });

  return (
    <div class={classes.barchart}>
      <ul id="legend" />
      <Information
        class={classes.specificity}
        text="% of molecular/actual Negative results identified by AI"
        placement="top-end"
      />
      <Information
        class={classes.sensitivity}
        text="% of molecular/actual Positive results detected by AI"
        placement="top-end"
      />
      <Information
        class={classes.npv}
        text="% of AI Negative results that are True Negative"
        placement="top-end"
      />
      <Information
        class={classes.ppv}
        text="% of AI Positive results that are True Positive"
        placement="top-end"
      />
      <Information
        class={classes.cohort}
        text="% of the cohort that received a Positive or a Negative result (excluding Equivocal results)"
        placement="top-end"
      />

      <Bar data={chartData()} options={chartOptions} height={400} />
    </div>
  );
}

function Information(props: {
  class: string;
  text: string;
  placement?: Placement;
}) {
  return (
    <div class={props.class}>
      <Tooltip text={props.text} placement={props.placement}>
        <div class={classes['tooltip-wrap']}>
          <Info />
        </div>
      </Tooltip>
    </div>
  );
}
