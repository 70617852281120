export default function ClockIcon() {
  return (
    <svg
      width="44"
      height="51"
      viewBox="0 0 44 51"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="22"
        cy="29"
        r="20.5"
        fill="#F4F2F8"
        stroke="#6745B1"
        stroke-width="3"
      />
      <line
        x1="14.2998"
        y1="1.5"
        x2="30.4998"
        y2="1.5"
        stroke="#6745B1"
        stroke-width="3"
        stroke-linecap="round"
      />
      <line
        x1="20.7002"
        y1="30.3008"
        x2="32.1002"
        y2="30.3008"
        stroke="#6745B1"
        stroke-width="3"
        stroke-linecap="round"
      />
      <line
        x1="20.1001"
        y1="30.3008"
        x2="20.1001"
        y2="18.9008"
        stroke="#6745B1"
        stroke-width="3"
        stroke-linecap="round"
      />
    </svg>
  );
}
