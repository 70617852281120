import { createSignal, Match, ParentProps, Show, Switch } from 'solid-js';
import { Typography } from '@imagene/components';
import { WorklistStage, WorklistStatus } from '@imagene/api-interfaces';
import { StatusIcons, StatusIndicator } from '../StatusIndicator';
import { ProgressMenu } from './ProgressMenu';
import { ProgressTable } from './ProgressTable';
import { useProgressStatus } from './useProgressStatus';

import classes from './progress.module.css';

export type ProgressIndicatorMode = 'full' | 'compact';
export interface ProgressIndicatorProps {
  mode: ProgressIndicatorMode;
  status: WorklistStatus | undefined;
  stage: WorklistStage | undefined;
}

export function ProgressIndicator(props: ParentProps<ProgressIndicatorProps>) {
  let singleProgress: HTMLDivElement;
  const [showDropdown, setShowDropdown] = createSignal(false);
  const statuses = useProgressStatus(props);

  const tableDisplayStatus = () => statuses.text();

  return (
    <Switch>
      <Match when={props.mode === 'compact'}>
        <div
          aria-label="progress_menu_anchor"
          class={classes.singleProgress}
          ref={(r) => (singleProgress = r)}
          onMouseEnter={() => {
            if (statuses.current() !== StatusIcons.done) {
              setShowDropdown(true);
            }
          }}
          onMouseLeave={() => {
            setShowDropdown(false);
          }}
        >
          <StatusIndicator
            ariaLabel="current_status_icon"
            classList={{
              [classes.ongoing]: statuses.current() === StatusIcons.ongoing,
            }}
            status={statuses.current()}
          />
          <Typography class={classes.indicatorText} component="div">
            {tableDisplayStatus()}
          </Typography>
        </div>
        <Show when={showDropdown()}>
          <ProgressMenu
            status={props.status}
            stage={props.stage}
            mode={'full'}
            node={() => singleProgress}
          />
        </Show>
      </Match>
      <Match when={props.mode === 'full'}>
        <ProgressTable
          analysisIcon={statuses.analysis()}
          uploadIcon={statuses.upload()}
          qcIcon={statuses.qc()}
        />
      </Match>
    </Switch>
  );
}
