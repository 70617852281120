import { Typography } from '@imagene/components';
import { StatusIcons, StatusIndicator } from '../StatusIndicator';
import { ParentProps } from 'solid-js';
import classes from './progress.module.css';

interface ProgressTableProps {
  uploadIcon: StatusIcons;
  qcIcon: StatusIcons;
  analysisIcon: StatusIcons;
}

export function ProgressTable(props: ParentProps<ProgressTableProps>) {
  return (
    <div class={classes.progressContainer} aria-label="progress_table">
      <StatusIndicator
        ariaLabel="upload_status_icon"
        class={classes.indicatorFile}
        status={props.uploadIcon}
        classList={{
          [classes.ongoing]: props.uploadIcon === StatusIcons.ongoing,
        }}
      />
      <Typography class={classes.indicatorTextFile} component="div">
        Image upload
      </Typography>
      <div
        class={
          props.qcIcon !== StatusIcons.unknown
            ? classes['purple-line']
            : classes.line
        }
      />
      <StatusIndicator
        ariaLabel="qc_status_icon"
        class={classes.indicatorQA}
        status={props.qcIcon}
        classList={{ [classes.ongoing]: props.qcIcon === StatusIcons.ongoing }}
      />
      <Typography class={classes.indicatorTextQA} component="div">
        Quality control
      </Typography>
      <div
        style={{
          'grid-column': 4,
          'grid-row': 1,
        }}
        class={
          props.analysisIcon !== StatusIcons.unknown
            ? classes['purple-line']
            : classes.line
        }
      />
      <StatusIndicator
        ariaLabel="analysis_status_icon"
        class={classes.indicatorAI}
        status={props.analysisIcon}
        classList={{
          [classes.ongoing]: props.analysisIcon === StatusIcons.ongoing,
        }}
      />
      <Typography class={classes.indicatorTextAI} component="div">
        Model analysis
      </Typography>
    </div>
  );
}
