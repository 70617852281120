import { createMutation } from '@tanstack/solid-query';
import { axiosClient } from './axios';
import { onCleanup } from 'solid-js';
import { useAuth } from './session';
import { DateTime } from 'luxon';

const ONE_HOUR_MS = 3600000;
const MAX_HOURS = 49;

export function useExtendSession() {
  let counter = 0;
  const { session } = useAuth();

  const loginISO = session()?.authenticated_at ?? '';

  counter = Math.round(
    DateTime.now().diff(DateTime.fromISO(loginISO), 'hours').hours
  );

  const mutation = createMutation(
    () => axiosClient.post('/api/v1/profile/session/extend'),
    {
      retry: 5,
      onSettled: () => counter++,
    }
  );

  const id = setInterval(() => {
    if (counter < MAX_HOURS) {
      mutation.mutate();
    }
  }, ONE_HOUR_MS);

  onCleanup(() => clearInterval(id));
}
