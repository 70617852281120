import { useNavigate } from '@solidjs/router';
import { useQueryClient } from '@tanstack/solid-query';
import { Show } from 'solid-js';
import { Spinner, Typography, useToast } from '@imagene/components';
import { LoginForm, LoginFormData } from './LoginForm';

import { authClient } from '../../utils/authClient';
import { useReturnTo, useReturnToQueryString } from '../../utils/use-return-to';
import { useFlow } from '../../utils/use-flow';
import * as Sentry from '@sentry/browser';
import { datadogLogs } from '@datadog/browser-logs';

import classes from './login.module.css';
import { useAuth } from '../../utils/session';

export function Login() {
  const returnTo = useReturnTo();
  const returnToQS = useReturnToQueryString(returnTo);
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { clearAllToasts } = useToast();
  const { setAAL2Required } = useAuth();

  const flow = useFlow({
    pageName: 'login',
    getter: (flowId) =>
      flowId
        ? authClient.getLoginFlow({ id: flowId }).then((res) => {
            // was added due to kratos version update see gitlab issue (https://gitlab.com/imagene-ai/dev/platform/-/issues/724)
            const isAAL2Required = res.data.requested_aal === 'aal2';
            if (isAAL2Required) {
              setAAL2Required(true);
              navigate(`/login/2fa${returnToQS}`, { replace: true });
            }
            return res.data;
          })
        : authClient.createBrowserLoginFlow().then((res) => res.data),
    submitter: (flowId, data: LoginFormData) =>
      authClient
        .updateLoginFlow({
          flow: flowId,
          updateLoginFlowBody: { method: 'password', ...data },
        })
        .then((res) => res.data),
    onSuccess: (res) => {
      queryClient.invalidateQueries(['whoami']);
      clearAllToasts();

      datadogLogs.setGlobalContextProperty(
        'user_email',
        res.session.identity?.traits['email']
      );
      datadogLogs.logger.debug('user logged in');

      Sentry.addBreadcrumb({
        category: 'auth',
        message: `User logged in`,
      });

      navigate(returnTo ?? '/');
    },
  });

  return (
    <Show when={flow.data()} fallback={<Spinner center />}>
      {(f) => (
        <div class={classes.container}>
          <Typography component="h1" weight="thin">
            Welcome to the ImageneOI
          </Typography>
          <Typography component="h2" weight="bold">
            Login
          </Typography>
          <LoginForm flow={f()} onSubmit={flow.submit} />
        </div>
      )}
    </Show>
  );
}
