import { createQuery } from '@tanstack/solid-query';
import { isAxiosError } from 'axios';
import { axiosClient } from './axios';
import { ParentProps, createContext, createSignal, useContext } from 'solid-js';
import { useAuthErrorHandler } from './authClient';
import { PlatformConfiguration } from '@imagene/api-interfaces';
import { useNavigate } from '@solidjs/router';

const [tenant, setTenant] = createSignal<PlatformConfiguration>(
  {} as unknown as PlatformConfiguration
);
const TenantContext = createContext(tenant);

export function useTenant() {
  return useContext(TenantContext);
}

export function TenantProvider(props: ParentProps) {
  const handleError = useAuthErrorHandler();
  const navigate = useNavigate();

  createQuery(
    () => ['tenant'],
    () =>
      axiosClient
        .get<PlatformConfiguration>('/api/v1/configuration')
        .then((res) => res.data),
    {
      onSuccess: (tenant) => {
        if (tenant.require_2fa && tenant.aal2_enrollment_required) {
          navigate('/login/enroll', { replace: true });
        }
        setTenant(tenant);
        return tenant;
      },
      onError: handleError,
      retry: (_, error) => {
        // do not retry if error status code is in 400-500 range
        return (
          isAxiosError(error) && !!error.response && error.response.status > 499
        );
      },
    }
  );

  return (
    <TenantContext.Provider value={tenant}>
      {props.children}
    </TenantContext.Provider>
  );
}
