export default function PositiveOutlinedIcon(props: { ariaLabel?: string }) {
  return (
    <svg
      aria-label={props.ariaLabel}
      width="19"
      height="19"
      viewBox="0 0 19 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="9.5"
        cy="9.5"
        r="8.5"
        fill="white"
        stroke="#48AF5F"
        stroke-width="2"
      />
      <path
        d="M5 10.2L7.86364 13L14 7"
        stroke="#48AF5F"
        stroke-width="2"
        stroke-linecap="round"
      />
    </svg>
  );
}
