import { Link } from '@solidjs/router';
import { useThresholds } from './use-thresholds';
import { Button } from '@imagene/components';
import { useValidation } from './use-validation';

export function PerformanceExport() {
  const validation = useValidation();
  const thresholds = useThresholds();
  return (
    <Link
      download
      href={`/api/v1/validation/export?alk_negative=${thresholds.values?.alk.at(
        0
      )}&alk_positive=${thresholds.values?.alk.at(
        1
      )}&egfr_negative=${thresholds.values?.egfr.at(
        0
      )}&egfr_positive=${thresholds.values?.egfr.at(1)}`}
    >
      <Button disabled={validation.isEmpty()}>Export Data</Button>
    </Link>
  );
}
