export default function AddFileIcon() {
  return (
    <svg
      width="46"
      height="62"
      viewBox="0 0 46 62"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M37.6483 60.5H8.00001C4.41015 60.5 1.5 57.5898 1.5 54V8C1.5 4.41015 4.41015 1.5 8 1.5H27.9174C29.6938 1.5 31.3927 2.22698 32.6193 3.51191L42.3502 13.7062C43.5044 14.9153 44.1483 16.5227 44.1483 18.1943V54C44.1483 57.5899 41.2382 60.5 37.6483 60.5Z"
        fill="#F4F2F8"
        stroke="#6745B1"
        stroke-width="3"
      />
      <path
        d="M30.6592 16.3516V1.36261L44.2856 16.3516H30.6592Z"
        fill="#6745B1"
      />
      <line
        x1="23.6348"
        y1="19.8958"
        x2="23.6348"
        y2="42.7971"
        stroke="#6745B1"
        stroke-width="3"
        stroke-linecap="round"
      />
      <line
        x1="11.7197"
        y1="30.8824"
        x2="34.6211"
        y2="30.8824"
        stroke="#6745B1"
        stroke-width="3"
        stroke-linecap="round"
      />
    </svg>
  );
}
