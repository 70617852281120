import { For } from 'solid-js';
import {
  ColumnDef,
  createSolidTable,
  flexRender,
  getCoreRowModel,
} from '@tanstack/solid-table';
import { Typography } from '..';

import classes from './simple-table.module.css';

export interface SimpleTableProps<TData> {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  columns: ColumnDef<TData, any>[];
  data: TData[];
  onDelete?: (index: number) => void;
  class: string;
  containerClass?: string;
}

export function SimpleTable<TData>(props: SimpleTableProps<TData>) {
  const table = createSolidTable({
    columns: props.columns,
    get data() {
      return props.data;
    },
    defaultColumn: {
      minSize: 0,
      size: Number.MAX_SAFE_INTEGER,
      maxSize: Number.MAX_SAFE_INTEGER,
    },
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <div
      classList={{
        [classes.wrapper]: true,
        [props.containerClass ?? '']: !!props.containerClass,
      }}
    >
      <table class={props.class}>
        <thead>
          <For each={table.getHeaderGroups()}>
            {(headerGroup) => (
              <tr id={headerGroup.id}>
                <For each={headerGroup.headers}>
                  {(header) => (
                    <th
                      id={header.id}
                      style={{
                        width:
                          header.getSize() === Number.MAX_SAFE_INTEGER
                            ? 'auto'
                            : `${header.getSize()}px`,
                      }}
                    >
                      {header.isPlaceholder ? null : (
                        <Typography>
                          {flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                        </Typography>
                      )}
                    </th>
                  )}
                </For>
              </tr>
            )}
          </For>
        </thead>
        <tbody>
          <For each={table.getRowModel().rows}>
            {(row) => (
              <tr id={row.id}>
                <For each={row.getVisibleCells()}>
                  {(cell) => (
                    <td id={cell.id}>
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </td>
                  )}
                </For>
              </tr>
            )}
          </For>
        </tbody>
      </table>
    </div>
  );
}
