export default function GreaterThanIcon() {
  return (
    <svg
      width="11"
      height="11"
      viewBox="0 0 11 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.512 0.295999H5.672L10.544 5.6L5.672 10.904H0.512L5.408 5.6L0.512 0.295999Z"
        fill="black"
      />
    </svg>
  );
}
