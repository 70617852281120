import {
  SettingsFlow,
  UpdateSettingsFlowWithPasswordMethod,
} from '@ory/client';
import { AuthInput, Button, Input, Typography } from '@imagene/components';
import { createEffect, createSignal, ParentProps, Show } from 'solid-js';
import { createStore } from 'solid-js/store';
import {
  passwordSchema,
  getUiNode,
  getUiNodeAttributes,
} from '../../utils/auth';
import {
  PasswordErrors,
  ValidationError,
} from '../ResetPassword/PasswordErrors';
import classes from './account-details.module.css';

type PasswordSettingsFlowValues = UpdateSettingsFlowWithPasswordMethod & {
  method: 'password';
};

interface ChangePasswordFormProps {
  flow: SettingsFlow;
  onSubmit: (values: PasswordSettingsFlowValues) => void;
}

export function ChangePasswordForm(
  props: ParentProps<ChangePasswordFormProps>
) {
  const csrf = getUiNodeAttributes(props.flow.ui.nodes, 'csrf_token');
  const [formValues, setFormValues] = createStore<
    PasswordSettingsFlowValues & { confirm_password: string }
  >({
    method: 'password' as const,
    password: '',
    csrf_token: csrf.value,
    confirm_password: '',
  });
  const [errors, setErrors] = createSignal<ValidationError[]>([]);

  const valid = () => errors().length === 0;
  const showErrors = () =>
    errors().length && (formValues.password || formValues.confirm_password);

  createEffect(() => {
    let errors = passwordSchema.validate(formValues.password, {
      details: true,
    }) as ValidationError[];

    if (formValues.password !== formValues.confirm_password) {
      errors = Array.isArray(errors)
        ? [{ validation: 'match' }, ...errors]
        : [{ validation: 'match' }];
    }
    setErrors(errors);
  });

  function onSubmit(e: SubmitEvent) {
    e.preventDefault();

    if (valid()) {
      props.onSubmit({ ...formValues });
    }
  }

  return (
    <form class={classes.card} onSubmit={onSubmit}>
      <div class={classes['card-header']}>
        <Typography weight="bold">Change Password</Typography>
      </div>
      <div
        classList={{ [classes['card-body']]: true, [classes.password]: true }}
      >
        <AuthInput
          label="New password"
          placeholder="New password"
          node={getUiNode(props.flow.ui.nodes, 'password')}
          onInput={(val) => setFormValues('password', val)}
          class={classes.input}
        />
        <Input
          label="Confirm new password"
          type="password"
          required
          placeholder="Confirm new password"
          onInput={(e) =>
            setFormValues('confirm_password', e.currentTarget.value)
          }
          class={classes.input}
        />
        <Button class={classes.btn} disabled={!valid()}>
          Update Password
        </Button>
        <Show
          when={showErrors()}
          //fallback provided to not break grid styling when not showing errors
          fallback={<div aria-label="errors-placeholder"></div>}
        >
          <PasswordErrors errors={errors()} class={classes.errors} />
        </Show>
      </div>
    </form>
  );
}
