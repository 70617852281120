import { ParentProps } from 'solid-js';
import classes from './skeleton.module.css';

export interface SkeletonProps {
  width?: number | string;
  height?: number | string;
  variant?: 'rectangle' | 'circular';
  class?: string;
}

export function Skeleton(props: ParentProps<SkeletonProps>) {
  const width =
    typeof props.width === 'string' || typeof props.width === 'undefined'
      ? props.width ?? '100%'
      : `${props.width}px`;

  let height = '';
  if (props.height) {
    height =
      typeof props.height === 'string' ? props.height : `${props.height}px`;
  }

  const style = {
    width,
    height,
    'border-radius': props.variant === 'circular' ? '50%' : '2px',
  };

  return (
    <div
      style={style}
      classList={{
        [classes.skeleton]: true,
        [props.class ?? '']: !!props.class,
      }}
    ></div>
  );
}
