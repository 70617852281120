import { createUniqueId, JSX, Show } from 'solid-js';
import FilledErrorIcon from '../../icons/FilledErrorIcon';
import classes from './Input.module.css';

type InputBaseProps = {
  id?: string;
  required?: boolean;
  class?: string;
  label?: string;
  error?: string;
  hideErrorIcon?: boolean;
  disableOptionalTxt?: boolean;
};

type BaseInput = {
  children: (id: string) => JSX.Element;
} & InputBaseProps;

function BaseInput(props: BaseInput) {
  const id = props.id ?? createUniqueId();

  const optionalLabel = () =>
    props.required || props.disableOptionalTxt ? '' : '(Optional)';

  return (
    <div
      classList={{
        [classes.container]: true,
        [props.class ?? '']: !!props.class,
      }}
    >
      <label for={id} class={classes.label}>
        {props.label} <span>{optionalLabel()}</span>
      </label>
      <span class={classes.wrapper}>
        {props.children(id)}
        <Show when={props.error && !props.hideErrorIcon}>
          <FilledErrorIcon />
        </Show>
      </span>
      <div aria-errormessage={props.error} class={classes.error}>
        {props.error}
      </div>
    </div>
  );
}

export type InputProps = JSX.InputHTMLAttributes<HTMLInputElement> &
  InputBaseProps;

export function Input(props: InputProps) {
  return (
    <BaseInput {...props}>
      {(id) => (
        <input
          {...props}
          id={id}
          class={classes.input}
          classList={{ [classes.error]: !!props.error }}
        />
      )}
    </BaseInput>
  );
}

export type TextAreaProps = JSX.TextareaHTMLAttributes<HTMLTextAreaElement> &
  InputBaseProps & { resize?: 'horizontal' | 'vertical' | 'none' };

export function TextArea(props: TextAreaProps) {
  return (
    <BaseInput {...props}>
      {(id) => (
        <textarea
          {...props}
          id={id}
          class={classes.input}
          classList={{
            [classes.error]: !!props.error,
            [classes['resize-horizontal']]: props.resize === 'horizontal',
            [classes['resize-vertical']]: props.resize === 'vertical',
            [classes['resize-none']]: props.resize === 'none',
          }}
        />
      )}
    </BaseInput>
  );
}
