import { Outlet } from '@solidjs/router';
import { Transition } from 'solid-transition-group';
import { SideBySide } from '../SideBySide';

import classes from './login-layout.module.css';
import { Typography } from '@imagene/components';
import { ImageneOILogo } from '@imagene/components/icons/ImageneOILogo';

export function LoginLayout() {
  return (
    <Transition name="fade">
      <SideBySide
        class={classes.layout}
        left={
          <div class={classes['login-sidebar']}>
            <ImageneOILogo />
            <Typography weight="thin" class={classes['login-sidebar-text']}>
              Immediate Oncology Intelligence
            </Typography>
          </div>
        }
        right={
          <div class={classes.container}>
            <Outlet />
          </div>
        }
      />
    </Transition>
  );
}
