import { createEffect, onCleanup, onMount, ParentProps, Show } from 'solid-js';
import { Progress, Typography } from '@imagene/components';
import ErrorIcon from '@imagene/components/icons/ErrorIcon';
import { useWsiUpload } from './use-wsi-upload';
import { useUpload } from '../../../utils/use-upload';
import { usePreventPageClose } from '../../../utils/use-prevent-page-close';
import { PageCardNavigation } from '../../../components/PageCardNavigation';
import { SlideDetails } from '../SlideDetailsSubHeader';

import classes from './upload.module.css';
import { useAnalysisCreation } from '../use-create-analysis';

export interface UploadProps {
  file: File | undefined;
  onUploadDone: (success: boolean) => void;
}

export function Upload(props: ParentProps<UploadProps>) {
  usePreventPageClose();
  const [details] = useAnalysisCreation();

  if (!props.file) {
    return;
  }

  const wsi = useWsiUpload(details.wsi_id, props.file);
  const { isUploading, setIsUploading } = useUpload();

  const uploadMessage = () =>
    isUploading() ? 'File is uploading' : 'Preparing file to upload';

  onMount(() => setIsUploading(true));

  createEffect(() => {
    if (wsi.state === 'DONE') {
      props.onUploadDone(true);
    }

    if (wsi.state === 'TIMEOUT' || wsi.state === 'ERROR') {
      props.onUploadDone(false);
    }
  });

  onCleanup(() => setIsUploading(false));

  return (
    <PageCardNavigation
      title="Add slides"
      description="Uploading"
      subheader={<SlideDetails panel />}
    >
      <section class={classes['upload-section']}>
        <div class={classes['progress-wrapper']}>
          <div>
            <Typography id="progress_message" weight="bold">
              {uploadMessage()}
            </Typography>
            <Show when={isUploading()}>
              <Typography id="progress_text" weight="thin">
                {wsi.percentage}%
              </Typography>
            </Show>
          </div>
          <Progress value={wsi.percentage} loading={!isUploading()} max={100} />
        </div>
        <div class={classes['stay-message']}>
          <ErrorIcon />
          <div class={classes['stay-message-text']}>
            <Typography component="div">
              Please do not close the current browser tab.
            </Typography>
            <Typography component="div">
              Closing the tab will interrupt the uploading process and will
              require re-uploading the file
            </Typography>
          </div>
        </div>
      </section>
    </PageCardNavigation>
  );
}
