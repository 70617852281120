import { Typography } from '@imagene/components';
import classes from './qcFailedLabel.module.css';

export function QCFailedLabel() {
  return (
    <Typography
      weight="normal"
      component="div"
      class={classes.label}
      color="error"
      aria-label="qc_failed_label"
    >
      Quality / Quantity Not Sufficient
    </Typography>
  );
}
