import { Match, Switch, createSignal, onCleanup } from 'solid-js';
import { useAnalysisCreation } from './use-create-analysis';
import {
  PanelSelection,
  PanelSelectionEvent,
} from '../../components/PanelSelection';
import { useFileFormat } from '../../utils/use-file-format';
import { SlideSelection } from './SlideSelection';
import { SlideDetailsConfigure } from './ConfigureDetails/SlideDetailConfigure';
import { FileManager } from './FileManager';
import { BatchNaming } from './ConfigureDetails/BatchNaming';
import { UploadBatchTable } from './Upload/UploadBatchTable';
import { Upload } from './Upload';
import {
  AnalysisCreateDto,
  CreateAnalysisResponse,
} from '@imagene/api-interfaces';
import { createMutation, useQueryClient } from '@tanstack/solid-query';
import { axiosClient } from '../../utils/axios';
import { UploadDone } from './Upload/UploadDone';
import { useCheckQuota, useQuota } from '../../utils/use-quota-limit';

enum PageState {
  PANEL_SELECTION,
  FILE_SELECTION,
  MANAGE_FILES,
  BATCH_NAMING,
  SLIDE_NAMING,
  BATCH_UPLOAD,
  SINGLE_UPLOAD,
  UPLOAD_DONE,
}

export function Analysis() {
  const queryClient = useQueryClient();
  const [page, setPage] = createSignal<PageState>(PageState.PANEL_SELECTION);
  const [details, setDetails] = useAnalysisCreation();
  const [file, setFile] = createSignal<File>();
  const quota = useQuota();
  const [uploadSuccess, setUploadSuccess] = createSignal<boolean>(false);
  const moveStepByFileLength = () => {
    if (details.files.length > 1) {
      setPage(PageState.MANAGE_FILES);
    } else {
      setPage(PageState.SLIDE_NAMING);
    }
  };

  const checkQuota = useCheckQuota({
    successCallback: moveStepByFileLength,
  });

  onCleanup(() => setDetails('files', []));

  const setPanel = (e: PanelSelectionEvent) => {
    const {
      panel: { id, label },
      biomarkers,
      completePanel,
    } = e;
    setDetails('panel', 'biomarkers', biomarkers);
    setDetails('panel', 'label', label);
    setDetails('panel', 'id', id);
    setDetails('completePanel', completePanel);
    setPage(PageState.FILE_SELECTION);
  };

  const onFileSelect = (files: File[]) => {
    const file = files[0];
    const format = useFileFormat(file);
    const slide_id = file.name.substring(0, file.name.lastIndexOf('.'));
    setFile(file);

    setDetails((prev) => ({
      ...prev,
      slide_id,
      file: {
        size: file.size,
        name: file.name,
        format: format(),
      },
      files,
      accession: undefined,
    }));

    validateSubmission();
  };

  const validateSubmission = () => {
    if (quota.isSuccess && quota.data.runs_limit_amount) {
      checkQuota.mutate({
        amount: details.files.length,
      });
    } else {
      moveStepByFileLength();
    }
  };

  const submitAnalysis = createMutation(
    (dto: AnalysisCreateDto) =>
      axiosClient
        .post<CreateAnalysisResponse>('/api/v1/analysis', dto)
        .then((res) => res.data),
    {
      onSuccess: (run) => {
        setDetails('run_id', run.id);
        setPage(PageState.UPLOAD_DONE);
        queryClient.invalidateQueries(['quota']);
      },
      onError: () => {
        setPage(PageState.FILE_SELECTION);
      },
    }
  );

  const onUploadDone = (success: boolean) => {
    setUploadSuccess(success);
    if (success) {
      submitAnalysis.mutateAsync({
        panel_id: details.panel.id,
        wsi_id: details.wsi_id,
        biomarkers: details.panel.biomarkers?.map((b) => b.id) ?? [],
      });
    } else {
      setPage(PageState.UPLOAD_DONE);
    }
  };

  return (
    <Switch>
      <Match when={page() === PageState.PANEL_SELECTION}>
        <PanelSelection
          title="Add slides"
          nextLabel="Next"
          onNext={setPanel}
          panel={details.panel}
        />
      </Match>
      <Match when={page() === PageState.FILE_SELECTION}>
        <SlideSelection
          onBack={() => setPage(PageState.PANEL_SELECTION)}
          onNext={validateSubmission}
          onFileSelected={onFileSelect}
          files={details.files}
        />
      </Match>

      <Match when={page() === PageState.SLIDE_NAMING}>
        <SlideDetailsConfigure
          onBack={() => setPage(PageState.FILE_SELECTION)}
          onConfigure={(details) => {
            setDetails(details);
            setPage(PageState.SINGLE_UPLOAD);
          }}
          onDecline={() => setPage(PageState.FILE_SELECTION)}
          file={file()}
        />
      </Match>

      <Match when={page() === PageState.MANAGE_FILES}>
        <FileManager
          onNext={() => setPage(PageState.BATCH_NAMING)}
          onBack={() => setPage(PageState.FILE_SELECTION)}
        />
      </Match>

      <Match when={page() === PageState.BATCH_NAMING}>
        <BatchNaming
          onDecline={() => setPage(PageState.MANAGE_FILES)}
          onBack={() => setPage(PageState.MANAGE_FILES)}
          onNext={() => setPage(PageState.BATCH_UPLOAD)}
        />
      </Match>

      <Match when={page() === PageState.BATCH_UPLOAD}>
        <UploadBatchTable
          onSubmitError={() => setPage(PageState.MANAGE_FILES)}
        />
      </Match>

      <Match when={page() === PageState.SINGLE_UPLOAD}>
        <Upload file={file()} onUploadDone={onUploadDone} />
      </Match>

      <Match when={page() === PageState.UPLOAD_DONE}>
        <UploadDone success={uploadSuccess()} />
      </Match>
    </Switch>
  );
}
