import {
  Show,
  ParentProps,
  createSignal,
  createEffect,
  Suspense,
} from 'solid-js';
import { ExtendedDetailedAnalysisRun } from '@imagene/api-interfaces';
import { ERROR_CODE } from '@imagene/error-codes';
import { Drawer, Skeleton, Typography } from '@imagene/components';
import { CopyReportButton } from './TextReport/CopyReportButton';
import { QCFailedLabel } from './QC/FailedLabel';
import { QualityControl } from './QC';
import { ReportText } from './TextReport';

import classes from './assay-drawers.module.css';

interface AssayDrawersProps {
  run: ExtendedDetailedAnalysisRun | undefined;
}

export function AssayDrawers(props: ParentProps<AssayDrawersProps>) {
  const [reportDrawerOpen, setReportDrawerOpen] = createSignal(false);
  const [reportDrawerDisable, setReportDrawerDisable] = createSignal(false);

  const [qualityDrawerOpen, setQualityDrawerOpen] = createSignal(false);
  const [qualityDrawerDisable, setQualityDrawerDisable] = createSignal(false);

  createEffect(() => {
    if (props.run && props.run.status) {
      handleStatus(props.run);
    }
  });

  function handleStatus(run: ExtendedDetailedAnalysisRun) {
    switch (run.status) {
      case 'IN_PROGRESS':
      case 'ERROR':
        if (run.stage === 'UPLOAD' || run.stage === 'QC') {
          setQualityDrawerDisable(true);
          setReportDrawerDisable(true);
        }
        if (run.stage === 'ANALYSIS') {
          setQualityDrawerDisable(false);
          setReportDrawerDisable(true);
        }
        break;

      case 'QC_FAILED':
        if (run.status_code === ERROR_CODE.CORRUPTED_FILE) {
          setQualityDrawerDisable(true);
          setQualityDrawerOpen(false);
          setReportDrawerDisable(true);
        } else {
          setQualityDrawerDisable(false);
          setQualityDrawerOpen(true);
          setReportDrawerDisable(false);
        }
        break;

      case 'COMPLETE':
        setReportDrawerDisable(false);
        setQualityDrawerDisable(false);
        break;
    }
  }

  const showFailedLabel = () =>
    props.run?.inclusion_criteria?.some((i) => !i.passed);

  return (
    <div class={classes.drawersContainer}>
      <Suspense fallback={<DrawerSkeleton />}>
        <Drawer
          ariaLabel="qc_drawer"
          open={qualityDrawerOpen()}
          onClick={() => setQualityDrawerOpen(true)}
          onClose={() => setQualityDrawerOpen(false)}
          disable={qualityDrawerDisable()}
          class={classes.drawer}
          containerClass={classes.drawerContainerLeft}
          headerLabel={
            <div class={classes.qcLabel}>
              <Typography component="div" weight="bold">
                Quality control
              </Typography>
              <Show when={showFailedLabel()}>
                <QCFailedLabel />
              </Show>
            </div>
          }
        >
          <QualityControl criteria={props.run?.inclusion_criteria ?? []} />
        </Drawer>
        <Drawer
          ariaLabel="report_drawer"
          open={reportDrawerOpen()}
          onClick={() => setReportDrawerOpen(true)}
          onClose={() => setReportDrawerOpen(false)}
          disable={reportDrawerDisable()}
          class={classes.drawer}
          containerClass={classes.drawerContainerRight}
          headerLabel={
            <div class={classes.copyContainer}>
              <Typography component="div" weight="bold">
                Report
              </Typography>
              <CopyReportButton
                disable={reportDrawerDisable()}
                report={props.run?.report ?? ''}
              />
            </div>
          }
        >
          <ReportText report={props.run?.report ?? ''} />
        </Drawer>
      </Suspense>
    </div>
  );
}

function DrawerSkeleton() {
  return (
    <>
      <Skeleton height={44} />
      <Skeleton height={44} />
    </>
  );
}
