import { createSignal, Show } from 'solid-js';
import { useNavigate } from '@solidjs/router';

import { ContextMenu, ContextMenuProps } from '@imagene/components';
import LogoutIcon from '@imagene/components/icons/LogoutIcon';
import ProfileIcon from '@imagene/components/icons/ProfileIcon';

import { useLogout } from '../../../utils/authClient';
import { useAuth } from '../../../utils/session';

import classes from './sidebar.module.css';

export interface UserProfileMenuProps {
  onDisabledClick?: VoidFunction;
  disabled: boolean;
}

export function UserProfileMenu(props: UserProfileMenuProps) {
  const { username, role } = useAuth();
  const navigate = useNavigate();
  const logout = useLogout();
  const [isMenuOpen, setMenuOpen] = createSignal(false);
  const isAdmin = () => role() === 'ADMIN';
  const defaultItems: ContextMenuProps['items'] = [
    {
      text: 'My account',
      onClick: () => {
        navigate('/account');
      },
    },
    {
      text: 'Log out',
      onClick: () => {
        logout();
      },
      icon: <LogoutIcon />,
      bottom: true,
    },
  ];
  const menuItems = () =>
    isAdmin()
      ? [
          {
            text: 'Users management',
            onClick: () => {
              navigate('/admin/users');
            },
          },
          {
            text: 'Organization Settings',
            onClick: () => {
              navigate('/admin/settings');
            },
          },
          ...defaultItems,
        ]
      : defaultItems;

  return (
    <li
      classList={{
        ['relative']: true,
        [classes.static]: true,
        [classes.item]: true,
        [classes.disabled]: props.disabled,
      }}
      onMouseEnter={() => {
        if (!props.disabled) {
          setMenuOpen(true);
        }
      }}
      onMouseLeave={() => {
        if (!props.disabled) {
          setMenuOpen(false);
        }
      }}
      onClick={() => props.onDisabledClick?.()}
    >
      <ProfileIcon />
      <Show when={isMenuOpen()}>
        <ContextMenu title={username() ?? 'Unknown'} items={menuItems()} />
      </Show>
    </li>
  );
}
