import { Show, Suspense } from 'solid-js';
import { Skeleton, Typography } from '@imagene/components';
import { useTenant } from '../../../../utils/use-tenant';

import classes from './header.module.css';

interface AssayGeneralDataHeaderProps {
  slide_id: string | undefined;
  accession: string | null | undefined;
}

export function AssayGeneralDataHeader(props: AssayGeneralDataHeaderProps) {
  const config = useTenant();
  return (
    <Suspense fallback={<Skeleton class={classes.skeleton} width={157} />}>
      <div class={classes.slide}>
        <Typography component="span" weight="bold">
          Slide ID
        </Typography>
        <Typography component="span" aria-label="slide_id_title">
          {props.slide_id}
        </Typography>
        <Show when={config().accession_enabled}>
          <Typography weight="bold">/</Typography>
          <Typography weight="bold">Accession ID</Typography>
          <Typography>{props.accession}</Typography>
        </Show>
      </div>
    </Suspense>
  );
}
