import {
  TermsOfServiceDto,
  TermsOfServiceSignatures,
} from '@imagene/api-interfaces';
import { axiosClient } from './axios';
import {
  createMutation,
  createQuery,
  useQueryClient,
} from '@tanstack/solid-query';

interface TermsOfServiceVersion {
  link: string;
  version: string;
}

export function useTermsOfService() {
  const queryClient = useQueryClient();

  const signatures = createQuery(
    () => ['signatures'],
    () =>
      axiosClient
        .get<TermsOfServiceSignatures>('/api/v1/legal/terms-of-service')
        .then((res) => res.data),
    {
      refetchOnWindowFocus: false,
    }
  );

  const tosVersion = createQuery(
    () => ['tos-versions'],
    () =>
      axiosClient
        .get<TermsOfServiceVersion>('/tos.json')
        .then((res) => res.data)
  );

  const needSignature = () =>
    tosVersion.isSuccess &&
    signatures.isSuccess &&
    !signatures.data.find((sig) => sig.version === tosVersion.data.version);

  const sign = createMutation(
    () => {
      const data: TermsOfServiceDto = {
        version: tosVersion.data?.version ?? '',
      };
      return axiosClient.post('/api/v1/legal/terms-of-service', data);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['signatures']);
      },
    }
  );

  return {
    link: () => tosVersion.data?.link,
    needSignature,
    sign,
  };
}
