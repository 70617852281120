import { ParentProps, JSX, Show } from 'solid-js';
import classes from './card.module.css';
import { Typography } from '../../Typography';

export type CardProps = ParentProps<
  JSX.HTMLAttributes<HTMLDivElement> & {
    title?: string;
    header?: JSX.Element;
  }
>;

export function Card(props: CardProps) {
  return (
    <div
      {...props}
      classList={{
        [classes.card]: true,
        [props.class ?? '']: Boolean(props.class),
      }}
    >
      <Show when={props.title || props.header}>
        <header>
          <Show when={props.title}>
            {(title) => <Typography weight="bold">{title()}</Typography>}
          </Show>
          {props.header}
        </header>
      </Show>
      {props.children}
    </div>
  );
}
