import { createUniqueId, JSX, ParentProps } from 'solid-js';

import classes from './radio-button.module.css';

type RadioButtonProps = ParentProps<
  Omit<JSX.InputHTMLAttributes<HTMLInputElement>, 'type'>
> & { label: string };

export function RadioButton(props: RadioButtonProps) {
  const id = createUniqueId();
  return (
    <div class={classes.container}>
      <input id={id} type="radio" {...props} class={classes.radio} />
      <span class={classes.dot}></span>
      <label for={id}>{props.label}</label>
    </div>
  );
}
