import {
  Button,
  Checkbox,
  SimpleTable,
  ToastProps,
  Tooltip,
  Typography,
  useToast,
  Search,
} from '@imagene/components';
import { Show, createEffect, createSignal } from 'solid-js';
import { createColumnHelper } from '@tanstack/solid-table';
import classes from './validation.module.css';
import { MolecularAssaySelect } from '../../components/OfficialResults';
import { CreateOfficialResultDto, ResultType } from '@imagene/api-interfaces';
import { Transition } from 'solid-transition-group';
import { createMutation, useQueryClient } from '@tanstack/solid-query';
import { axiosClient } from '../../utils/axios';
import { useValidation } from './use-validation';
import { OfficialResultValue } from '@prisma/client';
import { transformReportResult } from '@imagene/biomarker-results';
import { MinusIcon } from '@imagene/components/icons/MinusIcon';
import { PlusIcon } from '@imagene/components/icons/PlusIcon';

export interface MolecularResultTableType {
  slide_id: string;
  run_id: string;
  egfr_result?: OfficialResultValue | null;
  alk_result?: OfficialResultValue | null;
  assay?: string | null;
  results: ResultType[];
  excluded: boolean;
}

type OnSlideClick = (id: string, value: boolean) => void;

interface ResultsTableProps {
  selectedSlides: string[];
  data: MolecularResultTableType[];
  onSlideSelect: OnSlideClick;
  onSlideExclude: OnSlideClick;
  onSelectAll: (checked: boolean) => void;
}

export function MolecularResults() {
  const details = useValidation();
  const [tableData, setTableData] = createSignal<MolecularResultTableType[]>(
    details.molecularResultsTableData()
  );
  const [slideQuery, setSlideQuery] = createSignal<string>('');
  const [selectedSlides, setSelectedSlides] = createSignal<string[]>([]);
  const { toast } = useToast();
  const noSelectionToast: ToastProps = {
    title: 'No slides were selected',
    description: 'Click on the checkboxes to select slides.',
    type: 'warning',
  };
  const queryClient = useQueryClient();

  const resultMutation = createMutation(
    (
      data: Pick<
        CreateOfficialResultDto,
        'positive_result_id' | 'all_negative' | 'run_id'
      >[]
    ) => axiosClient.post('/api/v1/validation/results', data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['validation']);
      },
    }
  );

  const excludeMutation = createMutation(
    (data: { id: string; excluded: boolean }) =>
      axiosClient.patch(`/api/v1/validation/inclusion/${data.id}`, {
        excluded: data.excluded,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['validation']);
      },
    }
  );

  createEffect(() => {
    if (slideQuery()) {
      setTableData(
        details
          .molecularResultsTableData()
          .filter((i) =>
            i.slide_id
              .toLocaleLowerCase()
              .includes(slideQuery().toLocaleLowerCase())
          )
      );
    } else {
      setTableData(details.molecularResultsTableData());
    }
  });

  const markEGFRPositive = () => {
    if (selectedSlides().length) {
      setTableData((prev) =>
        prev.map((i) => ({
          ...i,
          egfr_result: selectedSlides().includes(i.run_id)
            ? 'POSITIVE'
            : i.egfr_result ?? undefined,
        }))
      );
      const selected = tableData().filter((i) =>
        selectedSlides().includes(i.run_id)
      );
      const data = selected.map((s) => {
        const result_id = s.results?.find((r) =>
          r.biomarker.label.includes('EGFR')
        )?.id;
        if (!result_id)
          throw new Error('undefined result id for biomarker EGFR');
        return {
          positive_result_id: result_id,
          run_id: s.run_id,
        };
      });
      setSelectedSlides([]);
      resultMutation.mutate(data);
    } else {
      toast(noSelectionToast);
    }
  };

  const markALKPositive = () => {
    if (selectedSlides().length) {
      setTableData((prev) =>
        prev.map((i) => ({
          ...i,
          alk_result: selectedSlides().includes(i.run_id)
            ? 'POSITIVE'
            : i.alk_result ?? undefined,
        }))
      );
      const selected = tableData().filter((i) =>
        selectedSlides().includes(i.run_id)
      );
      const data = selected.map((s) => {
        const result_id = s.results?.find((r) =>
          r.biomarker.label.includes('ALK')
        )?.id;
        if (!result_id)
          throw new Error('undefined result id for biomarker ALK');
        return {
          positive_result_id: result_id,
          run_id: s.run_id,
        };
      });
      setSelectedSlides([]);
      resultMutation.mutate(data);
    } else {
      toast(noSelectionToast);
    }
  };

  const markBothNegative = () => {
    if (selectedSlides().length) {
      setTableData((prev) =>
        prev.map((i) => ({
          ...i,
          egfr_result: selectedSlides().includes(i.run_id)
            ? 'NEGATIVE'
            : i.egfr_result ?? undefined,
          alk_result: selectedSlides().includes(i.run_id)
            ? 'NEGATIVE'
            : i.alk_result ?? undefined,
        }))
      );
      const selected = tableData().filter((i) =>
        selectedSlides().includes(i.run_id)
      );
      const data = selected.map((s) => {
        return {
          all_negative: true,
          run_id: s.run_id,
        };
      });
      setSelectedSlides([]);
      resultMutation.mutate(data);
    } else {
      toast(noSelectionToast);
    }
  };

  const onSelectAll = (checked: boolean) => {
    if (checked) {
      setSelectedSlides(
        tableData()
          .filter((i) => !i.excluded)
          .map((i) => i.run_id)
      );
    } else {
      setSelectedSlides([]);
    }
  };

  return (
    <Transition appear name="fade">
      <section class={classes['molecular-results']}>
        <div class={classes['results-header']}>
          <h2>Molecular Results</h2>
          <Typography>
            Click on the checkboxes to select slides and press one of the
            buttons below to add the molecular results
          </Typography>
          <div>
            <Search
              id="molecular_results_query"
              placeholder="Search Slide ID"
              onSearch={(query) => setSlideQuery(query)}
              skipParams
              containerClass={classes.search}
            />
            <div class={classes.buttons}>
              <Button variant="confirm-light" onClick={markBothNegative}>
                Mark Both EGFR and ALK Negative
              </Button>
              <div>
                <Button variant="default-light" onClick={markEGFRPositive}>
                  Mark EGFR Positive
                </Button>
                <Button variant="default-light" onClick={markALKPositive}>
                  Mark ALK Positive
                </Button>
              </div>
            </div>
          </div>
        </div>
        <ResultsTable
          data={tableData()}
          onSlideSelect={(run_id, checked) => {
            if (checked) {
              setSelectedSlides((prev) => [...prev, run_id]);
            } else {
              setSelectedSlides((prev) => prev.filter((i) => i !== run_id));
            }
          }}
          selectedSlides={selectedSlides()}
          onSelectAll={onSelectAll}
          onSlideExclude={(id, excluded) => {
            excludeMutation.mutate({ id, excluded });
          }}
        />
      </section>
    </Transition>
  );
}

function ResultsTable(props: ResultsTableProps) {
  const columnHelper = createColumnHelper<MolecularResultTableType>();
  const queryClient = useQueryClient();
  return (
    <SimpleTable
      containerClass={classes['results-table-wrapper']}
      class={classes['results-table']}
      columns={[
        columnHelper.display({
          id: 'id',
          header: () => {
            const checked = () =>
              Boolean(props.selectedSlides.length) &&
              props.selectedSlides.length === props.data.length;
            return (
              <Checkbox
                checked={checked()}
                onChange={(e) => {
                  props.onSelectAll(e.currentTarget.checked);
                }}
                label={<Typography weight="bold">Slide ID</Typography>}
              />
            );
          },
          size: 360,
          cell: (info) => {
            const checked = () =>
              Boolean(
                props.selectedSlides.find((s) => s === info.row.original.run_id)
              );
            const isExcluded = () => info.row.original.excluded;

            return (
              <div class={classes['slide-id-wrap']}>
                <Checkbox
                  label={
                    <Typography
                      wrap="25ch"
                      title={info.row.original.slide_id}
                      classList={{
                        [classes.strike]: isExcluded(),
                      }}
                    >
                      {info.row.original.slide_id}
                    </Typography>
                  }
                  id={info.row.original.run_id}
                  onChange={(e) => {
                    props.onSlideSelect(
                      e.currentTarget.id,
                      e.currentTarget.checked
                    );
                  }}
                  checked={checked()}
                  disabled={isExcluded()}
                />
                <Show
                  when={isExcluded()}
                  fallback={
                    <ExcludeSlideButton
                      id={info.row.original.run_id}
                      onClick={props.onSlideExclude}
                    />
                  }
                >
                  <IncludeSlideButton
                    id={info.row.original.run_id}
                    onClick={props.onSlideExclude}
                  />
                </Show>
              </div>
            );
          },
        }),
        columnHelper.display({
          id: 'egfr',
          header: () => <Typography weight="bold">EGFR Mut</Typography>,
          size: 242,
          cell: (info) => {
            const isExcluded = () => info.row.original.excluded;
            const result = () =>
              isExcluded()
                ? 'Excluded'
                : transformReportResult(info.row.original.egfr_result);
            return (
              <div
                classList={{
                  [classes.highlight]: result() === 'Positive',
                  [classes.excluded]: isExcluded(),
                }}
              >
                <Typography>{result()}</Typography>
              </div>
            );
          },
        }),
        columnHelper.display({
          id: 'alk',
          header: () => <Typography weight="bold">ALK fusion</Typography>,
          size: 240,
          cell: (info) => {
            const isExcluded = () => info.row.original.excluded;
            const result = () =>
              isExcluded()
                ? 'Excluded'
                : transformReportResult(info.row.original.alk_result);
            return (
              <div
                classList={{
                  [classes.highlight]: result() === 'Positive',
                  [classes.excluded]: Boolean(isExcluded()),
                }}
              >
                <Typography>{result()}</Typography>
              </div>
            );
          },
        }),
        columnHelper.display({
          id: 'assay',
          header: () => <Typography weight="bold">Molecular Assay</Typography>,
          cell: (info) => {
            return (
              <Show when={!info.row.original.excluded}>
                <div
                  classList={{
                    [classes.select]: true,
                    [classes.text]: Boolean(info.row.original.assay),
                  }}
                >
                  <MolecularAssaySelect
                    run_id={info.row.original.run_id}
                    results={info.row.original.results}
                    direction={info.row.index > 7 ? 'up' : 'down'}
                    show
                    onSuccessCb={() => {
                      queryClient.invalidateQueries(['validation']);
                    }}
                  />
                  <Show when={Boolean(info.row.original.assay)}>
                    <Typography>{info.row.original.assay}</Typography>
                  </Show>
                </div>
              </Show>
            );
          },
        }),
      ]}
      data={props.data}
    />
  );
}

function IncludeSlideButton(props: { id: string; onClick: OnSlideClick }) {
  return (
    <Tooltip placement="top" text="Include slide into cohort">
      <span data-scope="icon" onClick={() => props.onClick(props.id, false)}>
        <PlusIcon />
      </span>
    </Tooltip>
  );
}

function ExcludeSlideButton(props: { id: string; onClick: OnSlideClick }) {
  return (
    <Tooltip placement="top" text="Exclude slide from cohort">
      <span data-scope="icon" onClick={() => props.onClick(props.id, true)}>
        <MinusIcon />
      </span>
    </Tooltip>
  );
}
