import { useNavigate } from '@solidjs/router';
import { ColumnDef } from '@tanstack/solid-table';
import { Button, Typography } from '@imagene/components';
import { sortResults } from '@imagene/biomarker-results';
import { ExtendedAnalysisRun } from '@imagene/api-interfaces';
import { useDateFormat } from '@imagene/dates/lib/use-date-format';
import { ResultsIndicator } from './ResultsIndicator';
import { ProgressIndicator } from '../../components/Progress';
import { useTenant } from '../../utils/use-tenant';

// FIXME: do not use external css module
import classes from '@imagene/components/Table/table.module.css';
import classes2 from './assays.module.css';
import { Accessor, createSignal } from 'solid-js';
import {
  BiomarkerResultSelect,
  MolecularAssaySelect,
} from '../../components/OfficialResults';

type ResultTableColumDef = ColumnDef<ExtendedAnalysisRun>[];
export type ResultsTableColumns = Accessor<ResultTableColumDef>;

export function useTableColumns(): ResultsTableColumns {
  const navigate = useNavigate();
  const config = useTenant();
  const showResults = () =>
    config().positive_findings ||
    config().probability_enabled ||
    config().inconclusive_probability_enabled;
  const [columns] = createSignal<ResultTableColumDef>([
    {
      accessorFn: (run) => ({
        slide_id: run.wsi.slide.identifier,
        run_id: run.id,
      }),
      cell: (info) => {
        const { slide_id, run_id } = info.getValue<{
          slide_id: string;
          run_id: string;
        }>();
        return (
          <div class={classes2['table-btn']}>
            <Button
              aria-label="slide_id_cell"
              variant="secondary"
              onClick={() => {
                navigate(`/assays/${run_id}`);
              }}
              size="large"
              fullWidth
            >
              {slide_id}
            </Button>
          </div>
        );
      },
      header: 'Slide ID',
    },
    {
      accessorFn: (run) => run.wsi.slide.accession,
      meta: {
        headerAlignStart: true,
      },
      cell: (info) => {
        const accession = info.getValue<string>();
        return (
          <div
            aria-label="accession_id_cell"
            classList={{
              [classes['justify-start']]: true,
              [classes2.accession]: true,
            }}
          >
            <Typography wrap="22ch" title={accession}>
              {accession}
            </Typography>
          </div>
        );
      },
      header: 'Accession ID',
    },
    {
      accessorFn: (run) => {
        const date =
          run.status === 'QC_FAILED'
            ? run.updated_at
            : run.results?.[0]?.created_at;

        return {
          submitDate: useDateFormat(run.wsi.upload_start_at),
          resultDate: run.status !== 'ERROR' ? useDateFormat(date) : '---',
        };
      },
      meta: {
        headerAlignStart: true,
      },
      cell: (info) => {
        const { submitDate, resultDate } = info.getValue<{
          submitDate: string;
          resultDate: string;
        }>();
        return (
          <div
            classList={{
              [classes['justify-start']]: true,
              [classes2.dates]: true,
            }}
          >
            <span aria-label="submit_date_cell">
              {submitDate} {resultDate ? '/' : ''}
            </span>
            <span aria-label="result_date_cell">{resultDate}</span>
          </div>
        );
      },
      header: 'Submission/Result date',
    },
    {
      accessorFn: (row) => row,
      meta: {
        headerAlignStart: true,
      },
      cell: (info) => {
        const run = info.getValue<ExtendedAnalysisRun>();
        const sortedResults = sortResults(run.results, run.probability_enabled);
        const showStatus = run.status !== 'COMPLETE' || !showResults();
        const statusEl = (
          <div class={classes['justify-start']} aria-label="status_cell">
            <ProgressIndicator
              mode="compact"
              status={run.status}
              stage={run.stage}
            />
          </div>
        );
        const resultsEl = (
          <ResultsIndicator
            results={sortedResults}
            status={run.status}
            probabilityMode={run.probability_enabled}
            equivocalMode={run.inconclusive_probability_enabled}
            positiveFindingsMode={run.positive_findings_enabled}
          />
        );
        return showStatus ? statusEl : resultsEl;
      },
      header: 'AI findings',
    },
    {
      header: 'Noticed a discordance?',
      accessorFn: (row) => row,
      meta: {
        cellWithBg: true,
      },
      cell: (info) => {
        const run = info.getValue<ExtendedAnalysisRun>();
        const renderAbove = [7, 8, 9].includes(info.row.index);
        return (
          <BiomarkerResultSelect
            run_id={run.id}
            show={run.status === 'COMPLETE'}
            direction={renderAbove ? 'up' : 'down'}
            results={run.results}
          />
        );
      },
    },
    {
      header: 'Molecular assay',
      accessorFn: (row) => row,
      meta: {
        headerAlignStart: true,
        headerPadStart: true,
        cellWithBg: true,
      },
      cell: (info) => {
        const run = info.getValue<ExtendedAnalysisRun>();
        const renderAbove = [7, 8, 9].includes(info.row.index);
        return (
          <div
            classList={{
              [classes['justify-start']]: true,
              [classes['padding-start']]: true,
            }}
          >
            <MolecularAssaySelect
              run_id={run.id}
              show={run.status === 'COMPLETE'}
              direction={renderAbove ? 'up' : 'down'}
              results={run.results}
            />
          </div>
        );
      },
    },
    // {
    //   accessorFn: (row) => row,
    //   header: 'PDF',
    //   cell: (info) => {
    //     const { id, status, batch_id } = info.getValue<Worklist>();
    //     return (
    //       <PdfDownloadButton
    //         url={`/api/v1/analysis/${id}/report`}
    //         disabled={
    //           status === 'IN_PROGRESS' ||
    //           status === 'ERROR' ||
    //           Boolean(batch_id)
    //         }
    //       />
    //     );
    //   },
    // },
  ]);

  return columns;
}
