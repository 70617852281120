import { UiContainer, UiNode, UiNodeInputAttributes } from '@ory/client';
import PasswordValidator from 'password-validator';

export function getUiNode(nodes: UiNode[], name: string) {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  return nodes.find(
    (node) => (node.attributes as UiNodeInputAttributes).name === name
  )!;
}

export function getUiNodeByType(nodes: UiNode[], type: UiNode['type']) {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  return nodes.find((node) => node.type === type)!;
}

export function getUiNodeAttributes(nodes: UiNode[], name: string) {
  return getUiNode(nodes, name)?.attributes as unknown as UiNodeInputAttributes;
}

export function getGlobalError(ui: UiContainer) {
  return ui.messages
    ?.filter((msg) => msg.type === 'error')
    ?.map((msg) => {
      if (msg.id === 4000006) {
        return 'That email and password combination is incorrect.';
      }
      if (msg.id === 4000008 || msg.id === 4060006) {
        return 'Incorrect code';
      }
      return msg.text;
    })
    .join(',');
}

export function getGlobalMessage(ui: UiContainer) {
  return ui.messages
    ?.filter((msg) => msg.type === 'info')
    ?.map((msg) => msg.text)
    .join(',');
}

const passwordSchema = new PasswordValidator();
passwordSchema
  .is()
  .min(8)
  .is()
  .max(50)
  .has()
  .uppercase()
  .has()
  .lowercase()
  .has()
  .symbols()
  .has()
  .digits(1)
  .not()
  .spaces();

export { passwordSchema };
