import { Accessor, ParentProps } from 'solid-js';
import { ProgressIndicatorProps } from '.';

import { Tooltip2, Typography } from '@imagene/components';
import { StatusIcons, StatusIndicator } from '../StatusIndicator';
import { useProgressStatus } from './useProgressStatus';

import classes from './progress.module.css';

interface ProgressMenuProps extends ProgressIndicatorProps {
  node: Accessor<HTMLElement>;
}

export function ProgressMenu(props: ParentProps<ProgressMenuProps>) {
  const statuses = useProgressStatus(props);

  return (
    <Tooltip2 refElement={props.node}>
      <div
        class={classes['progress-menu-container']}
        aria-label="progress_menu"
      >
        <div class={classes['progress-menu-item']}>
          <StatusIndicator
            ariaLabel="upload_status_icon"
            classList={{
              [classes.ongoing]: statuses.upload() === StatusIcons.ongoing,
            }}
            status={statuses.upload()}
          />
          <Typography class={classes.indicatorText} component="div">
            Image upload
          </Typography>
        </div>
        <div
          class={
            ![
              StatusIcons.unknown,
              StatusIcons.error,
              StatusIcons.ongoing,
            ].includes(statuses.qc())
              ? classes['separator-purple']
              : classes.separator
          }
        />
        <div class={classes['progress-menu-item']}>
          <StatusIndicator
            ariaLabel="qc_status_icon"
            classList={{
              [classes.ongoing]: statuses.qc() === StatusIcons.ongoing,
            }}
            status={statuses.qc()}
          />
          <Typography class={classes.indicatorText} component="div">
            Quality control
          </Typography>
        </div>
        <div
          class={
            ![
              StatusIcons.unknown,
              StatusIcons.error,
              StatusIcons.ongoing,
            ].includes(statuses.analysis())
              ? classes['separator-purple']
              : classes.separator
          }
        />
        <div class={classes['progress-menu-item']}>
          <StatusIndicator
            ariaLabel="analysis_status_icon"
            classList={{
              [classes.ongoing]: statuses.analysis() === StatusIcons.ongoing,
            }}
            status={statuses.analysis()}
          />
          <Typography class={classes.indicatorText} component="div">
            Analysis
          </Typography>
        </div>
      </div>
    </Tooltip2>
  );
}
