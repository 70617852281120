import { Typography } from '@imagene/components';
import PositiveOutlinedIcon from '@imagene/components/icons/PositiveOutlinedIcon';
import NegativeOutlinedIcon from '@imagene/components/icons/NegativeOutlinedIcon';

import classes from './generalData.module.css';
import { For } from 'solid-js';
import type { InclusionCriteriaWithLabel } from '@imagene/api-interfaces';

interface InclusionCriteriaTableProps {
  results: InclusionCriteriaWithLabel[];
}

export function InclusionCriteriaTable(props: InclusionCriteriaTableProps) {
  return (
    <table class={classes.table}>
      <thead>
        <tr class={classes.tableHeader}>
          <Typography component="td" weight="bold">
            Inclusion Criteria
          </Typography>
          <Typography component="td" weight="bold">
            Value
          </Typography>
          <Typography component="td" weight="bold">
            Quality
          </Typography>
        </tr>
      </thead>
      <tbody>
        <For each={props.results}>{(item) => <CriteriaItem item={item} />}</For>
      </tbody>
    </table>
  );
}

function CriteriaItem(props: { item: InclusionCriteriaWithLabel }) {
  const itemType = props.item.type.toLowerCase();
  const icon = () => {
    if (props.item.value === '-') return null;

    return props.item.passed ? (
      <PositiveOutlinedIcon ariaLabel={`${itemType}_icon_checkmark`} />
    ) : (
      <NegativeOutlinedIcon ariaLabel={`${itemType}_icon_error`} />
    );
  };

  return (
    <tr class={classes.tableRow}>
      <Typography component="td">{props.item.label}</Typography>
      <Typography component="td" aria-label={`${itemType}_value`}>
        {props.item.value ?? '---'}
      </Typography>
      <td>{icon()}</td>
    </tr>
  );
}
