import IdleTracker from 'idle-tracker';
import { onCleanup, onMount } from 'solid-js';

export interface UseIdleDetectionOptions {
  timeout?: IdleTracker['timeout'];
  throttle?: IdleTracker['throttleTime'];
  onIdleCallback: IdleTracker['callback'];
}

export function useIdleDetection(options: UseIdleDetectionOptions) {
  const idleTracker = new IdleTracker(options);

  onMount(() => idleTracker.start());
  onCleanup(() => idleTracker.end());
}
