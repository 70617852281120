import { useSearchParams } from '@solidjs/router';
import { Accessor } from 'solid-js';

export type BiomarkerQuery = 'egfr' | 'alk';

export function useBiomarkerQuery() {
  const [query, setQuery] = useSearchParams();
  const selectedBiomarker: Accessor<BiomarkerQuery> = () =>
    (query.g as BiomarkerQuery) ?? 'egfr';

  function setCurrentGene(gene: BiomarkerQuery) {
    setQuery({ g: gene });
  }

  return {
    selectedBiomarker,
    setCurrentGene,
  };
}
