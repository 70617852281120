import { createEffect, createSignal, For, ParentProps, Show } from 'solid-js';
import { Typography } from '../../Typography';
import { Digit } from './Digit';

import classes from './digits.module.css';

interface DigitsProps {
  id?: string;
  amount: number;
  onComplete: (digits: string) => void;
  class?: string;
  error?: string;
}

export function Digits(props: ParentProps<DigitsProps>) {
  const resetDigits = () => new Array(props.amount).fill(null);
  const [current, setCurrent] = createSignal(0);
  const [digits, setDigits] = createSignal<(number | null)[]>(resetDigits());

  function isAllDigits(numbers: (number | null)[]) {
    return numbers.every(Number.isFinite);
  }

  createEffect(() => {
    if (isAllDigits(digits())) {
      //check if every digit is a number
      props.onComplete(digits().join(''));
    }
  });

  function onTab(backward = false) {
    const index = current();
    if (backward && index === 0) return;
    if (!backward && index === props.amount - 1) return;

    setCurrent(index + (backward ? -1 : 1));
  }

  function onPaste(digits: number[]) {
    if (isAllDigits(digits)) {
      setDigits(digits);
      setCurrent(props.amount - 1);
    } else {
      setDigits(resetDigits());
      setCurrent(0);
    }
  }

  function onDigit(value: number | null, deleted = false) {
    const index = current();

    setDigits((prev) => {
      prev[index] = deleted ? null : value;
      return [...prev];
    });

    onTab(deleted);
  }

  return (
    <div classList={{ [props.class ?? '']: !!props.class }}>
      <div
        id={props.id}
        class={classes.digits}
        classList={{ [classes.invalid]: !!props.error }}
      >
        <For each={digits()}>
          {(value, index) => (
            <Digit
              value={value}
              focus={current() === index()}
              onInput={onDigit}
              onTab={onTab}
              onDelete={() => onDigit(null, true)}
              onFocus={() => setCurrent(index())}
              onPaste={onPaste}
            />
          )}
        </For>
      </div>
      <Show when={props.error}>
        <Typography component="div" color="error">
          {props.error}
        </Typography>
      </Show>
    </div>
  );
}
