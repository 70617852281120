import {
  Accessor,
  createSignal,
  JSX,
  ParentProps,
  createEffect,
} from 'solid-js';
import { Portal } from 'solid-js/web';
import classes from './tooltip2.module.css';

interface Tooltip2Props {
  class?: string;
  refElement: Accessor<HTMLElement | SVGElement>;
}

export function Tooltip2(props: ParentProps<Tooltip2Props>) {
  const [styling, setStyling] = createSignal<JSX.CSSProperties>();
  const [overflowing, setOverflowing] = createSignal(false);
  const [tooltipRef, setTooltipRef] = createSignal<HTMLDivElement>();

  createEffect(() => {
    if (tooltipRef()) {
      const { top, left, bottom } = props.refElement().getBoundingClientRect();
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const { height } = tooltipRef()!.getBoundingClientRect();
      const overflowing = bottom + height + 20 > window.innerHeight;
      const fromTop = overflowing ? top - height - 40 : bottom;

      setStyling({
        top: `calc(${fromTop}px + 20px)`,
        left: `calc(${left}px - 20px)`,
      });

      setOverflowing(overflowing);
    }
  });

  return (
    <Portal>
      <div
        classList={{
          [classes.tooltip2]: true,
          [classes['arrow-top']]: !overflowing(),
          [classes['arrow-bottom']]: overflowing(),
        }}
        style={styling()}
        ref={(r) => setTooltipRef(r)}
      >
        {props.children}
      </div>
    </Portal>
  );
}
