import { For, ParentProps, createEffect } from 'solid-js';

import classes from './context-menu.module.css';
import { ContextMenuItem, MenuItemProps } from './menu-item';

export interface ContextMenuProps {
  title: string;
  items: Omit<MenuItemProps, 'header'>[];
}

export function ContextMenu(props: ParentProps<ContextMenuProps>) {
  let ref: HTMLDivElement | null = null;

  createEffect(() => {
    ref?.style.setProperty(
      '--context-menu-width',
      ref?.getBoundingClientRect().width + 'px'
    );
  });

  return (
    <div ref={(v) => (ref = v)} class={classes.menu}>
      <ContextMenuItem text={props.title} header />
      <For each={props.items}>{(item) => <ContextMenuItem {...item} />}</For>
    </div>
  );
}
