import { createSignal } from 'solid-js';
import { createMutation } from '@tanstack/solid-query';
import { UpdateUserStateDto } from '@imagene/auth';
import { Button, Modal, useToast } from '@imagene/components';
import { axiosClient } from '../../../utils/axios';
import queryClient from '../../../utils/queryClient';

import classes from '../users.module.css';

export function Activate(props: { id: string; email: string }) {
  const [isOpen, setIsOpen] = createSignal<boolean>(false);
  const { toast } = useToast();

  const user = createMutation(
    (data: UpdateUserStateDto) =>
      axiosClient
        .patch(`/api/v1/admin/users/${props.id}`, data)
        .then((res) => res.data),
    {
      onSuccess: () => {
        toast({
          title: 'Account activated!',
          description: `Account ${props.email} was activated successfully`,
          dismissAfter: 5000,
        });
        queryClient.invalidateQueries(['users']);
      },
      onError: () => {
        toast({
          type: 'error',
          title: 'Failed to activate',
          description: `Account ${props.email} was not activated due to technical issues. Please try again later.`,
          dismissAfter: 5000,
        });
      },
    }
  );

  async function activateAccount() {
    user.mutate({
      state: 'active',
    });
  }

  return (
    <>
      <Button
        variant="secondary"
        class={classes['action-btn']}
        onClick={() => setIsOpen(true)}
      >
        Activate
      </Button>
      <Modal
        open={isOpen()}
        onClose={() => setIsOpen(false)}
        onAction={activateAccount}
        closeBtnText="Keep account inactive"
        actionBtnText="Activate account"
        title="Activate account?"
        text={`Activate account ${props.email}?
               User will be able to login and use the system.
               Activated user account has access to all slide analyses implemented before, if there were any.`}
      />
    </>
  );
}
