import { Outlet } from '@solidjs/router';
import { useLogout } from '../../../utils/authClient';
import { useIdleDetection } from '../../../utils/use-idle-detection';
import { Sidebar } from '../Sidebar';
import classes from './main-layout.module.css';
import { useSessionExpired } from '../../../utils/use-session-expired';
import * as Sentry from '@sentry/browser';
import { Typography } from '@imagene/components';
import { createQuery } from '@tanstack/solid-query';
import { axiosClient } from '../../../utils/axios';
import { useUpload } from '../../../utils/use-upload';
interface Version {
  version: string;
}

export function MainLayout() {
  const logout = useLogout();
  const { isUploading } = useUpload();
  let expiredToastId: number | null = null;
  const showExpiredToast = useSessionExpired(
    'You have been inactive for too long',
    () => (expiredToastId = null)
  );
  useIdleDetection({
    timeout: 1000 * 60 * 60 * 4, // 4 hours
    onIdleCallback: async ({ idle }) => {
      if (idle && !isUploading()) {
        console.warn('User is idle detected, login out...');
        Sentry.addBreadcrumb({
          category: 'auth',
          message: 'User is idle detected, logging out',
        });
        if (expiredToastId === null) {
          expiredToastId = showExpiredToast();
        }
        await logout();
      }
    },
  });

  const verQuery = createQuery(
    () => ['version'],
    () => axiosClient.get<Version>('/version.json').then((res) => res.data)
  );

  return (
    <div class={classes.layout}>
      <Sidebar />
      <main class={classes.main}>
        <Outlet />
        <Footer version={verQuery.data?.version} />
      </main>
    </div>
  );
}

export function Footer(props: { version?: string }) {
  return (
    <div class={classes.footer}>
      <Typography weight="thin">
        For Research Use Only. Not for use in diagnostic procedures. |&nbsp;
        {props.version}
      </Typography>
      <Typography weight="thin">
        <a target="_blank" href="/ImageneOI - Terms of Use.pdf">
          Terms of Use
        </a>
        &nbsp;|&nbsp;
        <a target="_blank" href="/ImageneOI - Privacy Policy.pdf">
          Privacy Policy
        </a>
      </Typography>
    </div>
  );
}
