import { For, Match, ParentProps, Switch } from 'solid-js';
import { ResultType, WorklistStatus } from '@imagene/api-interfaces';
import {
  displayProbability,
  isNegative,
  isPositive,
} from '@imagene/biomarker-results';
import { Typography } from '@imagene/components';

import classes from './assays.module.css';

interface ResultsIndicatorProps {
  results: ResultType[];
  status: WorklistStatus;
  probabilityMode: boolean;
  equivocalMode: boolean;
  positiveFindingsMode: boolean;
}

export function ResultsIndicator(props: ParentProps<ResultsIndicatorProps>) {
  return (
    <div class={classes['justify-start']} aria-label="results_cell">
      <Switch>
        <Match when={props.probabilityMode}>
          <ProbabilityResults results={props.results} isProbability />
        </Match>
        <Match when={props.equivocalMode && !props.positiveFindingsMode}>
          <ProbabilityResults results={props.results} />
        </Match>
        <Match when={props.equivocalMode && props.positiveFindingsMode}>
          <PositiveFindings results={props.results} status={props.status} />
        </Match>
        <Match when={props.positiveFindingsMode}>
          <PositiveFindings results={props.results} status={props.status} />
        </Match>
      </Switch>
    </div>
  );
}

export function ProbabilityResults(props: {
  results: ResultType[];
  isProbability?: boolean;
}) {
  return (
    <div class={classes.results}>
      <For
        each={props.results.filter(
          (r) =>
            r.visible &&
            ['EGFR', 'ALK'].some((gene) => r.biomarker.label.includes(gene))
        )}
      >
        {(result) => (
          <>
            <Typography weight="semi-bold">
              {result.biomarker.label}:
            </Typography>
            <Switch
              fallback={
                <Typography weight="semi-bold">
                  Equivocal ({displayProbability(result.raw_probability)} %)
                </Typography>
              }
            >
              <Match when={isPositive(result, props.isProbability)}>
                <Typography weight="semi-bold">Positive</Typography>
              </Match>
              <Match when={isNegative(result, props.isProbability)}>
                <Typography weight="semi-bold">Negative</Typography>
              </Match>
            </Switch>
          </>
        )}
      </For>
    </div>
  );
}

export function PositiveFindings(props: {
  results: ResultType[];
  status: WorklistStatus;
}) {
  const numOfNegatives = props.results.reduce((prev, curr) => {
    if (curr.result !== 'POSITIVE') return prev + 1;
    return prev;
  }, 0);

  const positives = props.results
    .filter((r) => r.visible && isPositive(r, false))
    .map((r) => r.biomarker.label);

  const showDashes = () =>
    props.status === 'ERROR' || props.status === 'QC_FAILED';

  return (
    <Switch>
      <Match when={positives.length}>
        <Typography
          weight="semi-bold"
          classList={{ [classes.result]: true, [classes.positive]: true }}
        >
          {positives.join(',')}
        </Typography>
      </Match>
      <Match when={numOfNegatives}>
        <Typography weight="normal" class={classes.result}>
          No findings
        </Typography>
      </Match>
      <Match when={showDashes()}>
        <Typography weight="normal" class={classes.result}>
          ---
        </Typography>
      </Match>
    </Switch>
  );
}
