import * as Sentry from '@sentry/browser';

const isLocal = window.location.hostname.includes('localhost');

Sentry.init({
  dsn: import.meta.env.VITE_SENTRY_DSN,
  enabled: !isLocal,
  environment: import.meta.env.VITE_ENV,
  // integrations: [
  //   new Sentry.BrowserTracing({
  //     // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
  //     tracePropagationTargets: ['localhost', /^https:\/\/localhost\/api/],
  //   }),
  // ],
  // Performance Monitoring
  // tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
});
