import { createEffect, ParentProps } from 'solid-js';
import { LoginFlow } from '@ory/client';

import { Button, Digits, Typography } from '@imagene/components';
import { AMOUNT_OF_DIGITS, TwoFAData, use2FA } from './use2FA';

import { getGlobalError } from '../../utils/auth';

import classes from './two-fa.module.css';

interface TwoFAFormProps {
  flow: LoginFlow;
  onSubmit: (data: TwoFAData) => void;
}

export function TwoFAForm(props: ParentProps<TwoFAFormProps>) {
  const { onDigitsComplete, isValid, data } = use2FA(props.flow.ui.nodes);

  let submit: HTMLButtonElement | null = null;
  createEffect(() => {
    if (submit && isValid()) {
      submit.focus();
    }
  });

  return (
    <div class={classes.container}>
      <Typography component="h2" weight="bold">
        Authenticate
      </Typography>
      <Typography component="p">
        Enter the 6 digit code from your two factor authentication app
      </Typography>
      <Digits
        id="two-fa-digits"
        class="align-start"
        amount={AMOUNT_OF_DIGITS}
        onComplete={onDigitsComplete}
        error={getGlobalError(props.flow.ui)}
      />
      <Button
        id="submit"
        ref={(v) => (submit = v)}
        class="align-start"
        disabled={!isValid()}
        onClick={() => props.onSubmit(data)}
      >
        Submit
      </Button>
    </div>
  );
}
