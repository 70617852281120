import { createSignal, onCleanup, onMount, ParentProps, Show } from 'solid-js';

import AddFileIcon from '../../icons/AddFileIcon';
import PathIcon from './PathIcon';

import { Typography } from '../../Typography';
import { Link } from '../Link';

import classes from './file-input.module.css';

interface FileInputProps {
  id?: string;
  accept: string;
  multiple?: boolean;
  onSelected: (file: File[]) => void;
}

export function FileInput(props: ParentProps<FileInputProps>) {
  let input: HTMLInputElement | null = null;
  const [error, setError] = createSignal<string>();

  const ALLOWED_FILE_EXTENSIONS = props.accept.split(',').map((s) => s.trim());
  const UNSUPPORTED_FORMAT = `unsupported file format (${props.accept})`;
  const ONLY_ONE_FILE = 'please select one file at a time';

  function isFileExtensionValid(files: FileList) {
    return Array.from(files).every((file) =>
      ALLOWED_FILE_EXTENSIONS.some((ext) => file.name.endsWith(ext))
    );
  }

  function onFileChange(files: FileList | null | undefined) {
    if (!files) return;
    if (!props.multiple && files.length > 1) {
      setError(ONLY_ONE_FILE);
      return;
    }

    if (!isFileExtensionValid(files)) {
      setError(UNSUPPORTED_FORMAT);
      return;
    }

    props.onSelected(Array.from(files));
  }

  function onClick(e: MouseEvent) {
    e.preventDefault();
    setError();
    input?.click();
  }

  function onChange(e: Event) {
    const files = (e.target as HTMLInputElement).files;
    onFileChange(files);
  }

  function allowDrop(e: DragEvent) {
    e.stopPropagation();
    e.preventDefault();
    setError();
  }

  function onDrop(e: DragEvent) {
    e.stopPropagation();
    e.preventDefault();
    onFileChange(e.dataTransfer?.files);
  }

  onMount(() => {
    input?.addEventListener('change', onChange);
  });

  onCleanup(() => {
    input?.removeEventListener('change', onChange);
  });

  return (
    <div
      id={props.id}
      class={classes.container}
      onDragOver={allowDrop}
      onDrop={onDrop}
    >
      <input
        type="file"
        ref={(r) => (input = r)}
        accept={props.accept}
        multiple={props.multiple}
      />
      <div class={classes.icons}>
        <AddFileIcon />
        <PathIcon />
      </div>
      <Typography>
        Drag and drop {props.multiple ? 'files' : 'a file'}, or{' '}
        <Link onClick={onClick} href="">
          Browse
        </Link>
      </Typography>
      <Show when={error()}>
        {(err) => (
          <Typography
            aria-errormessage={err()}
            color="error"
            class={classes.error}
          >
            {err()}
          </Typography>
        )}
      </Show>
    </div>
  );
}
