export function CheckmarkIcon(props: {
  ariaLabel?: string;
  class?: string;
  classList?: {
    [k: string]: boolean | undefined;
  };
}) {
  return (
    <svg
      aria-label={`${props.ariaLabel}_checkmark`}
      class={props.class}
      classList={props.classList}
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="11"
        cy="11"
        r="10"
        fill="white"
        stroke="#6745B1"
        stroke-width="2"
      />
      <path
        d="M6 11.5556L9.18182 14.6667L16 8"
        stroke="#6745B1"
        stroke-width="2"
        stroke-linecap="round"
      />
    </svg>
  );
}
