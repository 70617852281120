import type { FileFormat } from '@prisma/client';
import { Accessor } from 'solid-js';

export const useFileFormat: (file: File | undefined) => Accessor<FileFormat> = (
  file
) => {
  if (!file) throw new Error('file is not found');

  return () => {
    const idx = file.name.lastIndexOf('.');
    if (idx < 0) throw new Error('file name is invalid');
    return file.name.substring(idx + 1).toUpperCase() as FileFormat;
  };
};
