import { Match, Show, Switch } from 'solid-js';
import { Typography } from '@imagene/components';
import { BiomarkerResultRange } from './BiomarkerResultRange';
import {
  displayProbability,
  displayText,
  isPositive,
} from '@imagene/biomarker-results';
import { ResultType } from '@imagene/api-interfaces';

import classes from './assayBiomarkerTable.module.css';

interface BiomarkerItemProps {
  showInconclusiveProbability: boolean;
  showProbability: boolean;
  result: ResultType;
  capitalize: boolean;
  class: string;
}

export function BiomarkerItem(props: BiomarkerItemProps) {
  const visible = () => props.result.visible;
  const itemColor = () =>
    visible() && !props.showProbability
      ? isPositive(props.result, props.showProbability)
        ? 'error'
        : 'default'
      : 'disabled';

  const probability = () => {
    return displayProbability(props.result.raw_probability);
  };

  return (
    <tr class={props.class}>
      <td>
        <Typography
          color={itemColor()}
          classList={{
            [classes['text-gray']]: !props.result.visible,
          }}
        >
          {props.result.biomarker.label}
        </Typography>
      </td>
      <td>
        <Typography
          aria-label="biomarker_item_result"
          color={itemColor()}
          component="span"
          classList={{
            [classes.capitalize]: props.capitalize,
            [classes['text-gray']]: !props.result.visible,
          }}
        >
          {displayText(props.result, {
            probabilityEnabled: props.showProbability,
            inconclusiveProbabilityEnabled: props.showInconclusiveProbability,
          })}
        </Typography>
      </td>
      <td>
        <Show when={props.showProbability && visible()}>
          <Switch>
            <Match when={props.result.biomarker.label.includes('EGFR')}>
              <BiomarkerResultRange
                range={[3, 25, 50, 70]}
                value={probability()}
                lowInnerLine
              />
            </Match>
            <Match when={props.result.biomarker.label.includes('ALK')}>
              <BiomarkerResultRange
                range={[1, 25, 50, 70]}
                value={probability()}
              />
            </Match>
          </Switch>
        </Show>
      </td>
    </tr>
  );
}
