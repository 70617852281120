import { Input, Tooltip, Typography } from '@imagene/components';
import { createSignal, ParentProps, Show } from 'solid-js';
import { SlideDetails } from '../../SlideDetailsSubHeader';
import { UploadSlideDetails } from '../../types';
import { useTenant } from '../../../../utils/use-tenant';
import { PageCardNavigation } from '../../../../components/PageCardNavigation';

import classes from './slide-details-configure.module.css';
import { useAnalysisCreation } from '../../use-create-analysis';
import { InclusionCriteriaModal } from '../../../../components/InclusionCriteriaModal';
import { WsiCreateDto, CreateWsiResponse } from '@imagene/api-interfaces';
import { createMutation, useQueryClient } from '@tanstack/solid-query';
import { axiosClient } from '../../../../utils/axios';

export interface SlideDetailConfigureProps {
  file?: File;
  onConfigure: (details: UploadSlideDetails) => void;
  onBack: VoidFunction;
  onDecline: VoidFunction;
}

export function SlideDetailsConfigure(
  props: ParentProps<SlideDetailConfigureProps>
) {
  let formRef: HTMLFormElement;
  const queryClient = useQueryClient();
  const config = useTenant();
  const [isValid, setIsValid] = createSignal<boolean>(false);
  const [details, setDetails] = useAnalysisCreation();
  const [criteriaOpen, setCriteriaOpen] = createSignal<boolean>(false);

  const createWsi = createMutation(
    (data: WsiCreateDto) =>
      axiosClient
        .post<CreateWsiResponse>('/api/v1/wsi', data)
        .then((res) => res.data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['quota']);
      },
    }
  );

  function validateForm() {
    setIsValid(formRef.reportValidity());
  }

  function onNext() {
    validateForm();
    if (config().criteria_confirmation_enabled && isValid()) {
      setCriteriaOpen(true);
    } else {
      onSubmit();
    }
  }

  async function onSubmit() {
    validateForm();
    if (isValid()) {
      const wsi = await createWsi.mutateAsync({
        accession: details.accession,
        slide_id: details.slide_id,
        fileName: details.file.name,
        format: details.file.format,
        size_in_bytes: details.file.size,
      });

      props.onConfigure({ ...details, wsi_id: wsi.id });
    }
  }

  return (
    <PageCardNavigation
      title="Add slides"
      description="Add slide's data"
      subheader={<SlideDetails panel />}
      onNext={onNext}
      onBack={props.onBack}
    >
      <div class={classes['form-wrapper']}>
        <div>
          <div class={classes['form-title']}>
            <Typography weight="bold">Selected slide:</Typography>
            <Tooltip text={details.file.name}>
              <Typography id="selected_slide" wrap="20ch">
                {details.file.name}
              </Typography>
            </Tooltip>
          </div>
          <form class={classes['form-inputs']} ref={(r) => (formRef = r)}>
            <Input
              id="slide_id"
              label={'Type Slide ID'}
              placeholder={'Slide ID'}
              class={classes['rename-input']}
              onInput={(e) => {
                e.currentTarget.setCustomValidity('');
                setDetails('slide_id', e.currentTarget.value);
              }}
              value={details.slide_id}
              required
              pattern=".{10,50}"
              autocomplete="off"
              onInvalid={(e) => {
                if (e.currentTarget.validity.patternMismatch) {
                  if (details.slide_id.length < 10) {
                    e.currentTarget.setCustomValidity(
                      'Slide ID must have minimum 10 characters'
                    );
                  } else if (details.slide_id.length >= 10) {
                    e.currentTarget.setCustomValidity(
                      'Slide ID must have maximum 50 characters'
                    );
                  }
                }
                if (e.currentTarget.validity.valueMissing) {
                  e.currentTarget.setCustomValidity(
                    'Slide ID is a mandatory field'
                  );
                }
              }}
            />
            <Show when={config().accession_enabled}>
              <Input
                id="accession_number"
                label={'Type Accession ID'}
                placeholder={'Accession ID'}
                class={classes['rename-input']}
                onInput={(e) => {
                  e.currentTarget.setCustomValidity('');
                  setDetails('accession', e.currentTarget.value);
                }}
                onInvalid={(e) => {
                  if (e.currentTarget.validity.patternMismatch) {
                    e.currentTarget.setCustomValidity(
                      'Accession number must have minimum 5 characters'
                    );
                  }
                  if (e.currentTarget.validity.valueMissing) {
                    e.currentTarget.setCustomValidity(
                      'Accession number is a mandatory field'
                    );
                  }
                }}
                value={details.accession}
                required
                pattern=".{5,50}"
                autocomplete="off"
              />
            </Show>
          </form>
        </div>
      </div>
      <InclusionCriteriaModal
        open={criteriaOpen()}
        headline="Please confirm that selected slides meet all criteria"
        onClose={() => setCriteriaOpen(false)}
        onConfirm={onSubmit}
        onDecline={props.onDecline}
      />
    </PageCardNavigation>
  );
}
