import { createSignal } from 'solid-js';
import { createMutation } from '@tanstack/solid-query';
import { Button, Modal, useToast } from '@imagene/components';
import { axiosClient } from '../../../utils/axios';

import classes from '../users.module.css';

export function ResendInvite(props: { id: string; email: string }) {
  const [isOpen, setIsOpen] = createSignal<boolean>(false);
  const { toast } = useToast();

  const user = createMutation(
    (userId: string) =>
      axiosClient
        .post(`/api/v1/admin/users/${userId}/invite`)
        .then((res) => res.data),
    {
      onSuccess: () => {
        toast({
          title: 'Invitation sent!',
          description: `Invitation was sent to email ${props.email} successfully`,
          dismissAfter: 5000,
        });
      },
      onError: () => {
        toast({
          type: 'error',
          title: 'Failed to invite',
          description: `Invitation email was not sent due to technical issues. Please try again later.`,
          dismissAfter: 5000,
        });
      },
    }
  );

  async function resendInvite() {
    user.mutate(props.id);
  }

  return (
    <>
      <Button
        variant="secondary"
        class={classes['action-btn']}
        onClick={() => setIsOpen(true)}
      >
        Re-send invitation
      </Button>
      <Modal
        open={isOpen()}
        onClose={() => setIsOpen(false)}
        onAction={resendInvite}
        closeBtnText="Not now"
        actionBtnText="Send invitation"
        title="Send invitation?"
        text={`Send invitation to email ${props.email}?
               User will receive additional invitation email with registration link to ImageneOI.`}
      />
    </>
  );
}
