import * as slider from '@zag-js/slider';
import { normalizeProps, useMachine } from '@zag-js/solid';
import {
  For,
  createEffect,
  createMemo,
  createUniqueId,
  splitProps,
} from 'solid-js';
import SliderThumb from '../icons/SliderThumb';

import classes from './range-slider.module.css';

export type RangeSliderProps = Pick<
  slider.Context,
  | 'name'
  | 'value'
  | 'invalid'
  | 'readOnly'
  | 'disabled'
  | 'onValueChange'
  | 'min'
  | 'max'
  | 'step'
> & { inverse?: boolean };

export function RangeSlider(props: RangeSliderProps) {
  const [context, local] = splitProps(props, [
    'value',
    'disabled',
    'onValueChange',
  ]);
  const [state, send] = useMachine(
    slider.machine({
      id: createUniqueId(),
      minStepsBetweenThumbs: 1,
    }),
    { context: context }
  );

  createEffect(() => {
    api().setValue(props.value ?? []);
  });

  const api = createMemo(() => slider.connect(state, send, normalizeProps));
  const isSquooshed = () =>
    context.value && context.value[1] - context.value[0] <= 2;

  return (
    <div
      class={classes.slider}
      {...api().getRootProps()}
      classList={{
        [classes.inverse]: local.inverse,
        [classes.squooshed]: isSquooshed(),
      }}
    >
      <div {...api().getControlProps()}>
        <div {...api().getTrackProps()}>
          <div {...api().getRangeProps()} />
        </div>
        <For each={api().value}>
          {(threshold, index) => (
            <div {...api().getThumbProps({ index: index() })}>
              <input {...api().getHiddenInputProps({ index: index() })} />
              <SliderThumb />
              <div {...api().getMarkerProps({ value: threshold })}>
                {threshold}%
              </div>
            </div>
          )}
        </For>
      </div>
    </div>
  );
}
