export function RedXIcon() {
  return (
    <svg width="10" height="11" viewBox="0 0 10 11" fill="none">
      <line
        x1="1"
        y1="-1"
        x2="11"
        y2="-1"
        transform="matrix(0.707107 0.707107 -0.695997 0.718044 0 2)"
        stroke="#C73434"
        stroke-width="2"
        stroke-linecap="round"
      />
      <line
        x1="1"
        y1="-1"
        x2="11"
        y2="-1"
        transform="matrix(0.707107 -0.707107 0.695997 0.718044 1.11914 10.7705)"
        stroke="#C73434"
        stroke-width="2"
        stroke-linecap="round"
      />
      <line
        x1="1"
        y1="-1"
        x2="11"
        y2="-1"
        transform="matrix(0.707107 0.707107 -0.695997 0.718044 0 2)"
        stroke="#C73434"
        stroke-width="2"
        stroke-linecap="round"
      />
      <line
        x1="1"
        y1="-1"
        x2="11"
        y2="-1"
        transform="matrix(0.707107 -0.707107 0.695997 0.718044 1.11914 10.7705)"
        stroke="#C73434"
        stroke-width="2"
        stroke-linecap="round"
      />
    </svg>
  );
}
