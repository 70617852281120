import { ImageneIdentity } from '@imagene/api-interfaces';
import { Session } from '@ory/client';
import {
  createContext,
  createSignal,
  onMount,
  ParentProps,
  useContext,
} from 'solid-js';
import * as Sentry from '@sentry/browser';
import { makePersisted } from '@solid-primitives/storage';

const [session, setInternalSession] = makePersisted(
  createSignal<Session | null>(null),
  { name: 'imagene_user' }
);

const [isAAL2Required, setAAL2Required] = makePersisted(
  createSignal<boolean | null>(null),
  {
    name: 'imagene_user_aal2',
  }
);

const identity = () => session()?.identity as ImageneIdentity | undefined;

const role = () => identity()?.metadata_public?.role;

const username = () => identity()?.metadata_public?.full_name;

const tenantId = () => identity()?.metadata_public?.tenant_id;

function setSession(currentSession: Session) {
  setInternalSession(currentSession);
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const user = currentSession.identity!;
  Sentry.setUser({
    id: user.id,
    email: user.traits.email,
    username: username(),
    role: user.traits.role,
  });
}

function clearSession() {
  setAAL2Required(null);
  setInternalSession(null);
  Sentry.setUser(null);
}

const Context = {
  session,
  setSession,
  clearSession,
  username,
  role,
  tenantId,
  identity,
  isAAL2Required,
  setAAL2Required,
};
export const AuthContext = createContext(Context);
export const useAuth = () => useContext(AuthContext);

export function Auth(props: ParentProps) {
  onMount(() => setInternalSession(session()));
  return (
    <AuthContext.Provider value={Context}>
      {props.children}
    </AuthContext.Provider>
  );
}
