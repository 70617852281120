import { UiNode, UiNodeImageAttributes } from '@ory/client';
import { ParentProps } from 'solid-js';

interface QRCodeProps {
  node: UiNode;
}

export function QRCode(props: ParentProps<QRCodeProps>) {
  const attr = () => props.node.attributes as UiNodeImageAttributes;
  return (
    <img
      id={attr().id}
      width={attr().width}
      height={attr().height}
      src={attr().src}
      alt={props.node.meta.label?.text}
    />
  );
}
