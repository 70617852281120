import { useLocation, useSearchParams } from '@solidjs/router';

export function useReturnTo() {
  const ignoredPages = ['/', '/login', '/login/', '/login/2fa', '/login/2fa/'];
  const [params] = useSearchParams();
  const location = useLocation();
  if (params.return_to) return params.return_to;
  if (ignoredPages.includes(location.pathname)) return undefined;
  return encodeURIComponent(location.pathname + (location.search ?? ''));
}

export function useReturnToQueryString(returnTo: string | undefined) {
  if (returnTo) {
    return `?return_to=${returnTo}`;
  }
  return '/';
}
