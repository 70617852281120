import { Progress, Typography } from '@imagene/components';
import { Show } from 'solid-js';
import classes from './run-limit-range.module.css';
import { useShowLimit } from './use-show-limit';

export function RunsLimitRangeCompact() {
  const { values, show } = useShowLimit();
  return (
    <Show when={show()}>
      <div class={classes.container} classList={{ [classes.compact]: true }}>
        <div class={classes.text}>
          <Typography color="primary">Early Access Program:</Typography>
          <Typography color="primary">
            {values().cur} / {values().total}
          </Typography>
        </div>
        <Progress max={values().total} value={values().cur} />
      </div>
    </Show>
  );
}
