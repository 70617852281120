import { ErrorBoundary, JSX, ParentProps } from 'solid-js';
import * as Sentry from '@sentry/browser';
import { datadogLogs } from '@datadog/browser-logs';

interface LogErrorBoundaryProps {
  scope?: string;
  component: JSX.Element;
}

export function LogErrorBoundary(props: ParentProps<LogErrorBoundaryProps>) {
  if (props.scope) {
    Sentry.configureScope((scope) => {
      scope.setTag('scope', props.scope);
    });
  }

  return (
    <ErrorBoundary
      fallback={(e) => {
        Sentry.captureException(e, {
          level: 'error',
        });
        datadogLogs.logger.error(
          `LogErrorBoundary error: ${JSON.stringify(e)}`
        );
        return props.component;
      }}
    >
      {props.children}
    </ErrorBoundary>
  );
}
