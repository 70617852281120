import { ParentProps, createContext, useContext } from 'solid-js';
import { createSignal } from 'solid-js';

const [isUploading, setIsUploading] = createSignal(false);
const uploadContext = {
  isUploading,
  setIsUploading,
};
const UploadContext = createContext(uploadContext);

export function useUpload() {
  return useContext(UploadContext);
}

export function UploadProvider(props: ParentProps) {
  return (
    <UploadContext.Provider value={uploadContext}>
      {props.children}
    </UploadContext.Provider>
  );
}
