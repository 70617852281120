export enum ERROR_CODE {
  // general errors
  ERROR_INVALID_CHECKSUM = '0001',
  ERROR_MANUAL_UPLOAD = '0002',
  ERROR_AGENT_UPLOAD = '0003',
  ERROR_JS_OBJECT_STORAGE_UPLOAD = '0004',
  ERROR_JS_OBJECT_STORAGE_DOWNLOAD = '0005',
  ERROR_PYTHON_OBJECT_STORAGE_UPLOAD = '0006',
  ERROR_PYTHON_OBJECT_STORAGE_DOWNLOAD = '0007',
  CORRUPTED_FILE = '0008',
  ERROR_WSI_READER_EXCEPTION = '0009',
  ERROR_PYTHON_CLOUDEVENT_EMITTER = '0010',
  ERROR_JS_CLOUDEVENT_EMITTER = '0011',
  ERROR_PDF_GENERATOR = '0012',
  ERROR_LOAD_MODEL_FAILED = '0013',
  ERROR_RUN_MODEL_FAILED = '0014',
  ERROR_REPORT_MISSING = '0015',
  ERROR_REPORT_NAME_MISSING = '0016',
  ERROR_GENERATE_PDF_FAILED = '0017',
  ERROR_INVALID_APP_VERSION = '0018',
  ERROR_WORKER_FAILURE = '0019',
  ERROR_NO_CONTENT_LENGTH = '0020',
  ERROR_WSI_HTTP_SYNC = '0021',
  ERROR_WSI_RCLONE_SYNC = '0022',
  ERROR_BATCH_SYNC = '0023',
  ERROR_BATCH_PROCESS = '0024',
  // Warnings
  WARNING_WSI_THUMBNAIL_NA = '1001',
  // Informational
  INFO_QC_LOW_TISSUE = '2001',
  INFO_WSI_LOW_MAGNIFICATION = '2002',
  INFO_WSI_MAGNIFICATION_NA = '2003',
  INFO_TISSUE_STRUCTURE_QC = '2004',
  INFO_LUNG_ORIGIN_QC = '2005',
}

export enum ERROR_CAUSE {
  WSI_WAS_NOT_UPLOADED = 'WSI_WAS_NOT_UPLOADED',
}
