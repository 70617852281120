import { createMutation, useQueryClient } from '@tanstack/solid-query';
import { Button, Card, Typography } from '@imagene/components';
import { axiosClient } from '../../utils/axios';

import classes from './settings.module.css';
import { PlatformConfiguration } from '@imagene/api-interfaces';

function TwoFAHeader(props: { require2FA: boolean | undefined }) {
  return (
    <Typography class={classes.status}>
      {props.require2FA ? 'Enabled' : 'Disabled'}
    </Typography>
  );
}

export function TwoFactorAuth(props: { config: PlatformConfiguration }) {
  const queryClient = useQueryClient();
  const toggle2FA = createMutation(() =>
    axiosClient.patch('/api/v1/admin/org/2fa')
  );

  async function toggle() {
    await toggle2FA.mutateAsync();
    await queryClient.invalidateQueries(['tenant']);
  }

  return (
    <Card
      title="Two Factor Authentication"
      header={<TwoFAHeader require2FA={props.config.require_2fa} />}
    >
      <div class={classes.content}>
        <Button onClick={() => toggle()}>
          {props.config.require_2fa ? 'Disable' : 'Enable'} Two Factor
          Authentication
        </Button>
        <Typography component="p">
          Enabling 2FA is highly recommended to ensure the security of your
          organization accounts and reduce the risks associated with compromised
          passwords.
        </Typography>
        <Typography component="p">
          Enabled Two Factor Authentication
          <br />
          Users are required to setup their 2FA method using one of the
          Authentication applications of their choice (e.g. Google
          Authenticator, Authy, etc) during their next login. User session is
          not interrupted, when admin is enabling the 2FA.
        </Typography>
        <Typography component="p">
          Disabled Two Factor Authentication
          <br />
          New users will not be required to setup 2FA. Existing users with
          configured 2FA will continue to login with 2FA. In order to disable
          2FA for existing users, please do the following steps:
          <br />
          <ol>
            <li>
              Press ‘Disable Two Factor Authentication’ button in ‘Settings‘
              page
            </li>
            <li>Open ‘User Management’ page</li>
            <li>
              Press ‘Reset 2FA’ button for the each user who is not required to
              login with 2FA
            </li>
          </ol>
        </Typography>
      </div>
    </Card>
  );
}
