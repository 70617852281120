import { createSignal } from 'solid-js';
import { useSearchParams } from '@solidjs/router';
import { PaginationState } from '@tanstack/solid-table';

const DEFAULT_PAGE_SIZE = 10;

export function usePagination() {
  const [searchParams, setSearchParams] = useSearchParams();
  const [pageState, setPageState] = createSignal<PaginationState>({
    pageIndex: parseInt(searchParams['page'] ?? 1),
    pageSize: DEFAULT_PAGE_SIZE,
  });

  const changePage = (newState: PaginationState) => {
    setPageState(newState);
    setSearchParams({ page: newState.pageIndex });
  };

  const pageCount = (count: number | undefined) =>
    Math.ceil((count ?? 0) / DEFAULT_PAGE_SIZE);

  return {
    pageState,
    changePage,
    pageCount,
    setSearchParams,
  };
}
