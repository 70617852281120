import { useSearchParams } from '@solidjs/router';
import { Input } from '../Input';
import { IconButton } from '../IconButton';
import SearchIcon from '../../icons/SearchIcon';

import classes from './search-input.module.css';
import { createSignal, Show } from 'solid-js';

const DEFAULT_SEARCH_PARAM = 'search';

export interface SearchProps {
  id?: string;
  placeholder?: string;
  onSearch?: (term: string) => void;
  searchParamName?: string;
  skipParams?: boolean;
  containerClass?: string;
  showBtn?: boolean;
}

export function Search(props: SearchProps) {
  const [params, setParams] = useSearchParams();
  const [term, setTerm] = createSignal(
    params[props.searchParamName ?? DEFAULT_SEARCH_PARAM] ?? ''
  );

  function setSearch(newTerm: string) {
    setTerm(newTerm);
    if (!props.skipParams) {
      setParams({
        ...params,
        [props.searchParamName ?? DEFAULT_SEARCH_PARAM]: newTerm,
      });
    }
  }

  function search() {
    props.onSearch?.(term());
  }

  function onKeyEvent(e: KeyboardEvent) {
    if (e.key === 'Enter' || e.key === 'Escape') {
      search();
    }
  }

  return (
    <div
      classList={{
        [classes.search]: true,
        [props.containerClass ?? '']: !!props.containerClass,
      }}
    >
      <Input
        id={props.id ?? 'search'}
        placeholder={props.placeholder}
        type="search"
        required
        hideErrorIcon
        onInput={(e) => {
          setSearch(e.currentTarget.value.trim());
          search();
        }}
        onKeyDown={onKeyEvent}
        value={term()}
      />
      <Show when={props.showBtn}>
        <IconButton id={`${props.id ?? 'search'}_btn`} onClick={search}>
          <SearchIcon />
        </IconButton>
      </Show>
    </div>
  );
}
