export function TickIcon() {
  return (
    <svg
      width="11"
      height="9"
      viewBox="0 0 11 9"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 4.2L3.86364 7L10 1"
        stroke-width="2"
        stroke-linecap="round"
        style={{ stroke: 'currentcolor' }}
      />
    </svg>
  );
}
