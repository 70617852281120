import { createSignal } from 'solid-js';
import { createMutation } from '@tanstack/solid-query';
import { axiosClient } from '../../../utils/axios';
import { UpdateUserStateDto } from '@imagene/auth';
import { Button, Modal, useToast } from '@imagene/components';
import queryClient from '../../../utils/queryClient';

import classes from '../users.module.css';

export function Deactivate(props: { id: string; email: string }) {
  const [isOpen, setIsOpen] = createSignal<boolean>(false);
  const { toast } = useToast();

  const user = createMutation(
    (data: UpdateUserStateDto) =>
      axiosClient
        .patch(`/api/v1/admin/users/${props.id}`, data)
        .then((res) => res.data),
    {
      onSuccess: () => {
        toast({
          title: 'Account deactivated!',
          description: `Account ${props.email} was de-activated successfully`,
          dismissAfter: 5000,
        });
        queryClient.invalidateQueries(['users']);
      },
      onError: () => {
        toast({
          type: 'error',
          title: 'Failed to de-activate',
          description: `Account ${props.email} was not de-activated due to technical issues. Please try again later.`,
          dismissAfter: 5000,
        });
      },
    }
  );

  function deactivateAccount() {
    user.mutate({
      state: 'inactive',
    });
  }

  return (
    <>
      <Button
        variant="secondary"
        class={classes['action-btn']}
        onClick={() => setIsOpen(true)}
      >
        Deactivate
      </Button>
      <Modal
        open={isOpen()}
        onClose={() => setIsOpen(false)}
        onAction={deactivateAccount}
        closeBtnText="Keep account active"
        actionBtnText="De-activate account"
        title="De-activate account?"
        text={`De-activate account ${props.email}?
                 User won't be able to login to ImageneOI and use it. 
                 All slide analyses implemented by the user will be kept in a system with the name of submitted user. 
                 You can activate user account later by clicking on 'Activate' button.`}
      />
    </>
  );
}
