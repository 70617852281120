import {
  createEffect,
  createSignal,
  createUniqueId,
  For,
  Match,
  ParentProps,
  Show,
  Switch,
} from 'solid-js';
import { useOutsideClickListener } from '../../hooks/use-outside-click-listener';
import DownArrowIcon from '../../icons/DownArrowIcon';
import { RadioButton } from '../RadioButton';
import { Typography } from '../../Typography';
import { Spinner } from '../../Spinner';

import classes from './select.module.css';

export interface SelectOption<T> {
  id: string;
  label: string;
  value: T;
}

export type SelectOptionProps<T> = Pick<SelectProps<T>, 'highlight' | 'style'> &
  Pick<SelectOption<T>, 'label'> & {
    selected: boolean;
    onSelect: VoidFunction;
  };

export interface SelectProps<T> {
  id?: string;
  options: SelectOption<T>[];
  onSelect?: (option: SelectOption<T>) => void;
  disabled?: boolean;
  containerClass?: string;
  class?: string;
  label?: string;
  value?: string;
  loading?: boolean;
  highlight?: boolean;
  placeholder?: string;
  direction?: 'up' | 'down';
  style?: 'default' | 'radio';
}

export function Select<T>(props: ParentProps<SelectProps<T>>) {
  let selectInput: HTMLDivElement;
  const dropdownId = createUniqueId();
  const [open, setOpen] = createSignal(false);
  const [selectedIndex, setSelectedIndex] = createSignal<number>();
  const direction = () => props.direction ?? 'down';
  const style = () => props.style ?? 'radio';

  createEffect(() => {
    setSelectedIndex(props.options.findIndex((o) => o.value === props.value));
  });

  useOutsideClickListener({
    el: () => selectInput,
    targetId: dropdownId,
    onOutsideClick: () => setOpen(false),
  });

  const currentValue = () => {
    const index = selectedIndex();
    if (index === undefined) return null;

    return props.options[index];
  };

  const displayValue = () => currentValue()?.label ?? props.placeholder ?? '';

  function toggle() {
    setOpen((prev) => !prev);
  }

  function onSelect(index: number) {
    setSelectedIndex(index);
    setOpen(false);
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    props.onSelect?.(currentValue()!);
  }

  return (
    <div
      classList={{
        [classes.select]: true,
        [classes.open]: open(),
        [classes.disabled]: props.disabled,
        [props.containerClass ?? '']: !!props.containerClass,
      }}
    >
      <Show when={props.label}>
        <label class={classes.label}>{props.label}</label>
      </Show>
      <div
        id={props.id}
        classList={{
          [classes.input]: true,
          [props.class ?? '']: !!props.class,
        }}
        onClick={toggle}
        ref={(r) => (selectInput = r)}
      >
        <Show when={props.loading}>
          <Spinner />
        </Show>
        <Typography
          classList={{
            [classes.value]: true,
            [classes.placeholder]: displayValue() === props.placeholder,
          }}
        >
          {displayValue()}
        </Typography>
        <DownArrowIcon />
      </div>
      <div>
        <ul
          id={dropdownId}
          classList={{
            [classes.dropdown]: true,
            [classes.highlight]: props.highlight,
            [classes.above]: direction() === 'up',
          }}
        >
          <For each={props.options}>
            {(option, i) => (
              <SelectOption
                label={option.label}
                selected={i() === selectedIndex()}
                onSelect={() => onSelect(i())}
                highlight={props.highlight}
                style={style()}
              />
            )}
          </For>
        </ul>
      </div>
    </div>
  );
}

function SelectOption<T>(props: SelectOptionProps<T>) {
  return (
    <Switch>
      <Match when={props.style === 'default'}>
        <div onClick={props.onSelect}>{props.label}</div>
      </Match>
      <Match when={props.style === 'radio'}>
        <RadioButton
          label={props.label}
          checked={props.selected}
          onChange={props.onSelect}
        />
      </Match>
    </Switch>
  );
}
