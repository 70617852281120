import { Link } from '@solidjs/router';
import { ParentProps, JSX, Show } from 'solid-js';
import classes from './sidebar.module.css';

export interface SideBarItemProps {
  icon: JSX.Element;
  text: string;
  id: string;
  route: string;
  target?: '_self' | '_blank' | '_parent' | '_top';
  visible?: boolean;
  classList?: Record<string, boolean | undefined>;
  onDisabledClick?: VoidFunction;
  disabled: boolean;
  onClick?: VoidFunction;
}

export function SideBarItem(props: ParentProps<SideBarItemProps>) {
  return (
    <Show when={props.visible ?? false}>
      <Link
        href={props.route}
        target={props.target}
        classList={{ [classes.disabled]: props.disabled }}
        onClick={(e) => {
          if (props.disabled) {
            e.preventDefault();
            props.onDisabledClick?.();
          } else if (props.onClick) {
            e.preventDefault();
            props.onClick();
          }
        }}
        activeClass={classes.active}
      >
        <li
          classList={{
            ...props.classList,
            [classes.item]: true,
            [classes.disabled]: props.disabled,
          }}
          id={props.id}
        >
          {props.icon}
          {props.text}
        </li>
      </Link>
    </Show>
  );
}
