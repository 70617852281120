import { Show } from 'solid-js';
import { FileInput, Typography } from '@imagene/components';

import classes from './slides-drag-n-drop.module.css';

export interface SlidesDragAndDropProps {
  onFileSelected: (file: File[]) => void;
  multiple?: boolean;
  fileName?: string;
  isFileSelected: boolean;
}

export function SlidesDragAndDrop(props: SlidesDragAndDropProps) {
  return (
    <section class={classes.slides}>
      <FileInput
        id="slide_input"
        accept=".svs,.ndpi,.tiff,.tif,.isyntax"
        multiple={props.multiple}
        onSelected={props.onFileSelected}
      />
      <Show when={props.isFileSelected}>
        <Typography color="primary" weight="semi-bold">
          Selected file:&nbsp;
          <Typography
            component="span"
            weight="semi-bold"
            color="primary"
            aria-label="selected_file_name"
          >
            {props.fileName}
          </Typography>
        </Typography>
      </Show>
    </section>
  );
}
