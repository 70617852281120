export default function BatchIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="32"
      viewBox="0 0 32 32"
      width="32"
      fill="none"
    >
      <path
        d="M11.6055 16.1758V25.1992M6.80078 20.3945H15.8242M21.625 26.3125V12.25C21.625 11.2145 20.7855 10.375 19.75 10.375H2.875C1.83947 10.375 1 11.2145 1 12.25V29.125C1 30.1605 1.83947 31 2.875 31H19.75C20.7855 31 21.625 30.1605 21.625 29.125V26.3125ZM21.625 26.3125H24.4375C25.473 26.3125 26.3125 25.473 26.3125 24.4375V7.5625C26.3125 6.52697 25.473 5.6875 24.4375 5.6875H7.5625C6.52697 5.6875 5.6875 6.52697 5.6875 7.5625V10.375M10.375 5.6875V2.875C10.375 1.83947 11.2145 1 12.25 1H29.125C30.1605 1 31 1.83947 31 2.875V19.75C31 20.7855 30.1605 21.625 29.125 21.625H26.3125"
        stroke="currentColor"
        stroke-width="2"
      />
    </svg>
  );
}
