import { createEffect, createSignal, ParentProps, Show } from 'solid-js';
import { createStore } from 'solid-js/store';
import {
  SettingsFlow,
  UpdateSettingsFlowWithPasswordMethod,
} from '@ory/client';
import { PasswordErrors, ValidationError } from './PasswordErrors';

import { AuthInput, Button, Checkbox, Input } from '@imagene/components';
import {
  passwordSchema,
  getUiNode,
  getUiNodeAttributes,
} from '../../utils/auth';

import classes from './reset-password.module.css';
import { useTermsOfService } from '../../utils/use-terms-of-service';

interface ResetPasswordFormProps {
  flow: SettingsFlow;
  onSubmit: (values: UpdateSettingsFlowWithPasswordMethod) => void;
}

export function ResetPasswordForm(props: ParentProps<ResetPasswordFormProps>) {
  const csrf = getUiNodeAttributes(props.flow.ui.nodes, 'csrf_token');
  const email = getUiNodeAttributes(props.flow.ui.nodes, 'traits.email');
  const [tos, setTermsOfService] = createSignal(false);
  const [formValues, setFormValues] = createStore<
    UpdateSettingsFlowWithPasswordMethod & { confirm_password: string }
  >({
    method: 'password',
    password: '',
    csrf_token: csrf.value,
    confirm_password: '',
  });
  const [errors, setErrors] = createSignal<ValidationError[]>([]);
  const { needSignature, sign, link } = useTermsOfService();

  const valid = () =>
    errors().length === 0 &&
    (!needSignature() || (needSignature() && tos())) &&
    sign.isIdle;

  createEffect(() => {
    let errors = passwordSchema.validate(formValues.password, {
      details: true,
    }) as ValidationError[];

    if (formValues.password !== formValues.confirm_password) {
      errors = Array.isArray(errors)
        ? [{ validation: 'match' }, ...errors]
        : [{ validation: 'match' }];
    }

    setErrors(errors);
  });

  function onTermsOfServiceToggle() {
    setTermsOfService((prev) => !prev);
  }

  async function onSubmit(e: SubmitEvent) {
    e.preventDefault();

    if (valid()) {
      if (needSignature()) {
        await sign.mutateAsync();
      }
      props.onSubmit({ ...formValues });
    }
  }

  return (
    <form class={classes.form} onSubmit={onSubmit}>
      <Input label="Username" value={email.value} disabled disableOptionalTxt />
      <AuthInput
        label="Password"
        placeholder="New password"
        node={getUiNode(props.flow.ui.nodes, 'password')}
        onInput={(val) => setFormValues('password', val)}
      />
      <Input
        label="Confirm password"
        type="password"
        required
        placeholder="Confirm password"
        onInput={(e) =>
          setFormValues('confirm_password', e.currentTarget.value)
        }
      />
      <PasswordErrors errors={errors()} />
      <Show when={needSignature()}>
        <Checkbox
          name="tos"
          label={
            <>
              Click here to indicate that you have read and agree to the
              Imagene's&nbsp;
              <a target="_blank" href={link()}>
                Terms of Use
              </a>
            </>
          }
          checked={tos()}
          onChange={onTermsOfServiceToggle}
        />
      </Show>
      <Button class={classes.set} disabled={!valid()}>
        Set password
      </Button>
    </form>
  );
}
