import { onCleanup, onMount } from 'solid-js';

export function usePreventPageClose() {
  function onBeforeUnload(e: BeforeUnloadEvent) {
    e.preventDefault();
    e.returnValue = '';
  }

  onMount(() => window.addEventListener('beforeunload', onBeforeUnload));
  onCleanup(() => window.removeEventListener('beforeunload', onBeforeUnload));
}
