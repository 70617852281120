import { render } from 'solid-js/web';
import { Router } from '@solidjs/router';
import { App } from './App';

const root = document.getElementById('root');
if (!root) throw new Error('root element not found!');

render(
  () => (
    <Router>
      <App />
    </Router>
  ),
  root
);
