interface ArrowProps {
  class?: string;
}

export default function ArrowIcon(props: ArrowProps) {
  return (
    <svg
      class={props.class ?? ''}
      width="20"
      height="12"
      viewBox="0 0 20 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18 10L10 2L2 10"
        stroke="black"
        stroke-width="3"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}
