import { Match, Switch } from 'solid-js';

import { CheckmarkIcon } from '@imagene/components/icons/CheckmarkIcon';
import { CircleFillIcon } from '@imagene/components/icons/CircleFillIcon';
import Loader from '@imagene/components/icons/Loader';
import { DoubleErrorIcon } from '@imagene/components/icons/DoubleErrorIcon';

export enum StatusIcons {
  unknown = 'unknown',
  ongoing = 'ongoing',
  done = 'done',
  error = 'error',
}

interface StatusIndicatorProps {
  status: StatusIcons | undefined;
  ariaLabel: string;
  class?: string;
  classList?: { [k: string]: boolean | undefined };
}

export function StatusIndicator(props: StatusIndicatorProps) {
  return (
    <Switch>
      <Match when={props.status === StatusIcons.unknown}>
        <CircleFillIcon
          class={props.class}
          classList={props.classList}
          ariaLabel={props.ariaLabel}
        />
      </Match>
      <Match when={props.status === StatusIcons.ongoing}>
        <Loader ariaLabel={props.ariaLabel} />
      </Match>
      <Match when={props.status === StatusIcons.done}>
        <CheckmarkIcon
          class={props.class}
          classList={props.classList}
          ariaLabel={props.ariaLabel}
        />
      </Match>
      <Match when={props.status === StatusIcons.error}>
        <DoubleErrorIcon
          class={props.class}
          classList={props.classList}
          ariaLabel={props.ariaLabel}
        />
      </Match>
    </Switch>
  );
}
