import { Transition } from 'solid-transition-group';
import { createQuery } from '@tanstack/solid-query';
import { axiosClient } from '../../utils/axios';
import { createSignal } from 'solid-js';
import { PaginationState } from '@tanstack/solid-table';
import { useNavigate, useSearchParams } from '@solidjs/router';
import { useTableColumns } from './use-table-columns';
import { UsersTableSkeleton } from './UsersTableSkeleton';
import { Button, Table, Typography } from '@imagene/components';
import { UsersQueryResponse } from '@imagene/auth';

import classes from './users.module.css';

const DEFAULT_PAGE_SIZE = 10;

export interface UsersTableType {
  user: {
    email: string;
    user_id: string;
  };
  status: string;
  name: string;
  role: string;
}

export function Users() {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [pageState, setPageState] = createSignal<PaginationState>({
    pageIndex: parseInt(searchParams['page'] ?? 1),
    pageSize: DEFAULT_PAGE_SIZE,
  });

  const query = createQuery(
    () => ['users', pageState().pageIndex],
    async () => {
      setSearchParams({
        page: pageState().pageIndex,
      });
      const res = await axiosClient.get<UsersQueryResponse>(
        '/api/v1/admin/users',
        {
          params: {
            page: pageState().pageIndex,
          },
        }
      );
      return res.data;
    },
    {
      refetchOnWindowFocus: false,
      refetchInterval: 15000,
    }
  );

  const changePage = (newState: PaginationState) => {
    setPageState(newState);
  };

  const pageCount = () =>
    Math.ceil((query?.data?.count ?? 0) / DEFAULT_PAGE_SIZE);

  const tableData = (): UsersTableType[] => query.data?.data ?? [];

  return (
    <Transition appear name="fade">
      <div
        classList={{
          [classes.container]: true,
          'page-margin': true,
        }}
      >
        <section class={classes.row}>
          <Typography weight="bold" component="h1" class={classes.title}>
            Users Management
          </Typography>
          <Button onClick={() => navigate('/admin/users/create')}>
            Create User
          </Button>
        </section>
        <Table
          data={tableData()}
          pageCount={pageCount()}
          pageState={pageState()}
          onPageChange={changePage}
          columns={useTableColumns()}
          TableSkeleton={<UsersTableSkeleton />}
          noResultsMsg={'No users found'}
          tableRowClass={classes['table-row']}
        />
      </div>
    </Transition>
  );
}
