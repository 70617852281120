import { JSX } from 'solid-js';

export default function InfoIcon(props: JSX.SvgSVGAttributes<SVGSVGElement>) {
  return (
    <svg
      {...props}
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="6" cy="6" r="6" fill="#A0A5AC" />
      <line
        x1="6"
        y1="9"
        x2="6"
        y2="6"
        stroke="white"
        stroke-width="2"
        stroke-linecap="round"
      />
      <circle cx="6" cy="3" r="1" fill="white" />
    </svg>
  );
}
