export default function PathIcon() {
  return (
    <svg
      width="119"
      height="62"
      viewBox="0 0 119 62"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.00782 4.35186C24.5377 -0.522621 48.0487 2.22612 68.8466 12.1662C89.6445 22.1063 106.551 38.6743 116.909 59.2672"
        stroke="#6745B1"
        stroke-width="4"
        stroke-linecap="round"
        stroke-dasharray="15 15"
      />
    </svg>
  );
}
