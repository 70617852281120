import { Show } from 'solid-js';
import { createQuery } from '@tanstack/solid-query';
import { ExtendedAnalysisRun } from '@imagene/api-interfaces';
import { Outlet } from '@solidjs/router';
import { Skeleton, Tabs } from '@imagene/components';
import { BiomarkerQuery, useBiomarkerQuery } from './use-biomarker-query';
import { PerformanceGraph } from './PerformanceGraph';
import { axiosClient } from '../../utils/axios';
import { ValidationProvider } from './use-validation';
import { ThresholdsProvider } from './use-thresholds';
import { PerformanceExport } from './PerformanceExport';

import classes from './validation.module.css';

export function Validation() {
  const geneQuery = useBiomarkerQuery();
  const query = createQuery(
    () => ['validation'],
    async () =>
      axiosClient
        .get<ExtendedAnalysisRun[]>('/api/v1/validation')
        .then((r) => r.data)
  );

  return (
    <ThresholdsProvider>
      <ValidationProvider slides={query.data}>
        <div class={classes.container}>
          <header class={classes.header}>
            <h1>Panel performance: Lung (NSCLC)</h1>
            <PerformanceExport />
          </header>
          <Tabs
            tabs={[
              { id: 'egfr', label: 'EGFR' },
              { id: 'alk', label: 'ALK' },
            ]}
            centered
            value={geneQuery.selectedBiomarker()}
            onTabSelect={(tabId) =>
              geneQuery.setCurrentGene(tabId as BiomarkerQuery)
            }
          />
          <div class={classes.layout}>
            <Show when={query.isSuccess} fallback={<ValidationSkelton />}>
              <Outlet />
              <PerformanceGraph />
            </Show>
          </div>
        </div>
      </ValidationProvider>
    </ThresholdsProvider>
  );
}

function ValidationSkelton() {
  return (
    <div class={classes.skelton}>
      <Skeleton width={690} height={70} />
      <Skeleton width={325} height={70} />
      <div class={classes.group}>
        <header>AI Positive</header>
        <Skeleton width={770} height={30} />
        <Skeleton width={645} height={30} />
      </div>
      <div class={classes.group}>
        <header>AI Positive</header>
        <Skeleton width={770} height={30} />
        <Skeleton width={645} height={30} />
      </div>
      <div class={classes.group}>
        <header>AI Positive</header>
        <Skeleton width={770} height={30} />
        <Skeleton width={645} height={30} />
      </div>
      <div class={classes['skelton-graph']}>
        <Skeleton width={325} height={70} />
        <section>
          <Skeleton width={56} height={280} />
          <Skeleton width={56} height={220} />
          <Skeleton width={56} height={325} />
        </section>
      </div>
    </div>
  );
}
