import { JSX, ParentProps } from 'solid-js';

import classes from './icon-button.module.css';

type IconButtonProps = ParentProps<JSX.HTMLAttributes<HTMLButtonElement>> & {
  color?: 'primary' | 'secondary';
  shape?: 'rect' | 'circle';
};

export function IconButton(props: IconButtonProps) {
  const color = props.color ?? 'primary';
  const shape = props.shape ?? 'rect';
  return (
    <button
      {...props}
      class={classes.btn}
      classList={{
        [classes.primary]: color === 'primary',
        [classes.secondary]: color === 'secondary',
        [classes.circle]: shape === 'circle',
      }}
    >
      {props.children}
    </button>
  );
}
