import { Skeleton } from '@imagene/components';
import classes from './assays.module.css';

const makeRow = (numOfRows: number) => {
  const rows = [];
  for (let i = 0; i < numOfRows; i++) {
    rows.push(
      <div class={classes['skeleton-row']}>
        <div class={classes['skeleton-cell']}>
          <Skeleton width="65%" />
        </div>
        <div
          classList={{
            [classes['skeleton-cell']]: true,
            [classes['flex-start']]: true,
          }}
        >
          <Skeleton width="85%" />
        </div>
        <div
          classList={{
            [classes['skeleton-cell']]: true,
            [classes.empty]: true,
          }}
        >
          <Skeleton width={0} class={classes['skeleton-cell']} />
        </div>
        {/* <div class={classes['skeleton-cell']}>
          <Skeleton
            width={15}
            height={15}
            variant="circular"
            class={classes['skeleton-cell']}
          />
        </div> */}
      </div>
    );
  }
  return rows;
};

export function ResultsTableSkeleton() {
  return <div class={classes['skeleton-rows-wrapper']}>{makeRow(9)}</div>;
}
