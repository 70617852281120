import { ParentProps, Show } from 'solid-js';
import { isAxiosError } from 'axios';
import { createQuery } from '@tanstack/solid-query';
import { authClient, useAuthErrorHandler } from '../../utils/authClient';
import { Spinner } from '@imagene/components';
import { useAuth } from '../../utils/session';
import { useTenant } from '../../utils/use-tenant';

export function Protected(props: ParentProps) {
  const { setSession } = useAuth();
  const handleError = useAuthErrorHandler();

  const tenant = useTenant();

  const session = createQuery(
    () => ['whoami'],
    () => authClient.toSession().then((res) => res.data),
    {
      get enabled() {
        return Boolean(tenant());
      },
      retry: (_, error) => {
        // do not retry if error status code is in 400-500 range
        return (
          isAxiosError(error) && !!error.response && error.response.status > 499
        );
      },
      onSuccess: (result) => {
        setSession(result);
      },
      onError: (error) => handleError(error),
    }
  );

  const isLoading = () => session.isLoading;

  return (
    <Show when={!isLoading()} fallback={<Spinner center />}>
      {props.children}
    </Show>
  );
}
