export default function Loader(props: { ariaLabel?: string }) {
  return (
    <svg
      aria-label={`${props.ariaLabel}_ongoing`}
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="50%"
        cy="50%"
        r="12.5"
        stroke-dasharray="100"
        stroke-width="2"
        fill="transparent"
        stroke="url(#transToPurple)"
      ></circle>
      <defs>
        <linearGradient id="transToPurple" gradientTransform="rotate(90)">
          <stop offset="1%" stop-color="transparent" />
          <stop offset="99%" stop-color="#6745B1" />
        </linearGradient>
      </defs>
      <animateTransform
        from="0 0 0"
        to="360 0 0"
        attributeName="transform"
        type="rotate"
        repeatCount="indefinite"
        dur="1300ms"
      />
    </svg>
  );
}
