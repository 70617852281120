import { createSignal } from 'solid-js';
import { createMutation } from '@tanstack/solid-query';
import { Button, Modal, useToast } from '@imagene/components';
import { axiosClient } from '../../../utils/axios';

import classes from '../users.module.css';

export function Reset2FA(props: { id: string; email: string }) {
  const [isOpen, setIsOpen] = createSignal<boolean>(false);
  const { toast } = useToast();

  const user = createMutation(
    () =>
      axiosClient
        .patch(`/api/v1/admin/users/${props.id}/reset-2fa`)
        .then((res) => res.data),
    {
      onSuccess: () => {
        toast({
          title: '2FA was reset!',
          description: `Two-factor authentication was reset successfully for account ${props.email}`,
          dismissAfter: 5000,
        });
      },
      onError: () => {
        toast({
          type: 'error',
          title: 'Failed to reset 2FA',
          description: `Two-factor authentication was not reset for account  ${props.email} due to technical issues. Please try again later.`,
          dismissAfter: 5000,
        });
      },
    }
  );

  async function activateAccount() {
    user.mutate();
  }

  return (
    <>
      <Button
        variant="secondary"
        class={classes['action-btn']}
        onClick={() => setIsOpen(true)}
      >
        Reset 2FA
      </Button>
      <Modal
        open={isOpen()}
        onClose={() => setIsOpen(false)}
        onAction={activateAccount}
        closeBtnText="Keep 2FA settings"
        actionBtnText="Reset 2FA"
        title="Reset 2FA?"
        text={`Reset 2FA for account ${props.email}?
               The next time the user logs in, they will need to set up their 2FA just like a new user.`}
      />
    </>
  );
}
