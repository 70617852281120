export default function AboutIcon() {
  return (
    <svg
      width="7"
      height="21"
      viewBox="0 0 7 21"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.62611 18.2956C6.62611 18.2956 4.47081 19.2104 4.66683 18.0309L6.14332 9.12716C6.32754 8.01721 5.52532 7 4.46718 7H0.362581L0.239162 7.55449C0.239162 7.55449 1.78372 7.64336 1.54777 9.0634C1.01235 12.2966 0.0340683 18.1932 0.0331608 18.199C-0.0984259 18.9902 0.16747 19.8122 0.726486 20.3522C1.56229 21.1598 2.71118 21.1067 3.72394 20.7628C4.06153 20.6478 7.00453 19.2461 6.99999 19.2346L6.62611 18.2946V18.2956Z"
        fill="currentcolor"
      />
      <circle cx="3.49996" cy="2.33333" r="2.33333" fill="currentcolor" />
    </svg>
  );
}
