import { createSignal, For, JSX, Show } from 'solid-js';
import { createStore } from 'solid-js/store';
import type {
  ExtendedPanelResponse,
  PanelsResponse,
} from '@imagene/api-interfaces';
import { sortResultByOrder } from '@imagene/biomarker-results';
import { Checkbox, Typography } from '@imagene/components';
import { PageCardNavigation } from '../PageCardNavigation';
import classes from './panel-selection.module.css';
import { useTenant } from '../../utils/use-tenant';
import { PanelSelect, PanelSelectEvent } from '../PanelSelect';

export type Panel = PanelsResponse;
export type Biomarker = Panel['biomarkers'][number] & {
  checked?: boolean;
};

export interface PanelSelectionStore {
  biomarkers: Biomarker[];
  panel: Panel;
}

export interface PanelSelectionEvent extends PanelSelectionStore {
  completePanel: boolean;
}

export interface PanelSelectionProps {
  title: string;
  subheader?: JSX.Element;
  nextLabel?: string;
  onNext: (e: PanelSelectionEvent) => void;
  valid?: boolean;
  panel?: Panel;
}

export function PanelSelection(props: PanelSelectionProps) {
  const [panels, setPanels] = createSignal<Panel[]>([]);
  const [store, setStore] = createStore<PanelSelectionStore>({
    panel: props?.panel ?? (null as unknown as Panel),
    biomarkers: props?.panel?.biomarkers ?? [],
  });
  const tenant = useTenant();

  const disableAllBiomarkers = () => !tenant()?.biomarker_selection;
  const isCompletePanel = () => store.biomarkers.every((bio) => bio.checked);
  const isValid = () => props.valid ?? true;
  const isAnySelected = () =>
    store.biomarkers.some((bio) => bio.checked) && isValid();

  const onPanelFetchSuccess = (panels: ExtendedPanelResponse) => {
    for (const panel of panels) {
      panel.biomarkers.sort(sortResultByOrder);
    }
    setPanels(panels);

    const isPanelSelected = Boolean(store.panel.id);
    const selectedBiomarkers = store.biomarkers.map((b) => b.id);
    const currentPanel =
      panels.find((p) => p.id === store.panel.id) ?? panels[0];

    setStore({
      panel: currentPanel,
      biomarkers: currentPanel.biomarkers.map((b) => ({
        ...b,
        checked: isPanelSelected ? selectedBiomarkers.includes(b.id) : true,
      })),
    });
  };

  function onSubmit() {
    if (!store.panel || !isAnySelected()) return;

    const selectedBiomarkers = store.biomarkers.filter((bio) => bio.checked);

    props.onNext({
      panel: { ...store.panel, biomarkers: selectedBiomarkers },
      biomarkers: selectedBiomarkers,
      completePanel: isCompletePanel(),
    });
  }

  const handleCheckboxClick = (checked: boolean, value: string) => {
    const index = store.biomarkers.findIndex((bio) => bio.id === value);

    if (!checked && store.biomarkers.length > 1 && isCompletePanel()) {
      setStore('biomarkers', (prev) =>
        prev.map((bio, idx) => ({ ...bio, checked: idx === index }))
      );
      return;
    }

    setStore('biomarkers', index, 'checked', checked);
  };

  const handleAllClick = (checked: boolean) => {
    setStore('biomarkers', (bio) => bio.map((bio) => ({ ...bio, checked })));
  };

  const handlePanelSelect = ({ id }: PanelSelectEvent) => {
    const currentPanel = panels().find((p) => p.id === id);
    setStore({
      panel: currentPanel,
      biomarkers: currentPanel?.biomarkers.map((bio) => ({
        ...bio,
        checked: true,
      })),
    });
  };

  return (
    <PageCardNavigation
      title={props.title}
      description="Choose panel and biomarkers"
      subheader={props.subheader}
      onNext={onSubmit}
      nextLabel={props.nextLabel ?? 'Submit'}
      valid={isAnySelected()}
    >
      <div class={classes['panel-biomarkers']}>
        <section class={classes['panel-section']}>
          <Typography component="h3" weight="bold">
            Choose a panel
          </Typography>
          <PanelSelect
            id="panel"
            onSelect={handlePanelSelect}
            onSuccess={onPanelFetchSuccess}
            value={store.panel?.id}
          />
        </section>
        <section class={classes['biomarkers-section']}>
          <Show when={!disableAllBiomarkers()}>
            <Checkbox
              id="all_biomarkers_checkbox"
              checked={isCompletePanel()}
              onClick={(e) => handleAllClick(e.currentTarget.checked)}
              label="Complete panel"
              disabled={disableAllBiomarkers()}
              containerStyle={{
                position: 'absolute',
                top: '30px',
              }}
            />
          </Show>
          <Typography component="p" weight="bold">
            {disableAllBiomarkers()
              ? 'Included biomarkers'
              : 'or choose individual biomarkers of interest:'}
          </Typography>
          <div class={classes.biomarkers}>
            <For each={store.biomarkers}>
              {(bio) => (
                <Checkbox
                  id={bio.id}
                  onClick={(e) =>
                    handleCheckboxClick(
                      e.currentTarget.checked,
                      e.currentTarget.value
                    )
                  }
                  checked={bio.checked}
                  disabled={disableAllBiomarkers()}
                  label={bio.label}
                  value={bio.id}
                />
              )}
            </For>
          </div>
        </section>
      </div>
    </PageCardNavigation>
  );
}
