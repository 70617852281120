import { UiNode } from '@ory/client';
import { createStore } from 'solid-js/store';
import { getUiNodeAttributes } from '../../utils/auth';

export interface TwoFAData {
  csrf_token: string;
  totp_code: string;
}
export const AMOUNT_OF_DIGITS = 6;

export function use2FA(nodes: UiNode[]) {
  const csrf = getUiNodeAttributes(nodes, 'csrf_token');
  const totp = getUiNodeAttributes(nodes, 'totp_code');

  const [formValues, setFormValues] = createStore<TwoFAData>({
    csrf_token: csrf?.value ?? '',
    totp_code: totp?.value ?? '',
  });

  const isValid = () =>
    formValues.totp_code !== '' &&
    formValues.totp_code.length === AMOUNT_OF_DIGITS;

  function onDigitsComplete(digits: string) {
    setFormValues('totp_code', digits);
  }

  return { onDigitsComplete, data: formValues, isValid };
}
