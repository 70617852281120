import { Progress, Typography } from '@imagene/components';
import classes from '../analysis.module.css';

export function UploadProgressBar(props: { completed: number; total: number }) {
  return (
    <div class={classes['total-progress']}>
      <Typography component="h2">
        {props.completed} / {props.total}
      </Typography>
      <Progress value={props.completed} max={props.total} />
    </div>
  );
}
