import { ParentProps } from 'solid-js';
import CheckIcon from '@imagene/components/icons/CheckIcon';
import FilledErrorIcon from '@imagene/components/icons/FilledErrorIcon';

import classes from './reset-password.module.css';

export type ValidationError = {
  validation:
    | 'match'
    | 'min'
    | 'max'
    | 'digits'
    | 'uppercase'
    | 'lowercase'
    | 'symbols';
  arguments?: unknown;
};

interface PasswordErrorsProps {
  errors: ValidationError[];
  class?: string;
}

export function PasswordErrors(props: ParentProps<PasswordErrorsProps>) {
  const validate = (...validation: ValidationError['validation'][]) =>
    !props.errors.filter((e) => validation.includes(e.validation)).length;

  return (
    <ul
      classList={{
        [classes.requirements]: true,
        [props.class ?? '']: !!props.class,
      }}
    >
      <ErrorItem valid={validate('min', 'max')}>8 characters minimum</ErrorItem>
      <ErrorItem valid={validate('digits')}>One number</ErrorItem>
      <ErrorItem valid={validate('uppercase')}>One uppercase letter</ErrorItem>
      <ErrorItem valid={validate('lowercase')}>One lowercase letter</ErrorItem>
      <ErrorItem valid={validate('symbols')}>One special character</ErrorItem>
      <ErrorItem valid={validate('match')}>Passwords matching</ErrorItem>
    </ul>
  );
}

function ErrorItem(props: ParentProps<{ valid: boolean }>) {
  return (
    <li classList={{ [classes.valid]: props.valid }}>
      {props.valid ? <CheckIcon /> : <FilledErrorIcon width="15" height="15" />}
      {props.children}
    </li>
  );
}
