export default function NegativeOutlinedIcon(props: { ariaLabel?: string }) {
  return (
    <svg
      aria-label={props.ariaLabel}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="10"
        cy="10"
        r="9"
        fill="white"
        stroke="#C73434"
        stroke-width="2"
      />
      <line
        x1="1"
        y1="-1"
        x2="11"
        y2="-1"
        transform="matrix(0.707107 0.707107 -0.695997 0.718044 5.14868 6.24231)"
        stroke="#C73434"
        stroke-width="2"
        stroke-linecap="round"
      />
      <line
        x1="1"
        y1="-1"
        x2="11"
        y2="-1"
        transform="matrix(0.707107 -0.707107 0.695997 0.718044 6.26733 15.0125)"
        stroke="#C73434"
        stroke-width="2"
        stroke-linecap="round"
      />
    </svg>
  );
}
