import { ButtonGroup } from '@imagene/components';
import { useMatch, useNavigate, useSearchParams } from '@solidjs/router';
import { useValidation } from './use-validation';

export function BatchViewSwitch() {
  const [query] = useSearchParams();
  const validation = useValidation();
  const navigate = useNavigate();
  const isMolecularResults = useMatch(() => '/validation/results');

  return (
    <ButtonGroup
      items={[
        { value: 'ai', label: 'View AI Results' },
        { value: 'mol', label: 'Add Molecular Results' },
      ]}
      disabled={validation.isEmpty()}
      value={isMolecularResults() ? 'mol' : 'ai'}
      onValueChange={({ value }) =>
        navigate(
          `/validation/${value === 'ai' ? '' : 'results'}?${new URLSearchParams(
            query
          )}`
        )
      }
    />
  );
}
