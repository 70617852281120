import { Show } from 'solid-js';
import { Typography } from '@imagene/components';
import { Accuracy } from '../Accuracy';
import { AccuracyType } from '@imagene/biomarker-results';

import classes from './slide-statistics-item.module.css';

export interface SlideStatisticsItemProps {
  color: 'default' | 'primary' | 'secondary';
  accuracy: AccuracyType | null;
  value: number;
  displayValue: string;
  name: string;
  excluded: boolean;
}

export function SlideStatisticsItem(props: SlideStatisticsItemProps) {
  return (
    <li
      class={classes.item}
      classList={{
        [classes['item-primary']]: props.color === 'primary',
        [classes['item-secondary']]: props.color === 'secondary',
        [classes.excluded]: props.excluded,
      }}
    >
      <Typography wrap="25ch" title={props.name}>
        {props.name}
      </Typography>
      <div class={classes['value-range']}>
        <span
          class={classes['item-value']}
          style={{ '--bd-value': `${props.value}%` }}
        >
          <Typography>{props.displayValue}</Typography>
          <Show when={props.accuracy}>
            {(acc) => <Accuracy value={acc()} />}
          </Show>
        </span>
      </div>
    </li>
  );
}
