import { PageCardNavigation } from '../../../components/PageCardNavigation';
import classes from '../analysis.module.css';
import { Progress, SimpleTable, Typography } from '@imagene/components';
import { createColumnHelper } from '@tanstack/solid-table';
import { useBatchUpload } from './use-batch-upload';
import { UploadProgressBar } from './UploadProgressBar';
import ErrorIcon from '@imagene/components/icons/ErrorIcon';
import { Show, onCleanup } from 'solid-js';
import { RedXIcon } from '@imagene/components/icons/RedXIcon';
import { TickIcon } from '@imagene/components/icons/TickIcon';
import { useUpload } from '../../../utils/use-upload';
import { WsiState } from '@prisma/client';
import { useAnalysisCreation } from '../use-create-analysis';
import { UploadBatchDetails } from '../types';

export function UploadBatchTable(props: { onSubmitError: VoidFunction }) {
  const [details] = useAnalysisCreation();
  const { isUploading, setIsUploading } = useUpload();

  const store = useBatchUpload({
    images: details.images.map((i) => ({ ...i, state: 'INIT' })),
    dataset_id: details.dataset_id,
    onSubmitError: props.onSubmitError,
  });

  const columnHelper = createColumnHelper<{
    name: string;
    wsi_id: string;
    state: WsiState;
    percentage?: number;
  }>();

  onCleanup(() => setIsUploading(false));

  const completed = () =>
    store.images.filter((i) => i.state !== 'INIT' && i.state !== 'IN_PROGRESS')
      .length;

  return (
    <PageCardNavigation
      title="Add slides"
      description="Uploading"
      subheader={<UploadSubheader {...details} />}
      subfooter={
        <UploadProgressBar
          total={store.images.length}
          completed={completed()}
        />
      }
      headerElement={<StayMessage show={isUploading()} />}
    >
      <SimpleTable
        class={classes['files-table']}
        columns={[
          columnHelper.display({
            id: 'id',
            size: 150,
            cell: (props) => <RowIdentifier index={props.row.index} />,
          }),
          columnHelper.display({
            id: 'state',
            header: 'Name',
            cell: (props) => {
              return (
                <div class={classes['file-progress']}>
                  <Typography wrap="95%" title={props.row.original.name}>
                    {props.row.original.name}
                  </Typography>
                  <UploadStatus
                    state={props.row.original.state}
                    percentage={props.row.original.percentage}
                  />
                </div>
              );
            },
          }),
        ]}
        data={store.images}
      />
    </PageCardNavigation>
  );
}

export function UploadSubheader(details: UploadBatchDetails) {
  return (
    <div class={classes.subheader}>
      <div class={classes['subheader-item']}>
        <Typography weight="semi-bold">Selected files</Typography>
        <Typography>{details.files.length}</Typography>
      </div>
      <div class={classes['subheader-item']}>
        <Typography weight="semi-bold">Panel</Typography>
        <Typography>{details.panel.label}</Typography>
      </div>
      <div class={classes['subheader-item']}>
        <Typography weight="semi-bold">Biomarkers</Typography>
        <Typography>
          {details.completePanel
            ? 'Complete panel'
            : details.panel?.biomarkers?.map((b) => b.label).join(', ')}
        </Typography>
      </div>
      <div class={classes['subheader-item']}>
        <Typography weight="semi-bold">Batch name</Typography>
        <Typography>{details.datasetName}</Typography>
      </div>
    </div>
  );
}

function RowIdentifier(props: { index: number }) {
  return (
    <div class={classes.identifier}>
      <Typography>{props.index + 1}</Typography>
    </div>
  );
}

function UploadStatus(props: { state: WsiState; percentage?: number }) {
  const state = () => {
    switch (props.state) {
      case 'INIT':
        return 'Queued';
      case 'IN_PROGRESS':
        return 'Uploading';
      case 'DONE':
        return 'Uploaded';
      case 'ERROR':
        return 'Upload Error';
      case 'TIMEOUT':
        return 'Upload Timeout';
    }
  };

  const icon = () => {
    switch (props.state) {
      case 'ERROR':
      case 'TIMEOUT':
        return <RedXIcon />;
      case 'DONE':
        return <TickIcon />;
    }
  };

  const showBar = () => state() === 'Uploading';

  const percentage = () => props.percentage ?? 0;

  return (
    <div class={classes['state-cell']}>
      <div class={classes['upload-icon']}>{icon()}</div>
      {state()}
      <Show when={showBar()}>
        <Progress value={percentage()} max={100} />
      </Show>
    </div>
  );
}

function StayMessage(props: { show: boolean }) {
  return (
    <Show when={props.show}>
      <div class={classes['stay-message']}>
        <ErrorIcon />
        <div class={classes['stay-message-text']}>
          <Typography component="div">
            Please do not close the current browser tab. Closing the tab will
            interrupt the uploading process.
          </Typography>
        </div>
      </div>
    </Show>
  );
}
