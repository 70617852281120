import { Spinner, Typography } from '@imagene/components';
import { useNavigate } from '@solidjs/router';
import { Show } from 'solid-js';
import { authClient } from '../../utils/authClient';
import { useFlow } from '../../utils/use-flow';
import { CodeForm } from './CodeForm';
import { RegistrationFormData } from './useCode';
import { RecoveryFlow } from '@ory/client';
import classes from './code.module.css';
import { isAxiosError } from 'axios';
import { datadogLogs } from '@datadog/browser-logs';

export function Code() {
  const navigate = useNavigate();

  const flow = useFlow({
    pageName: 'register',
    getter: (flowId) =>
      flowId
        ? authClient.getRecoveryFlow({ id: flowId }).then((res) => res.data)
        : authClient.createBrowserRecoveryFlow().then((res) => res.data),
    submitter: (flowId, data: RegistrationFormData) =>
      authClient
        .updateRecoveryFlow({
          flow: flowId,
          updateRecoveryFlowBody: { method: 'code', ...data },
        })
        .then((res) => res.data),
    onSuccess: (data: RecoveryFlow) => {
      const hasErrors = data.ui.messages?.some((m) => m.type === 'error');
      if (!hasErrors) {
        navigate('/reset-password');
      }
      flow.setFlow(data);
    },
    onError: (error, flowId) => {
      if (isAxiosError(error)) {
        if (error.response?.status === 410) {
          datadogLogs.logger.info(`recovery flow is expired`, { flowId });
          navigate('/expired', { replace: true });
          return true;
        }
      }
      return false;
    },
  });

  return (
    <Show when={flow.data()} fallback={<Spinner center />}>
      {(f) => (
        <div class={classes.container}>
          <Typography component="h1" weight="thin">
            Welcome to the ImageneOI
          </Typography>
          <Typography component="h2" weight="thin">
            Verification code:
          </Typography>
          <CodeForm flow={f()} onSubmit={flow.submit} />
        </div>
      )}
    </Show>
  );
}
