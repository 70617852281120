import { UiNode } from '@ory/client';
import { createStore } from 'solid-js/store';
import { getUiNodeAttributes } from '../../utils/auth';

export interface RegistrationFormData {
  code: string;
}

export const AMOUNT_OF_DIGITS = 6;

export function useCode(nodes: UiNode[]) {
  const code = getUiNodeAttributes(nodes, 'code');

  const [formValues, setFormValues] = createStore<RegistrationFormData>({
    code: code?.value ?? '',
  });

  const isValid = () =>
    formValues.code !== '' && formValues.code.length === AMOUNT_OF_DIGITS;

  function onDigitsComplete(digits: string) {
    setFormValues('code', digits);
  }

  return { onDigitsComplete, data: formValues, isValid };
}
