import { useQuota } from '../../utils/use-quota-limit';

export function useShowLimit() {
  const quota = useQuota();
  const show = () => Boolean(quota.data?.runs_limit_amount);
  const values = () => ({
    total: quota.data?.runs_limit_amount as number,
    cur: quota.data?.current_runs_amount as number,
  });
  return {
    show,
    values,
  };
}
