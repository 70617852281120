export function ImageneOILogo() {
  return (
    <svg
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 218.05 42.9"
    >
      <g>
        <path
          style={{ fill: '#fff' }}
          d="m89.05,10.93c.42,1.21,1.09,2.4,1.22,3.63.27,2.71-.89,5.34-3.12,6.92-1.04.74-2.28,1.26-3.53,1.46-.8.13-1.6.18-2.41.16-1.13-.03-2.94-.25-3.54,1-.66,1.39.82,2.15,1.91,2.47,1.47.44,2.92.99,4.4,1.4,1.47.41,2.99.71,4.41,1.27,3.85,1.51,5.03,5.33,2.79,8.86-1.39,2.19-3.5,3.45-5.92,4.12-3.2.88-6.43.88-9.65.03-3.09-.82-5.32-3.11-5.62-5.71-.26-2.28.32-4.16,1.73-5.66.95-1.02,2.67-.03,2.25,1.3,0,.01,0,.02-.01.03-1.1,3.47.4,6.18,3.92,7.18,2.29.65,4.6.62,6.85-.14.81-.28,1.63-.72,2.29-1.28,1.8-1.54,1.7-3.74-.38-4.82-2.04-1.06-4.32-1.65-6.43-2.58-1.71-.76-3.42-1.61-4.98-2.65-1.21-.8-1.25-2.11-.56-3.36,1.38-2.5,3.54-3.85,6.34-4.26.64-.1,1.3-.12,1.92-.28,3.29-.87,4.82-4.22,3.29-7.38-.09-.18-.18-.37-.24-.56-.7-2.18-.04-3.52,1.98-4.27.97-.35,1.92-.75,2.92-1.16.92-.38,1.9.1,2.05,1.09.18,1.17-.99,2.04-3.89,3.17Z"
        />
        <path
          style={{ fill: '#fff' }}
          d="m83.78,7.34c-5.96,2.28-10.29,5.67-10.74,12.36-1.85-2.15-2-5.97-.36-8.59,2.12-3.4,6.46-4.94,11.1-3.77Z"
        />
      </g>
      <path
        style={{ fill: '#fff' }}
        d="m3.09.63C2.73.25,2.31.07,1.82.07s-.93.19-1.29.56-.52.8-.52,1.32v26.49c0,.52.17.96.52,1.32.36.37.79.56,1.29.56s.92-.19,1.28-.56c.35-.36.52-.8.52-1.32V1.94c0-.52-.17-.96-.52-1.32Z"
      />
      <path
        style={{ fill: '#fff' }}
        d="m40.18,11.42c-1.9-2.17-4.19-3.26-6.87-3.26-3.31,0-5.94,1.54-7.87,4.61-1.95-3.08-4.58-4.61-7.89-4.61-2.25,0-4.27.81-6.05,2.42v-.57c0-.51-.18-.94-.53-1.29-.37-.37-.8-.55-1.3-.55s-.95.18-1.31.55c-.35.35-.53.78-.53,1.29v18.48c0,.51.18.94.53,1.29.37.35.81.53,1.31.53s.93-.18,1.3-.53c.35-.35.53-.78.53-1.29v-9.23c0-2.04.59-3.79,1.77-5.24,1.19-1.44,2.62-2.16,4.28-2.16s3.09.72,4.28,2.16c1.18,1.45,1.77,3.19,1.77,5.22v9.25c0,.5.18.93.53,1.29.37.35.81.53,1.32.53s.93-.18,1.3-.53c.35-.36.53-.8.53-1.29v-9.25c0-2.03.59-3.77,1.77-5.22,1.19-1.44,2.62-2.16,4.28-2.16s3.09.72,4.28,2.16c1.18,1.45,1.77,3.2,1.77,5.24v9.23c0,.5.18.93.53,1.29.37.35.81.53,1.31.53s.93-.18,1.3-.53c.35-.36.53-.8.53-1.29v-9.23c0-3.06-.95-5.67-2.85-7.83Z"
      />
      <path
        style={{ fill: '#fff' }}
        d="m67.23,8.71c-.35-.37-.78-.55-1.3-.55s-.94.18-1.3.55c-.37.35-.55.78-.55,1.29v.94c-2.05-1.86-4.46-2.79-7.21-2.79-3.01,0-5.58,1.09-7.7,3.26-2.13,2.16-3.2,4.77-3.2,7.83s1.07,5.65,3.2,7.81c2.12,2.16,4.68,3.24,7.7,3.24,2.75,0,5.15-.93,7.21-2.79v.96c0,.51.18.94.55,1.29.35.35.79.53,1.3.53s.94-.18,1.3-.53c.35-.35.53-.78.53-1.29V10c0-.51-.18-.94-.53-1.29Zm-5.24,15.76c-1.41,1.44-3.11,2.16-5.1,2.16s-3.69-.72-5.1-2.16c-1.41-1.45-2.12-3.19-2.12-5.22s.71-3.79,2.12-5.24c1.41-1.44,3.11-2.16,5.1-2.16s3.69.72,5.1,2.16c1.4,1.45,2.1,3.2,2.1,5.24s-.7,3.77-2.1,5.22Z"
      />
      <path
        style={{ fill: '#fff' }}
        d="m114.23,20.57c.35-.35.53-.78.53-1.3,0-1.5-.3-2.94-.88-4.32-.56-1.34-1.36-2.51-2.38-3.53-2.18-2.17-4.81-3.26-7.87-3.26-1.52,0-2.97.29-4.34.88-1.33.56-2.52,1.36-3.55,2.38-2.17,2.17-3.26,4.79-3.26,7.85,0,1.49.3,2.93.88,4.3.56,1.31,1.36,2.48,2.38,3.5,2.18,2.16,4.82,3.24,7.89,3.24,3.82,0,6.83-1.24,9.03-3.73.34-.38.5-.82.47-1.32-.03-.51-.23-.94-.61-1.28-.38-.33-.82-.48-1.34-.45-.51.03-.93.23-1.25.61-1.48,1.66-3.58,2.49-6.3,2.49-1.73,0-3.27-.52-4.61-1.57-1.34-1.03-2.21-2.35-2.63-3.95h16.53c.51,0,.95-.18,1.32-.55Zm-17.85-3.14c.42-1.62,1.29-2.96,2.61-4.01,1.36-1.06,2.91-1.59,4.63-1.59s3.25.53,4.61,1.59c1.32,1.05,2.19,2.38,2.61,4.01h-14.47Z"
      />
      <path
        style={{ fill: '#fff' }}
        d="m134.83,11.41c-1.98-2.17-4.37-3.25-7.17-3.25-2.45,0-4.62.85-6.5,2.57v-.72c0-.51-.18-.94-.53-1.29-.37-.37-.8-.55-1.29-.55s-.95.18-1.31.55-.53.78-.53,1.29v18.42c0,.51.18.94.53,1.29.37.35.8.53,1.31.53s.92-.18,1.29-.53c.35-.35.53-.78.53-1.29v-9.2c0-2.04.63-3.78,1.9-5.23,1.27-1.44,2.8-2.15,4.6-2.15s3.3.72,4.58,2.15c1.26,1.45,1.9,3.19,1.9,5.23v9.26c0,.49.18.93.53,1.29.35.35.78.53,1.29.53s.94-.18,1.29-.53c.37-.36.55-.8.55-1.29v-9.26c0-3.05-.99-5.66-2.98-7.81Z"
      />
      <path
        style={{ fill: '#fff' }}
        d="m162.57,20.57c.35-.35.53-.78.53-1.3,0-1.5-.29-2.94-.88-4.32-.56-1.34-1.36-2.51-2.38-3.53-2.18-2.17-4.81-3.26-7.87-3.26-1.52,0-2.96.29-4.34.88-1.34.56-2.52,1.36-3.55,2.38-2.17,2.17-3.26,4.79-3.26,7.85,0,1.49.3,2.93.89,4.3.56,1.31,1.35,2.48,2.37,3.5,2.18,2.16,4.82,3.24,7.89,3.24,3.82,0,6.83-1.24,9.03-3.73.34-.38.5-.82.47-1.32-.03-.51-.23-.94-.61-1.28-.38-.33-.82-.48-1.33-.45-.51.03-.93.23-1.26.61-1.48,1.66-3.58,2.49-6.3,2.49-1.73,0-3.27-.52-4.61-1.57-1.34-1.03-2.21-2.35-2.63-3.95h16.53c.51,0,.95-.18,1.31-.55Zm-17.85-3.14c.42-1.62,1.29-2.96,2.61-4.01,1.36-1.06,2.9-1.59,4.63-1.59s3.25.53,4.61,1.59c1.32,1.05,2.19,2.38,2.61,4.01h-14.47Z"
      />
      <g>
        <path
          style={{ fill: '#fff' }}
          d="m209.52,6.09h-.43V.39h-1.97v-.39h4.38v.39h-1.97v5.7Z"
        />
        <path
          style={{ fill: '#fff' }}
          d="m215.04,6.09l-2.24-5.62h-.02c0,.1.01.21.01.32s0,.23.01.35c0,.12,0,.25,0,.38v4.57h-.41V0h.65l2.16,5.42h.02l2.17-5.42h.63v6.09h-.42V1.47c0-.11,0-.22,0-.33,0-.11,0-.23.01-.34s0-.22.01-.33h-.02l-2.25,5.62h-.34Z"
        />
      </g>
      <path
        style={{ fill: '#578382' }}
        d="m196.18,15.23c0,4.28-1.42,7.87-4.26,10.75-2.84,2.89-6.4,4.33-10.68,4.33s-8.09-1.44-10.91-4.31c-2.82-2.87-4.23-6.54-4.23-11s1.5-7.93,4.5-10.76c2.96-2.78,6.63-4.17,10.99-4.17s7.81,1.46,10.55,4.38c2.68,2.86,4.03,6.46,4.03,10.78Zm-4.82.85c0-3.21-.93-6.02-2.78-8.45-2.03-2.66-4.61-4-7.73-4s-5.39,1.02-7.25,3.06c-1.8,1.97-2.7,4.45-2.7,7.43,0,3.33.93,6.21,2.8,8.64,2.03,2.65,4.68,3.98,7.94,3.98,2.91,0,5.29-1.07,7.13-3.21,1.73-2.03,2.59-4.51,2.59-7.45Z"
      />
      <path
        style={{ fill: '#578382' }}
        d="m204.07,30.31h-6.51c.67-2.67,1-5.27,1-7.79v-13.56c0-3.4-.33-6.39-1-8.95h6.51c-.68,2.57-1.02,5.55-1.02,8.95v13.35c0,2.67.34,5.34,1.02,8.01Z"
      />
    </svg>
  );
}
