import { createEffect, createSignal, JSX, ParentProps, Show } from 'solid-js';
import { CopyIcon } from '../../icons/CopyIcon';
import { Typography } from '../../Typography';

import classes from './copy-button.module.css';

type CopyButtonProps = ParentProps<JSX.HTMLAttributes<HTMLButtonElement>> & {
  onClick: () => void;
  copiedText?: string;
  disabled?: boolean;
};

export function CopyButton(props: CopyButtonProps) {
  const [copied, setCopied] = createSignal(false);

  createEffect(() => {
    if (copied()) {
      setTimeout(() => setCopied(false), 3000);
    }
  });

  function onClick() {
    setCopied(!copied());
    props.onClick && props.onClick();
  }

  return (
    <button
      {...props}
      class={classes.btn}
      classList={{ [classes.copied]: copied() }}
      onClick={onClick}
      disabled={props.disabled}
    >
      <CopyIcon />
      <Show
        when={!copied()}
        fallback={
          <Typography weight="semi-bold" class={classes.text}>
            {props.copiedText ?? 'Copied'}
          </Typography>
        }
      >
        <Typography weight="semi-bold" class={classes.text}>
          {props.children}
        </Typography>
      </Show>
    </button>
  );
}
