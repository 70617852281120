export default function ProfileIcon() {
  return (
    <svg
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.25263 28.7526C8.14737 27.5211 10.0263 26.5816 11.8895 25.9342C13.7526 25.2868 15.7895 24.9632 18 24.9632C20.2105 24.9632 22.2553 25.2868 24.1342 25.9342C26.0132 26.5816 27.9 27.5211 29.7947 28.7526C31.1526 27.1421 32.1711 25.4526 32.85 23.6842C33.5289 21.9158 33.8684 20.0211 33.8684 18C33.8684 13.5158 32.3447 9.75 29.2974 6.70263C26.25 3.65526 22.4842 2.13158 18 2.13158C13.5158 2.13158 9.75 3.65526 6.70263 6.70263C3.65526 9.75 2.13158 13.5158 2.13158 18C2.13158 20.0211 2.47105 21.9158 3.15 23.6842C3.82895 25.4526 4.86316 27.1421 6.25263 28.7526ZM18 19.0895C16.2947 19.0895 14.8579 18.5053 13.6895 17.3368C12.5211 16.1684 11.9368 14.7316 11.9368 13.0263C11.9368 11.3211 12.5211 9.88421 13.6895 8.71579C14.8579 7.54737 16.2947 6.96316 18 6.96316C19.7053 6.96316 21.1421 7.54737 22.3105 8.71579C23.4789 9.88421 24.0632 11.3211 24.0632 13.0263C24.0632 14.7316 23.4789 16.1684 22.3105 17.3368C21.1421 18.5053 19.7053 19.0895 18 19.0895ZM18 36C15.5053 36 13.1605 35.5263 10.9658 34.5789C8.77105 33.6316 6.86053 32.3447 5.23421 30.7184C3.60789 29.0921 2.32895 27.1895 1.39737 25.0105C0.465789 22.8316 0 20.4789 0 17.9526C0 15.4895 0.473684 13.1605 1.42105 10.9658C2.36842 8.77105 3.65526 6.86842 5.28158 5.25789C6.90789 3.64737 8.81053 2.36842 10.9895 1.42105C13.1684 0.473684 15.5211 0 18.0474 0C20.5105 0 22.8395 0.473684 25.0342 1.42105C27.2289 2.36842 29.1316 3.64737 30.7421 5.25789C32.3526 6.86842 33.6316 8.77105 34.5789 10.9658C35.5263 13.1605 36 15.5053 36 18C36 20.4947 35.5263 22.8316 34.5789 25.0105C33.6316 27.1895 32.3526 29.0921 30.7421 30.7184C29.1316 32.3447 27.2289 33.6316 25.0342 34.5789C22.8395 35.5263 20.4947 36 18 36Z"
        fill="currentcolor"
      />
    </svg>
  );
}
