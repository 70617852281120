import { For, JSX, createMemo, createUniqueId, splitProps } from 'solid-js';
import * as accordion from '@zag-js/accordion';
import { normalizeProps, useMachine } from '@zag-js/solid';
import DownArrowIcon from '../icons/DownArrowIcon';

import classes from './accordion.module.css';

export interface AccordionItem {
  id: string;
  title: string;
  content: JSX.Element;
  color?: 'default' | 'primary' | 'secondary';
}

export type AccordionProps = Pick<
  accordion.Context,
  'value' | 'multiple' | 'disabled' | 'collapsible' | 'onValueChange'
> & { items: AccordionItem[]; sticky?: boolean };

export function Accordion(props: AccordionProps) {
  const [context, local] = splitProps(props, [
    'disabled',
    'collapsible',
    'multiple',
    'value',
    'onValueChange',
  ]);
  const [state, send] = useMachine(
    accordion.machine({
      id: createUniqueId(),
    }),
    { context }
  );

  const api = createMemo(() => accordion.connect(state, send, normalizeProps));

  return (
    <div
      {...api().getRootProps()}
      class={classes.accordion}
      classList={{
        [classes.sticky]: local.sticky,
      }}
    >
      <For each={local.items}>
        {(item) => (
          <div
            {...api().getItemProps({ value: item.id })}
            classList={{
              [classes.primary]: item.color === 'primary',
              [classes.secondary]: item.color === 'secondary',
            }}
          >
            <button {...api().getItemTriggerProps({ value: item.id })}>
              <h3>{item.title}</h3>
              <span {...api().getItemIndicatorProps({ value: item.id })}>
                <DownArrowIcon />
              </span>
            </button>
            <div {...api().getItemContentProps({ value: item.id })}>
              {item.content}
            </div>
          </div>
        )}
      </For>
    </div>
  );
}
