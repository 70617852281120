import { Suspense } from 'solid-js';
import { Duration } from 'luxon';
import { StatsTimeResponse } from '@imagene/api-interfaces';
import { createQuery } from '@tanstack/solid-query';
import { axiosClient } from '../../utils/axios';
import { Skeleton } from '@imagene/components';
import ClockIcon from '@imagene/components/icons/ClockIcon';

import classes from './home.module.css';

export function AvgTime() {
  const query = createQuery(
    () => ['avg-time'],
    async () =>
      axiosClient.get<StatsTimeResponse>('/api/v1/tat').then((res) => res.data)
  );

  const average = () =>
    Duration.fromObject({ seconds: query.data?.TAT }).toFormat(
      "m 'min' s 'sec'"
    );
  return (
    <div class={classes['avg-time-wrapper']}>
      <div class={classes['avg-time-icon']}>
        <ClockIcon />
      </div>
      <div class={classes['avg-time-text']}>
        <div>Average TAT of biomarker results</div>
        <div class={classes['avg-time-text-large']}>
          <Suspense fallback={<AvgTimeSkeleton />}>{average()}</Suspense>
        </div>
      </div>
    </div>
  );
}

function AvgTimeSkeleton() {
  return (
    <Skeleton
      variant="rectangle"
      width={100}
      class={classes['avg-time-skeleton']}
    />
  );
}
