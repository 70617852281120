import { createSignal } from 'solid-js';
import { useParams } from '@solidjs/router';
import { ExtendedDetailedAnalysisRun } from '@imagene/api-interfaces';
import { createQuery } from '@tanstack/solid-query';
import { Typography } from '@imagene/components';
import { Transition } from 'solid-transition-group';
import { AssayBiomarkerTable } from './BiomarkerTable';
import { AssayGeneralData } from './GeneralData';
import { AssayDrawers } from './Drawers';
import { axiosClient } from '../../utils/axios';

import classes from './assayDetails.module.css';

export function AssayDetails() {
  const [refetchEnabled, setRefetchEnabled] = createSignal(true);
  const params = useParams();

  const query = createQuery(
    () => ['run', params.id],
    () =>
      axiosClient
        .get<ExtendedDetailedAnalysisRun>(`/api/v1/analysis/${params.id}`, {
          params: { extended: true },
        })
        .then((res) => res.data),
    {
      refetchOnWindowFocus: false,
      refetchInterval: 15000,
      get enabled() {
        return refetchEnabled();
      },
      onSuccess: (run) => {
        if (run?.status !== 'IN_PROGRESS') {
          setRefetchEnabled(false);
        }
        return run;
      },
    }
  );

  return (
    <Transition appear name="fade">
      <div class={`${classes.container} page-margin`}>
        <Typography class={classes.header} component="h2" weight="bold">
          Details
        </Typography>
        <AssayGeneralData run={query.data} />
        <AssayBiomarkerTable run={query.data} />
        <AssayDrawers run={query.data} />
      </div>
    </Transition>
  );
}
