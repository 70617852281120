import { Accessor } from 'solid-js';
import { Select, SelectProps, SelectOption } from '@imagene/components';
import { createQuery } from '@tanstack/solid-query';
import { axiosClient } from '../../utils/axios';
import { ExtendedPanelResponse } from '@imagene/api-interfaces';

export type PanelSelectEvent = SelectOption<string>;

export interface PanelSelectProps
  extends Omit<SelectProps<string>, 'options' | 'loading'> {
  onSuccess?: (response: ExtendedPanelResponse) => void;
}

export function PanelSelect(props: PanelSelectProps) {
  const panelQuery = createQuery(
    () => ['panels'],
    () =>
      axiosClient
        .get<ExtendedPanelResponse>('/api/v1/panels',{params: {extended: true}})
        .then((res) => res.data),
    {
      onSuccess: props.onSuccess,
      refetchOnWindowFocus: false,
    }
  );

  const options: Accessor<SelectOption<string>[]> = () =>
    panelQuery.data?.map((panel) => ({
      id: panel.id,
      label: panel.label,
      value: panel.id,
    })) ?? [];

  return (
    <Select loading={panelQuery.isFetching} options={options()} {...props} />
  );
}
