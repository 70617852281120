/* eslint-disable no-case-declarations */
import {
  Configuration,
  FrontendApi,
  LoginFlow,
  RecoveryFlow,
  SettingsFlow,
} from '@ory/client';
import { isAxiosError } from 'axios';
import { useNavigate } from '@solidjs/router';
import { useAuth } from './session';
import { useReturnToQueryString, useReturnTo } from './use-return-to';
import * as Sentry from '@sentry/browser';
import { datadogLogs } from '@datadog/browser-logs';

export const authClient = new FrontendApi(
  new Configuration({ basePath: '/auth' })
);

type Flow = LoginFlow | SettingsFlow | RecoveryFlow;

export interface HandleAuthErrorResult<T extends Flow> {
  badRequestFlow?: T;
}

export function useLogout() {
  const navigate = useNavigate();
  const { clearSession } = useAuth();
  const errorHandler = useAuthErrorHandler();

  return async function logout() {
    try {
      const {
        data: { logout_token },
      } = await authClient.createBrowserLogoutFlow();

      await authClient.updateLogoutFlow({
        token: logout_token,
      });

      Sentry.addBreadcrumb({
        category: 'auth',
        type: 'info',
        message: 'User logged out',
      });

      datadogLogs.logger.info('user logged out');

      clearSession();
      navigate('/login');
    } catch (e) {
      await errorHandler(e);
      datadogLogs.logger.error(
        `user failed to log out, error: ${JSON.stringify(e)}`
      );
    }
  };
}

export function useAuthErrorHandler<T extends Flow>() {
  const navigate = useNavigate();
  const { clearSession, setAAL2Required, isAAL2Required } = useAuth();
  const returnTo = useReturnToQueryString(useReturnTo());

  async function handleAuthError(
    error: unknown
  ): Promise<HandleAuthErrorResult<T>> {
    if (returnTo.includes('unsupported_browser')) {
      return {};
    }
    if (isAxiosError(error)) {
      switch (error.response?.data?.error?.id) {
        case 'session_aal2_required':
          setAAL2Required(true);
          navigate(`/login/2fa${returnTo}`, { replace: true });
          return {};
        case 'session_already_available':
          navigate(isAAL2Required() ? `/login/2fa${returnTo}` : returnTo, {
            replace: true,
          });
          return {};
        case 'session_refresh_required':
          // We need to re-authenticate to perform this action
          window.location.href = error.response?.data.redirect_browser_to;
          return {};
        case 'self_service_flow_return_to_forbidden':
        case 'self_service_flow_expired':
        case 'security_csrf_violation':
        case 'security_identity_mismatch':
          clearSession();
          navigate('/login');
          return {};
        case 'browser_location_change_required':
          // Ory Kratos asked us to point the user to this URL.
          window.location.href = error.response?.data.redirect_browser_to;
          return {};
      }

      switch (error.response?.status) {
        case 400:
          return { badRequestFlow: error.response.data };
        case 403:
        case 401:
          clearSession();
          navigate(`/login${returnTo}`, { replace: true });
          break;
        case 410:
          clearSession();
          navigate('/login', { replace: true });
          break;
        case 503:
        case 500:
          // return { navigateTo: '/error' };
          break;
        default:
          console.error(new Error('unhandled error code', { cause: error }));
      }
    } else {
      console.error(new Error('unknown error', { cause: error }));
    }
    return {};
  }

  return handleAuthError;
}
