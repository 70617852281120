import { Show } from 'solid-js';
import classes from '../users.module.css';
import { Deactivate } from './Deactivate';
import { Activate } from './Activate';
import { Reset2FA } from './Reset2FA';
import { ResendInvite } from './ResendInvite';

interface UserActionsProps {
  inactive: boolean;
  userId: string;
  email: string;
}

export function UserActions(props: UserActionsProps) {
  return (
    <div
      classList={{
        [classes['actions-container']]: true,
      }}
    >
      <Show
        when={!props.inactive}
        fallback={<Activate email={props.email} id={props.userId} />}
      >
        <ResendInvite email={props.email} id={props.userId} />
        <Reset2FA email={props.email} id={props.userId} />
        <Deactivate email={props.email} id={props.userId} />
      </Show>
    </div>
  );
}
