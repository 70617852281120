export default function ResultsIcon() {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="1"
        y="1"
        width="30"
        height="30"
        rx="1.5"
        stroke="currentcolor"
        stroke-width="2"
      />
      <rect x="5" y="23" width="7" height="2" fill="currentcolor" />
      <rect x="5" y="18" width="7" height="2" fill="currentcolor" />
      <rect x="5" y="13" width="7" height="2" fill="currentcolor" />
      <rect x="5" y="8" width="7" height="2" fill="currentcolor" />
      <rect x="14" y="23" width="2" height="2" fill="currentcolor" />
      <rect x="14" y="18" width="2" height="2" fill="currentcolor" />
      <rect x="14" y="13" width="2" height="2" fill="currentcolor" />
      <rect x="14" y="8" width="2" height="2" fill="currentcolor" />
      <rect x="18" y="23" width="9" height="2" fill="currentcolor" />
      <rect x="18" y="18" width="9" height="2" fill="currentcolor" />
      <rect x="18" y="13" width="9" height="2" fill="currentcolor" />
      <rect x="18" y="8" width="9" height="2" fill="currentcolor" />
    </svg>
  );
}
