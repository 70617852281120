export default function AddSlideIcon() {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="1"
        y="1"
        width="30"
        height="30"
        rx="1"
        stroke="currentcolor"
        stroke-width="2"
      />
      <line
        x1="16"
        y1="9"
        x2="16"
        y2="23"
        stroke="currentcolor"
        stroke-width="2"
      />
      <line
        x1="9"
        y1="16"
        x2="23"
        y2="16"
        stroke="currentcolor"
        stroke-width="2"
      />
    </svg>
  );
}
