import { createStore } from 'solid-js/store';
import { useNavigate } from '@solidjs/router';
import {
  ImageneIdentityResponse,
  InviteUserDto,
  UpdateUserDetailsDto,
} from '@imagene/auth';
import { createMutation, createQuery } from '@tanstack/solid-query';
import { isAxiosError } from 'axios';
import { useToast } from '@imagene/components';

import { axiosClient } from '../../utils/axios';
import queryClient from '../../utils/queryClient';

interface UserFormValues extends InviteUserDto {
  fullName: string;
}

export function useUserForm(user_id?: string, editMode = false) {
  const { toast } = useToast();
  const navigate = useNavigate();

  const [formValues, setFormValues] = createStore<UserFormValues>({
    name: {
      first: '',
      last: '',
    },
    email: '',
    role: 'USER',
    fullName: '',
  });

  const updateUserMutation = createMutation(
    (data: UpdateUserDetailsDto) =>
      axiosClient
        .put(`/api/v1/admin/users/${user_id}`, data)
        .then((res) => res.data),
    {
      onSuccess: () => {
        toast({
          title: 'User details updated successfully!',
          dismissAfter: 5000,
        });
        queryClient.invalidateQueries(['user', user_id]);
      },
      onError: () => {
        toast({
          type: 'error',
          title: 'Something went wrong',
          description:
            'We could not update user details. Please try again later.',
          dismissAfter: 5000,
        });
      },
    }
  );

  createQuery(
    () => ['user', user_id],
    () =>
      axiosClient
        .get<ImageneIdentityResponse>(`/api/v1/admin/users/${user_id}`)
        .then((res) => res.data),
    {
      get enabled() {
        return editMode;
      },
      onSuccess: (identity) => {
        setFormValues({
          name: identity.traits.name,
          role: identity.metadata_public.role,
          email: identity.traits.email,
          fullName: identity.metadata_public.full_name,
        });
      },
      refetchOnWindowFocus: !editMode,
    }
  );

  const createUserMutation = createMutation(
    async (data: InviteUserDto) =>
      axiosClient.post('/api/v1/admin/users/invite', data),
    {
      onSuccess: () => {
        toast({
          title: 'Invitation sent!',
          description: `Invitation was sent to email ${formValues.email} successfully`,
          dismissAfter: 5000,
        });
        navigate(-1);
      },
      onError: (err) => {
        //conflict - in case of duplicate email
        if (isAxiosError(err)) {
          if (err?.response?.status === 409) {
            if (err?.response?.data?.inactive) {
              toast({
                type: 'error',
                title: 'Invitation failed!',
                description: `User with email ${formValues.email} already exists and is de-activated.\n
                                You can activate this account by clicking on 'Activate' button in 'Users Management' table`,
                dismissAfter: 5000,
              });
            } else {
              toast({
                type: 'error',
                title: 'Invitation failed!',
                description: `User with email ${formValues.email} already exists.`,
                dismissAfter: 5000,
              });
            }
          }
        } else {
          toast({
            type: 'error',
            title: 'Failed to invite',
            description: `Invitation email was not sent due to technical issues. Please try again later.`,
            dismissAfter: 5000,
          });
        }
      },
    }
  );

  function onSubmit(e: SubmitEvent) {
    e.preventDefault();
    if (editMode) {
      updateUserMutation.mutate({ ...formValues });
    } else {
      createUserMutation.mutate({ ...formValues });
    }
  }

  return {
    onSubmit,
    values: formValues,
    setValues: setFormValues,
  };
}
