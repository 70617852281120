import { For, ParentProps, Show } from 'solid-js';
import { Tooltip, Typography } from '@imagene/components';
import { useTenant } from '../../../utils/use-tenant';

import classes from './slide-details.module.css';
import { useAnalysisCreation } from '../use-create-analysis';

interface SlideDetailsProps {
  mini?: boolean;
  panel?: boolean;
}

export function SlideDetails(props: ParentProps<SlideDetailsProps>) {
  const config = useTenant();
  const [details] = useAnalysisCreation();
  return (
    <div class={classes.container}>
      <div class={classes.item}>
        <Typography weight="bold">File name</Typography>
        <Tooltip text={details.file.name} placement="bottom-start">
          <Typography id="file_name" class="text-wrap" wrap="20ch">
            {details.file.name}
          </Typography>
        </Tooltip>
      </div>

      <Show when={!props.mini}>
        <div class={classes.item}>
          <Typography weight="bold">Slide ID</Typography>
          <Tooltip text={details.slide_id}>
            <Typography id="slide_id_title" class="text-wrap" wrap="20ch">
              {details.slide_id}
            </Typography>
          </Tooltip>
        </div>

        <Show when={config().accession_enabled}>
          <div class={classes.item}>
            <Typography weight="bold">Accession ID</Typography>
            <Tooltip text={details.accession ?? 'Unknown'}>
              <Typography
                id="accession_number_title"
                class="text-wrap"
                wrap="20ch"
              >
                {details.accession}
              </Typography>
            </Tooltip>
          </div>
        </Show>
      </Show>

      <Show when={!props.mini && props.panel}>
        <Show when={details.panel}>
          {(panel) => (
            <>
              <div class={classes.item}>
                <Typography weight="bold">Panel</Typography>
                <Typography id="panel_name_title">{panel().label}</Typography>
              </div>

              <div
                classList={{
                  [classes.item]: true,
                  [classes.biomarkers]: true,
                }}
              >
                <Typography weight="bold">Biomarkers</Typography>
                <div class={classes.biomarker} id="biomarkers_title">
                  <Show
                    when={!details.completePanel}
                    fallback={<Typography>Complete Panel</Typography>}
                  >
                    <For each={panel().biomarkers}>
                      {(bio) => <Typography>{bio.label}</Typography>}
                    </For>
                  </Show>
                </div>
              </div>
            </>
          )}
        </Show>
      </Show>
    </div>
  );
}
