import { For, JSX, createEffect, createSignal } from 'solid-js';
import classes from './dual-list.module.css';
import { Typography } from '../Typography';
import ArrowIcon from '../icons/ArrowIcon';
import DownArrowIcon from '../icons/DownArrowIcon';
import DoubleArrowIconRight from '../icons/DoubleArrowIconRight';
import DoubleArrowIconLeft from '../icons/DoubleArrowIconLeft';
import RightArrowIcon from '../icons/RightArrowIcon';
import LeftArrowIcon from '../icons/LeftArrowIcon';

export interface SingleListItem {
  id: string;
  label: string;
  name: string;
}

export interface SingleListProps {
  items: SingleListItem[];
  setSelectedItem: (index: number, list?: 'right' | 'left') => void;
  selected: number | null;
  disabled: boolean;
  list: 'right' | 'left';
  label: string;
}

export interface DualListProps {
  leftLabel: string;
  leftList: SingleListItem[];
  rightLabel: string;
  rightList: SingleListItem[];
  onMoveUp?: (leftList: SingleListItem[], rightList: SingleListItem[]) => void;
  onMoveDown?: (
    leftList: SingleListItem[],
    rightList: SingleListItem[]
  ) => void;
  onMoveLeft?: (
    leftList: SingleListItem[],
    rightList: SingleListItem[]
  ) => void;
  onMoveRight?: (
    leftList: SingleListItem[],
    rightList: SingleListItem[]
  ) => void;
  onMoveAllLeft?: (
    leftList: SingleListItem[],
    rightList: SingleListItem[]
  ) => void;
  onMoveAllRight?: (
    leftList: SingleListItem[],
    rightList: SingleListItem[]
  ) => void;
  disabled?: boolean;
}
export interface ActionBtnProps {
  onClick: () => void;
  icon: JSX.Element;
  disabled: boolean;
}

export function DualList(props: DualListProps) {
  const [leftListSelected, setLeftListSelected] = createSignal<number | null>(
    null
  );
  const [leftItems, setLeftItems] = createSignal<SingleListItem[]>(
    props.leftList
  );
  const [rightItems, setRightItems] = createSignal<SingleListItem[]>(
    props.rightList
  );
  const [rightListSelected, setRightListSelected] = createSignal<number | null>(
    null
  );

  const canMoveUp = () =>
    !props.disabled &&
    rightListSelected() !== null &&
    (rightListSelected() as number) - 1 >= 0;

  const canMoveDown = () =>
    !props.disabled &&
    rightListSelected() !== null &&
    (rightListSelected() as number) + 1 < rightItems().length;

  const canMoveRight = () => !props.disabled && leftListSelected() !== null;
  const canMoveLeft = () => !props.disabled && rightListSelected() !== null;
  const canMoveAllLeft = () => !props.disabled && !!rightItems().length;
  const canMoveAllRight = () => !props.disabled && !!leftItems().length;

  const setSelectedItem = (index: number, list?: 'right' | 'left') => {
    if (list === 'right') {
      setRightListSelected(index);
      setLeftListSelected(null);
    } else if (list === 'left') {
      setLeftListSelected(index);
      setRightListSelected(null);
    }
  };

  createEffect(() => {
    if (props.rightList.length) {
      setRightItems(props.rightList);
    }
    if (props.leftList.length) {
      setLeftItems(
        props.leftList.filter((i) => {
          const found = props.rightList.find((r) => r.id === i.id);
          if (!found) {
            return i;
          }
        })
      );
    }
  });

  const onMoveUp = () => {
    const from = rightListSelected() as number;
    const to = (rightListSelected() as number) - 1;
    const items = [...rightItems()];

    const item = items.splice(from, 1)[0];
    if (!item) return;

    items.splice(to, 0, item);

    setRightItems(items);

    setSelectedItem(to, 'right');

    if (typeof props.onMoveUp === 'function') {
      props.onMoveUp(leftItems(), rightItems());
    }
  };

  const onMoveDown = () => {
    const from = rightListSelected() as number;
    const to = (rightListSelected() as number) + 1;
    const items = [...rightItems()];

    const item = items.splice(from, 1)[0];
    if (!item) return;

    items.splice(to, 0, item);

    setRightItems(items);

    setSelectedItem(to, 'right');

    props.onMoveDown && props.onMoveDown(leftItems(), rightItems());
  };

  const onMoveLeft = () => {
    const item = rightItems().find((_, idx) => idx === rightListSelected());
    if (!item) return;

    setLeftItems([...leftItems(), item]);
    setRightItems(rightItems().filter((i) => item.id !== i.id));
    setRightListSelected(null);

    props.onMoveLeft && props.onMoveLeft(leftItems(), rightItems());
  };

  const onMoveRight = () => {
    const item = leftItems().find((_, idx) => idx === leftListSelected());
    if (!item) return;

    setRightItems([...rightItems(), item]);
    setLeftItems(leftItems().filter((i) => item.id !== i.id));
    setLeftListSelected(null);

    props.onMoveRight && props.onMoveRight(leftItems(), rightItems());
  };

  const onMoveAllLeft = () => {
    setLeftItems([...leftItems(), ...rightItems()]);
    setRightItems([]);

    props.onMoveAllLeft && props.onMoveAllLeft(leftItems(), rightItems());
  };

  const onMoveAllRight = () => {
    setRightItems([...leftItems(), ...rightItems()]);
    setLeftItems([]);

    props.onMoveAllRight && props.onMoveAllRight(leftItems(), rightItems());
  };

  return (
    <div class={classes.container}>
      <SingleList
        label={props.leftLabel}
        list="left"
        items={leftItems()}
        setSelectedItem={setSelectedItem}
        disabled={!!props.disabled}
        selected={leftListSelected()}
      />
      <div class={classes['actions-container']}>
        <ActionBtn
          disabled={!canMoveUp()}
          onClick={onMoveUp}
          icon={<ArrowIcon />}
        />
        <ActionBtn
          disabled={!canMoveDown()}
          onClick={onMoveDown}
          icon={<DownArrowIcon />}
        />
        <ActionBtn
          disabled={!canMoveRight()}
          onClick={onMoveRight}
          icon={<RightArrowIcon />}
        />
        <ActionBtn
          disabled={!canMoveLeft()}
          onClick={onMoveLeft}
          icon={<LeftArrowIcon />}
        />
        <ActionBtn
          disabled={!canMoveAllRight()}
          onClick={onMoveAllRight}
          icon={<DoubleArrowIconRight />}
        />
        <ActionBtn
          disabled={!canMoveAllLeft()}
          onClick={onMoveAllLeft}
          icon={<DoubleArrowIconLeft />}
        />
      </div>
      <SingleList
        label={props.rightLabel}
        list="right"
        items={rightItems()}
        setSelectedItem={setSelectedItem}
        disabled={!!props.disabled}
        selected={rightListSelected()}
      />
    </div>
  );
}

function ListItem(props: {
  selected: boolean;
  item: SingleListItem;
  onClick: VoidFunction;
}) {
  return (
    <li
      onClick={props.onClick}
      classList={{
        [classes['list-item']]: true,
        [classes.selected]: props.selected,
      }}
    >
      <Typography>{props.item.label}</Typography>
    </li>
  );
}

function ActionBtn(props: ActionBtnProps) {
  return (
    <button
      type="button"
      classList={{
        [classes['action-btn']]: true,
      }}
      disabled={props.disabled}
      onClick={props.onClick}
    >
      {props.icon}
    </button>
  );
}

function SingleList(props: SingleListProps) {
  return (
    <div class={classes['list-container']}>
      <Typography weight="semi-bold" component="div">
        {props.label}
      </Typography>
      <ul
        classList={{
          [classes['items-container']]: true,
          [classes.disabled]: props.disabled,
        }}
      >
        <For each={props.items}>
          {(item, idx) => (
            <ListItem
              onClick={() => props.setSelectedItem(idx(), props.list)}
              selected={props.selected === idx()}
              item={item}
            />
          )}
        </For>
      </ul>
    </div>
  );
}
