import { Show } from 'solid-js';
import { Navigate, Outlet } from '@solidjs/router';
import { useAuth } from '../../utils/session';

export function Admin() {
  const { role } = useAuth();

  return (
    <Show when={role() === 'ADMIN'} fallback={<Navigate href="/" />}>
      <Outlet />
    </Show>
  );
}
