import { JSX, ParentProps } from 'solid-js';

import classes from './link.module.css';

type LinkProps = ParentProps<JSX.AnchorHTMLAttributes<HTMLAnchorElement>> & {
  disabled?: boolean;
};

export function Link(props: LinkProps) {
  return (
    <a
      {...props}
      class={classes.link}
      classList={{
        [props.class ?? '']: !!props.class,
        [classes.disabled]: !!props.disabled,
      }}
    >
      {props.children}
    </a>
  );
}
