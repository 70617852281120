import { createSignal, ParentProps } from 'solid-js';
import { ForgotPasswordDto } from '@imagene/auth';
import { Button, Input, Typography } from '@imagene/components';

import classes from './forgot-password.module.css';

interface ForgotPasswordFormProps {
  message: string;
  onSubmit: (values: ForgotPasswordDto) => void;
}

export function ForgotPasswordForm(
  props: ParentProps<ForgotPasswordFormProps>
) {
  const [email, setEmail] = createSignal('');

  function onSubmit(e: SubmitEvent) {
    e.preventDefault();
    props.onSubmit({ email: email() });
  }

  return (
    <form onSubmit={onSubmit} class={classes.form}>
      <Typography component="h1">Forgot Password</Typography>
      <Typography component="p">Enter your email to recover</Typography>
      <Input
        label="Email"
        type="email"
        required
        placeholder="Email"
        onInput={(e) => setEmail(e.currentTarget.value)}
      />
      <Typography id="message">{props.message}</Typography>
      <Button id="submit" disabled={email() === ''}>
        {props.message ? 'Submit again' : 'Submit'}
      </Button>
    </form>
  );
}
