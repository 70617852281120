import { Select, SelectOption } from '@imagene/components';
import classes from './official-results.module.css';
import { Show } from 'solid-js';
import { createMutation } from '@tanstack/solid-query';
import { axiosClient } from '../../utils/axios';
import { CreateOfficialResultDto, ResultType } from '@imagene/api-interfaces';

interface OfficialResultProps {
  run_id: string;
  show: boolean;
  direction: 'up' | 'down';
  results: ResultType[];
  onSuccessCb?: VoidFunction;
}

export function BiomarkerResultSelect(props: OfficialResultProps) {
  const resultMutation = createMutation(
    (
      data: Pick<
        CreateOfficialResultDto,
        'all_negative' | 'positive_result_id' | 'run_id'
      >
    ) => axiosClient.post(`/api/v1/validation/results`, [data]),
    {
      onSuccess: props?.onSuccessCb,
    }
  );

  const onResultSelect = (option: SelectOption<string>) => {
    const { id, value, label } = option;
    if (value === 'all_negative') {
      resultMutation.mutate({
        all_negative: true,
        run_id: props.run_id,
      });
    } else if (label.includes('Positive')) {
      resultMutation.mutate({
        positive_result_id: id,
        run_id: props.run_id,
      });
    }
  };

  const options = () => [
    ...(props.results?.map((r) => {
      const name = r.biomarker.label.slice(0, r.biomarker.label.indexOf(' '));
      const label = `${name} Positive`;
      const id = r.id;
      if (!id) throw new Error(`undefined result id for biomarker ${name}`);
      return {
        id,
        value: id,
        label,
      };
    }) ?? []),
    { id: 'all_negative', value: 'all_negative', label: 'All Negative' },
  ];

  const isAllNegative = props.results?.every(
    (r) => r?.official_result?.value === 'NEGATIVE'
  );

  const selected = () =>
    props?.results?.find((r) => r?.official_result?.value === 'POSITIVE');

  const value = () => (isAllNegative ? 'all_negative' : selected()?.id);

  return (
    <Show when={props.show}>
      <Select
        options={options()}
        highlight
        containerClass={classes.select}
        placeholder="Actual result"
        direction={props.direction}
        onSelect={onResultSelect}
        disabled={resultMutation.isLoading}
        loading={resultMutation.isLoading}
        value={value()}
        style="default"
      />
    </Show>
  );
}

export function MolecularAssaySelect(props: OfficialResultProps) {
  const assayMutation = createMutation(
    (data: Pick<CreateOfficialResultDto, 'assay' | 'run_id'>) =>
      axiosClient.post(`/api/v1/validation/results`, [data]),
    {
      onSuccess: props?.onSuccessCb,
    }
  );

  const onAssaySelect = (option: SelectOption<string>) => {
    assayMutation.mutate({
      assay: option.value,
      run_id: props.run_id,
    });
  };

  const selected = () =>
    props?.results?.find((r) => Boolean(r?.official_result?.assay))
      ?.official_result?.assay ?? undefined;

  const assayOptions = [
    'NGS - hybrid, DNA&RNA',
    'NGS - hybrid, DNA only',
    'NGS - amplicon, DNA&RNA',
    'NGS - amplicon, DNA only',
    'PCR',
    'IHC',
    'FISH',
  ];

  const options = () =>
    assayOptions.map((a) => ({
      id: a,
      label: a,
      value: a,
    }));

  return (
    <Show when={props.show}>
      <Select
        options={options()}
        highlight
        containerClass={classes.wide}
        placeholder="Select assay"
        direction={props.direction}
        onSelect={onAssaySelect}
        loading={assayMutation.isLoading}
        disabled={assayMutation.isLoading}
        value={selected()}
        style="default"
      />
    </Show>
  );
}
