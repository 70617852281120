import { Skeleton } from '@imagene/components';
import classes from './skeletonTable.module.css';

export function SkeletonTable() {
  return (
    <>
      <SkeletonTableGroup />
      <SkeletonTableGroup />
    </>
  );
}

function SkeletonTableGroup() {
  return (
    <>
      <tr class={classes.unActiveTableRow}>
        <Skeleton width={175} />
        <Skeleton width={175} />
      </tr>
      <tr class={classes.unActiveTableRow}>
        <Skeleton width={114} />
        <Skeleton width={114} />
      </tr>
      <tr class={classes.unActiveTableRow}>
        <Skeleton width={90} />
        <Skeleton width={90} />
      </tr>
    </>
  );
}
