import { createSignal } from 'solid-js';
import classes from './users.module.css';
import { UserRole } from '@imagene/api-interfaces';
import { Button, Input, Select, Typography } from '@imagene/components';
import SideArrowIcon from '@imagene/components/icons/SideArrowIcon';
import { useNavigate, useParams } from '@solidjs/router';
import { Transition } from 'solid-transition-group';
import { useUserForm } from './use-user-form';

export function UserForm(props: { editMode?: boolean }) {
  let form: HTMLFormElement;
  const params = useParams();
  const navigate = useNavigate();
  const [isValid, setIsValid] = createSignal(props.editMode);

  const { onSubmit, values, setValues } = useUserForm(
    params.id,
    props.editMode
  );

  function validateForm() {
    setIsValid(form.checkValidity());
  }

  return (
    <Transition appear name="fade">
      <div
        classList={{
          [classes.container]: true,
          'page-margin': true,
        }}
      >
        <div
          class={classes['title-row']}
          onClick={() => navigate('/admin/users')}
        >
          <SideArrowIcon />
          <Typography
            weight="bold"
            component="h1"
            class={classes.title}
            classList={{
              [classes.title]: true,
              [classes.link]: true,
            }}
          >
            Back to Users Management
          </Typography>
        </div>
        <form class={classes.card} onSubmit={onSubmit} ref={(r) => (form = r)}>
          <div class={classes['card-header']}>
            <Typography weight="bold">
              {props.editMode ? values.fullName : 'Create User'}
            </Typography>
          </div>
          <div
            classList={{
              [classes['card-body']]: true,
              [classes.details]: true,
            }}
          >
            <Input
              label="First name"
              placeholder="First name"
              name="first"
              onInput={(e) => {
                setValues('name', 'first', e.target.value);
                validateForm();
              }}
              class={classes.input}
              required
              value={values.name.first}
              minlength={2}
              maxlength={30}
              tabIndex={1}
            />
            <Input
              label="Last Name"
              placeholder="Last name"
              name="last"
              required
              onInput={(e) => {
                setValues('name', 'last', e.target.value);
                validateForm();
              }}
              class={classes.input}
              value={values.name.last}
              minlength={2}
              maxlength={30}
              tabIndex={2}
            />
            <Input
              label="Email"
              placeholder="Email"
              name="email"
              onInput={(e) => {
                setValues('email', e.target.value);
                validateForm();
              }}
              disableOptionalTxt={props.editMode}
              disabled={props.editMode}
              required
              class={classes.input}
              value={values.email}
              type="email"
              tabIndex={3}
            />
            <Select
              label="Role"
              options={[
                { id: '', label: 'User', value: 'USER' },
                { id: '', label: 'Admin', value: 'ADMIN' },
              ]}
              onSelect={(option) => {
                setValues('role', option.value as UserRole);
                validateForm();
              }}
              containerClass={classes['select-container']}
              class={classes.select}
              value={values.role}
            />
            <div class={classes['btn-wrapper']}>
              <Button class={classes.btn} disabled={!isValid()} tabIndex={5}>
                {props.editMode ? 'Save Changes' : 'Send invitation'}
              </Button>
            </div>
          </div>
        </form>
      </div>
    </Transition>
  );
}
