import { useToast } from '@imagene/components';

export function useSessionExpired(
  description: string,
  onDismiss: VoidFunction
) {
  const { toast } = useToast();

  return () =>
    toast({
      title: 'Session expired!',
      description: `${description}, please sign-in again.`,
      type: 'warning',
      onDismiss,
    });
}
