export default function CircleCheckMarkIcon() {
  return (
    <svg
      width="95"
      height="95"
      viewBox="0 0 95 95"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="47.5" cy="47.5" r="45" stroke="#48AF5F" stroke-width="5" />
      <path
        d="M27 48.5821L40.2857 62L69 33"
        stroke="#48AF5F"
        stroke-width="5"
        stroke-linecap="round"
      />
    </svg>
  );
}
