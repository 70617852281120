import { Accessor, onCleanup, onMount } from 'solid-js';

export interface UseOutsideClickListenerOptions {
  el: Accessor<HTMLElement>;
  targetId: string;
  onOutsideClick: VoidFunction;
}

export function useOutsideClickListener(
  options: UseOutsideClickListenerOptions
) {
  function outsideClickHandler(e: MouseEvent) {
    const target = e.target as HTMLElement;
    if (target === options.el()) return;
    const closest = target.closest(`#${options.targetId}`);
    if (closest) return;

    options.onOutsideClick();
  }

  onMount(() => {
    window.addEventListener('click', outsideClickHandler);
  });

  onCleanup(() => {
    window.removeEventListener('click', outsideClickHandler);
  });
}
