import { ParentProps, Show, onCleanup, onMount } from 'solid-js';
import { useNavigate } from '@solidjs/router';
import { Button, Typography } from '@imagene/components';
import CircleXIcon from '@imagene/components/icons/CircleXIcon';
import CircleCheckMarkIcon from '@imagene/components/icons/CircleCheckMarkIcon';
import { PageCardNavigation } from '../../../../components/PageCardNavigation';
import { SlideDetails } from '../../SlideDetailsSubHeader';
import classes from './upload-done.module.css';
import { useAnalysisCreation } from '../../use-create-analysis';

interface UploadDoneProps {
  success: boolean;
}

export function UploadDone(props: ParentProps<UploadDoneProps>) {
  const navigate = useNavigate();
  const [details] = useAnalysisCreation();
  let timeoutId: NodeJS.Timeout;
  const message = () =>
    props.success ? 'File uploaded successfully' : 'Upload failed';

  const btnTxt = () =>
    props.success ? 'Proceed To Slide Details' : 'Try To Upload Again';

  onMount(() => {
    if (props.success && details.run_id) {
      timeoutId = setTimeout(
        () => navigate(`/assays/${details.run_id}`),
        10000
      );
    }
  });

  onCleanup(() => clearTimeout(timeoutId));

  function onClick() {
    if (props.success && details.run_id) {
      navigate(`/assays/${details.run_id}`);
    } else {
      window.location.reload();
    }
  }

  return (
    <PageCardNavigation
      title="Add slides"
      description="Uploading"
      subheader={<SlideDetails panel />}
    >
      <section class={classes.section}>
        <div class={classes.container}>
          <Show when={props.success} fallback={<CircleXIcon />}>
            <CircleCheckMarkIcon />
          </Show>
          <Typography
            weight="bold"
            class={classes.txt}
            id="upload_done_message"
          >
            {message()}
          </Typography>
          <Button class={classes.btn} onClick={onClick} id="upload_done_next">
            {btnTxt()}
          </Button>
        </div>
      </section>
    </PageCardNavigation>
  );
}
