import { Skeleton } from '@imagene/components';

import classes from './users.module.css';

const makeRow = (numOfRows: number) => {
  const rows = [];
  for (let i = 0; i < numOfRows; i++) {
    rows.push(
      <div class={classes['skeleton-row']}>
        <div
          classList={{
            [classes['skeleton-cell']]: true,
          }}
        >
          <Skeleton width="75%" />
        </div>
        <div
          classList={{
            [classes['skeleton-cell']]: true,
            [classes['flex-start']]: true,
          }}
        >
          <Skeleton width="70%" />
        </div>
        <div
          classList={{
            [classes['skeleton-cell']]: true,
            [classes['flex-start']]: true,
          }}
        >
          <Skeleton width="70%" />
        </div>
        <div
          classList={{
            [classes['skeleton-cell']]: true,
            [classes['flex-start']]: true,
          }}
        >
          <Skeleton width="70%" />
        </div>
      </div>
    );
  }
  return rows;
};

export function UsersTableSkeleton() {
  return <div class={classes['skeleton-rows-wrapper']}>{makeRow(9)}</div>;
}
