import { LoginFlow } from '@ory/client';
import { ParentProps } from 'solid-js';
import { AuthInput, Button, Link, Typography } from '@imagene/components';
import classes from './login.module.css';
import { createStore } from 'solid-js/store';
import {
  getGlobalError,
  getUiNode,
  getUiNodeAttributes,
} from '../../utils/auth';
import { useNavigate } from '@solidjs/router';

export interface LoginFormData {
  csrf_token: string;
  identifier: string;
  password: string;
}

interface LoginFormProps {
  flow: LoginFlow;
  onSubmit: (values: LoginFormData) => void;
}

export function LoginForm(props: ParentProps<LoginFormProps>) {
  const navigate = useNavigate();
  const csrfNode = getUiNodeAttributes(props.flow.ui.nodes, 'csrf_token');
  const identifier = getUiNodeAttributes(props.flow.ui.nodes, 'identifier');
  const password = getUiNodeAttributes(props.flow.ui.nodes, 'password');

  const [formValues, setFormValues] = createStore<LoginFormData>({
    csrf_token: csrfNode.value,
    identifier: identifier.value ?? '',
    password: password.value ?? '',
  });

  const valid = () => {
    return formValues.identifier !== '' && formValues.password !== '';
  };

  function onSubmit(e: SubmitEvent) {
    e.preventDefault();
    props.onSubmit(formValues);
  }

  return (
    <form class={classes.form} onSubmit={onSubmit}>
      <AuthInput
        label="Email"
        type="email"
        placeholder="Enter email"
        node={getUiNode(props.flow.ui.nodes, 'identifier')}
        onInput={(val) => setFormValues('identifier', val)}
        class="span-grid-column"
      />
      <AuthInput
        label="Password"
        placeholder="Enter password"
        node={getUiNode(props.flow.ui.nodes, 'password')}
        onInput={(val) => setFormValues('password', val)}
        class="span-grid-column"
      />
      <Typography class="span-grid-column" color="error">
        {getGlobalError(props.flow.ui)}
      </Typography>
      <Link
        id="forgot_password"
        class={classes.forgot}
        onClick={() => navigate('/forgot-password')}
      >
        Forgot Password
      </Link>
      <Button id="login" class={classes.submit} disabled={!valid()}>
        Login
      </Button>
    </form>
  );
}
