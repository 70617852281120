import { For, ParentProps, Show } from 'solid-js';
import classes from './group-button.module.css';
import { Typography } from '../..';

export interface GroupButton<T> {
  value: T;
  label: string;
}

interface GroupButtonsProps<T> {
  label?: string;
  selected: number | undefined;
  buttons: GroupButton<T>[];
  onClick: (value: T | undefined, index: number | undefined) => void;
}

export function GroupButtons<T>(props: ParentProps<GroupButtonsProps<T>>) {
  function onClick(value: T, index: number) {
    return () => {
      index === props.selected
        ? props.onClick(undefined, undefined)
        : props.onClick(value, index);
    };
  }
  return (
    <div class={classes.container}>
      <Show when={props.label}>
        <Typography component="div">{props.label}</Typography>
      </Show>
      <div class={classes.group}>
        <For each={props.buttons}>
          {(btn, index) => (
            <button
              type="button"
              classList={{
                [classes.btn]: true,
                [classes.selected]: props.selected === index(),
                [classes['first-btn']]: index() === 0,
                [classes['last-btn']]: index() === props.buttons.length - 1,
              }}
              onClick={onClick(btn.value, index())}
            >
              {btn.label}
            </button>
          )}
        </For>
      </div>
    </div>
  );
}
