import { createSignal, createEffect } from 'solid-js';
import { ProgressIndicatorProps } from './index';
import { StatusIcons } from '../StatusIndicator';
import { WorklistStatus } from '@imagene/api-interfaces';

export function useProgressStatus(props: ProgressIndicatorProps) {
  const [upload, setUpload] = createSignal(StatusIcons.unknown);
  const [qc, setQc] = createSignal(StatusIcons.unknown);
  const [analysis, setAnalysis] = createSignal(StatusIcons.unknown);
  const [current, setCurrent] = createSignal(StatusIcons.unknown);
  const [text, setText] = createSignal('');

  createEffect(() => {
    if (props.mode === 'full') handleMultiStatus();
    else handleSingleStatus();
  });

  function mapStatusIcon(status: WorklistStatus | undefined): StatusIcons {
    switch (status) {
      case 'IN_PROGRESS':
        return StatusIcons.ongoing;
      case 'QC_FAILED':
      case 'ERROR':
        return StatusIcons.error;
      case 'COMPLETE':
        return StatusIcons.done;
      default:
        return StatusIcons.unknown;
    }
  }

  function setTextFromStatus(status: WorklistStatus | undefined) {
    switch (status) {
      case 'IN_PROGRESS':
        setText('In progress');
        break;
      case 'QC_FAILED':
        setText('QC failed');
        break;
      case 'ERROR':
        setText('Error');
        break;
      case 'COMPLETE':
        setText('Complete');
        break;
      default:
        setText('');
        break;
    }
  }

  const handleMultiStatus = () => {
    switch (props.stage) {
      case 'UPLOAD':
        setUpload(mapStatusIcon(props.status));
        setQc(StatusIcons.unknown);
        setAnalysis(StatusIcons.unknown);
        setTextFromStatus(props.status);
        break;

      case 'QC':
        setUpload(StatusIcons.done);
        setQc(mapStatusIcon(props.status));
        setAnalysis(StatusIcons.unknown);
        setTextFromStatus(props.status);
        break;

      case 'ANALYSIS':
        setUpload(StatusIcons.done);
        setQc(StatusIcons.done);
        setAnalysis(mapStatusIcon(props.status));
        setTextFromStatus(props.status);
        break;
    }
  };

  const handleSingleStatus = () => {
    setCurrent(mapStatusIcon(props.status));
    setTextFromStatus(props.status);
  };

  return {
    current,
    upload,
    qc,
    analysis,
    text,
  };
}
