import { Typography } from '@imagene/components';
import { Transition } from 'solid-transition-group';

import classes from './settings.module.css';
import { useTenant } from '../../utils/use-tenant';
import { Show } from 'solid-js';
import { TwoFactorAuth } from './TwoFactorAuth';
// import { RemoteStorageConfig } from './RemoteStorageConfig';

export function Settings() {
  const tenant = useTenant();

  return (
    <Transition appear name="fade">
      <section
        classList={{
          [classes.container]: true,
          'page-margin': true,
        }}
      >
        <Typography weight="bold" component="h1">
          Organization Settings
        </Typography>
        <Show when={tenant()}>{(t) => <TwoFactorAuth config={t()} />}</Show>
        {/* <Show when={tenant()?.remote_storage_enabled && tenant()}>
          {(t) => <RemoteStorageConfig tenantId={t().id} />}
        </Show> */}
      </section>
    </Transition>
  );
}
