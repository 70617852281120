export function CopyIcon() {
  return (
    <svg
      width="14"
      height="18"
      viewBox="0 0 14 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.19118 18C0.873529 18 0.595588 17.865 0.357353 17.595C0.119118 17.325 0 17.01 0 16.65V3.0825H1.19118V16.65H10.6015V18H1.19118ZM3.57353 15.3C3.25588 15.3 2.97794 15.165 2.73971 14.895C2.50147 14.625 2.38235 14.31 2.38235 13.95V1.35C2.38235 0.99 2.50147 0.675 2.73971 0.405C2.97794 0.135 3.25588 0 3.57353 0H12.3088C12.6265 0 12.9044 0.135 13.1426 0.405C13.3809 0.675 13.5 0.99 13.5 1.35V13.95C13.5 14.31 13.3809 14.625 13.1426 14.895C12.9044 15.165 12.6265 15.3 12.3088 15.3H3.57353ZM3.57353 13.95H12.3088V1.35H3.57353V13.95ZM3.57353 13.95V1.35V13.95Z"
        fill="currentColor"
      />
    </svg>
  );
}
