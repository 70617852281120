import { Routes, Route } from '@solidjs/router';
import { Login } from './pages/Login';
import { Home } from './pages/Home';
import { Protected } from './components/auth/Protected';
import { Auth } from './utils/session';
import { Enroll2FA } from './pages/Enroll2FA';
import { MainLayout } from './components/layouts/MainLayout';
import { TwoFA } from './pages/TwoFA';
import { LoginLayout } from './components/layouts/LoginLayout';
import { Assays } from './pages/Assays';
import { About } from './pages/About';
import { ResetPassword } from './pages/ResetPassword';
import { QueryClientProvider } from '@tanstack/solid-query';
import { AssayDetails } from './pages/AssayDetails';
import { ForgotPassword } from './pages/ForgotPassword';
import { Analysis } from './pages/Analysis/index';
import { NotFound } from './pages/NotFound';
import { HideSmallDevice } from './pages/HideSmallDevice';
import { AccountDetails } from './pages/AccountDetails';
import queryClient from './utils/queryClient';
import { Users } from './pages/Users';
import { UserForm } from './pages/Users/UserForm';
import { Admin } from './components/auth/Admin';
import { ToastsProvider } from '@imagene/components';
import { LogErrorBoundary } from './components/LogErrorBoundary';
import { Settings } from './pages/Settings';
import { Code } from './pages/Code';
import { TenantProvider } from './utils/use-tenant';
import { Batches } from './pages/Batches';
import { UploadProvider } from './utils/use-upload';
import { Validation } from './pages/Validation';
import { ValidationStatistics } from './pages/Validation/ValidationStatistics';
import { MolecularResults } from './pages/Validation/MolecularResults';

import './sentry.config';
import './datadog.config';
import { InvitationExpired } from './pages/InvitationExpired';
import { AnalysisDetailsProvider } from './pages/Analysis/use-create-analysis';
import { useWebAssemblySupport } from './utils/use-wasm-support';
import { UnsupportedBrowserPage } from './pages/Error/Unsupported';

export function App() {
  useWebAssemblySupport();
  return (
    <ToastsProvider>
      <QueryClientProvider client={queryClient}>
        <Routes>
          <LogErrorBoundary component={null}>
            <Auth>
              <Route path="/reset-password" component={LoginLayout}>
                <Route path="/" component={ResetPassword} />
              </Route>
              <Route path="/forgot-password" component={LoginLayout}>
                <Route path="/" component={ForgotPassword} />
              </Route>
              <Route path="/login" component={LoginLayout}>
                <Route path="/" component={Login} />
                <Route path="/enroll" component={Enroll2FA} />
                <Route path="/2fa" component={TwoFA} />
              </Route>
              <Route path="/code" component={LoginLayout}>
                <Route path="/" component={Code} />
              </Route>
              <Route path="/expired" component={LoginLayout}>
                <Route path="/" component={InvitationExpired} />
              </Route>
              <Route
                path="*"
                element={
                  <TenantProvider>
                    <Protected>
                      <UploadProvider>
                        <HideSmallDevice />
                        <MainLayout />
                      </UploadProvider>
                    </Protected>
                  </TenantProvider>
                }
              >
                <Route path="/" component={Home} />
                <Route path="/assays">
                  <Route path="/" component={Assays} />
                  <Route path="/:id" component={AssayDetails} />
                </Route>
                <Route path="/batches" component={Batches} />
                <Route path="/validation" component={Validation}>
                  <Route path="/" component={ValidationStatistics} />
                  <Route path="/results" component={MolecularResults} />
                </Route>
                <Route
                  path="/analysis"
                  element={
                    <AnalysisDetailsProvider>
                      <Analysis />
                    </AnalysisDetailsProvider>
                  }
                />
                <Route path="/about" component={About} />
                <Route path="/account" component={AccountDetails} />
                <Route path="/admin" component={Admin}>
                  <Route path="/users">
                    <Route path="/" component={Users} />
                    <Route path="/:id" element={<UserForm editMode />} />
                    <Route path="/create" element={<UserForm />} />
                  </Route>
                  <Route path="/settings" component={Settings} />
                </Route>
                <Route path="*" component={NotFound} />
              </Route>
            </Auth>
            <Route path="/unsupported_browser" component={LoginLayout}>
              <Route path="/" component={UnsupportedBrowserPage} />
            </Route>
          </LogErrorBoundary>
        </Routes>
      </QueryClientProvider>
    </ToastsProvider>
  );
}
