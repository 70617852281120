import { Checkbox, Typography } from '..';
import FolderIcon from '../icons/FolderIcon';
import FileIcon from '../icons/FileIcon';
import { filesize } from 'filesize';

import classes from './file-explorer.module.css';
import { JSX, Show } from 'solid-js';

export interface FileExplorerItemProp {
  type: 'file' | 'folder';
  name: string;
  selected: boolean;
  onSelect: VoidFunction;
  onClick: VoidFunction;
  size?: number;
  disabled?: boolean;
  after?: JSX.Element;
}

export function FileExplorerItem(props: FileExplorerItemProp) {
  function toSizeDisplay(sizeInBytes: number | undefined) {
    if (!sizeInBytes) return 'unknown size';
    return filesize(sizeInBytes);
  }

  function onChange(e: Event) {
    e.preventDefault();
    props.onSelect();
  }

  return (
    <li
      classList={{
        [classes.active]: props.selected,
        [classes.disabled]: props.disabled,
      }}
    >
      <Checkbox
        checked={props.selected}
        onChange={onChange}
        disabled={props.disabled}
      />
      {props.type === 'file' ? <FileIcon /> : <FolderIcon />}
      <div class={classes.description} onClick={props.onClick}>
        <Typography
          class={classes.name}
          component="div"
          weight="bold"
          title={props.name}
        >
          {props.name}
        </Typography>
        <Typography class={classes.size} component="div">
          {props.type === 'file'
            ? toSizeDisplay(props.size)
            : `${props.size} direct files`}
        </Typography>
      </div>
      <Show when={props.after}>{props.after}</Show>
    </li>
  );
}
