import * as radio from '@zag-js/radio-group';
import { normalizeProps, useMachine } from '@zag-js/solid';
import { Index, createMemo, createUniqueId, splitProps } from 'solid-js';

import classes from './button-group.module.css';

export interface ButtonGroupItem {
  value: string;
  label: string;
}

export type ButtonGroupProps = Pick<
  radio.Context,
  'disabled' | 'name' | 'value' | 'onValueChange'
> & { items: ButtonGroupItem[] };

export function ButtonGroup(props: ButtonGroupProps) {
  const [context, local] = splitProps(props, [
    'value',
    'name',
    'disabled',
    'onValueChange',
  ]);
  const [state, send] = useMachine(radio.machine({ id: createUniqueId() }), {
    context,
  });

  const api = createMemo(() => radio.connect(state, send, normalizeProps));

  return (
    <div {...api().getRootProps()} class={classes.btnGroup}>
      <div {...api().getIndicatorProps()} />
      <Index each={local.items}>
        {(item) => (
          <label {...api().getItemProps({ value: item().value })}>
            <span {...api().getItemTextProps({ value: item().value })}>
              {item().label}
            </span>
            <input
              {...api().getItemHiddenInputProps({ value: item().value })}
            />
          </label>
        )}
      </Index>
    </div>
  );
}
