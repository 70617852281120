import { Match, Switch } from 'solid-js';
import { Typography } from '@imagene/components';
import { CheckmarkIcon } from '@imagene/components/icons/CheckmarkIcon';
import { DoubleErrorIcon } from '@imagene/components/icons/DoubleErrorIcon';
import Loader from '@imagene/components/icons/Loader';
import { Batch } from '@imagene/api-interfaces';

import classes from './batches.module.css';

interface StatusIndicatorProps {
  status: Batch['status'];
  count: number | null;
  total: number;
}

export function StatusIndicator(props: StatusIndicatorProps) {
  return (
    <div class={classes.status}>
      <Switch>
        <Match when={props.status === 'IN_PROGRESS'}>
          <Loader />
          <Typography>
            Processing&nbsp;&nbsp;{props.count} / {props.total}
          </Typography>
        </Match>
        <Match when={props.status === 'DONE'}>
          <CheckmarkIcon />
          <Typography>Complete</Typography>
        </Match>
        <Match when={props.status === 'ERROR'}>
          <DoubleErrorIcon />
          <Typography>Error</Typography>
        </Match>
      </Switch>
    </div>
  );
}
