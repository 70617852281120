import { For, Show } from 'solid-js';
import { Skeleton } from '../Skeleton';

import classes from './breadcrumbs.module.css';

export interface Crumb {
  label: string;
  href: string;
}

export interface BreadcrumbsProps {
  loading?: boolean;
  crumbs: Crumb[];
  onClick?: (crumb: Crumb) => void;
}

export function Breadcrumbs(props: BreadcrumbsProps) {
  return (
    <ul class={classes.breadcrumbs}>
      <Show when={!props.loading} fallback={<LoadingCrumbs />}>
        <For each={props.crumbs}>
          {(crumb, index) => (
            <>
              <li>
                <a href={crumb.href} onClick={() => props.onClick?.(crumb)}>
                  {crumb.label}
                </a>
              </li>
              <Show when={index() !== props.crumbs.length - 1}>
                <li>/</li>
              </Show>
            </>
          )}
        </For>
      </Show>
    </ul>
  );
}

function LoadingCrumbs() {
  return (
    <>
      <li>
        <Skeleton width={50} />
      </li>
      <li>/</li>
      <li>
        <Skeleton width={50} />
      </li>
    </>
  );
}
