import { useNavigate } from '@solidjs/router';
import { ButtonCard, Typography } from '@imagene/components';
import AddSlideCardIcon from '@imagene/components/icons/AddSlideCardIcon';
import ResultsCardIcon from '@imagene/components/icons/ResultsCardIcon';
import { Transition } from 'solid-transition-group';
import { AvgTime } from './avg-time';

import classes from './home.module.css';
import { TermsOfServiceModal } from './TermsOfServiceModal';
import { RunsLimitRange } from '../../components/RunLimitRange/RunsLimitRange';
import { useQuotaLimit } from '../../utils/use-quota-limit';

export function Home() {
  const navigate = useNavigate();
  const handleAddSlideClick = useQuotaLimit();

  return (
    <Transition appear name="fade">
      <div class={classes.container}>
        <h1>Welcome to ImageneOI</h1>
        <RunsLimitRange />
        <section class={classes['cards-row']}>
          <ButtonCard id="add_slide" onClick={handleAddSlideClick}>
            <AddSlideCardIcon />
            <Typography component="h2" weight="semi-bold">
              Add Slides
            </Typography>
          </ButtonCard>
          <ButtonCard id="view_results" onClick={() => navigate('assays')}>
            <ResultsCardIcon />
            <Typography component="h2" weight="semi-bold">
              View Results
            </Typography>
          </ButtonCard>
        </section>
        <section>
          <AvgTime />
        </section>
        <TermsOfServiceModal />
      </div>
    </Transition>
  );
}
