import { createColumnHelper } from '@tanstack/solid-table';
import {
  PageCardNavigation,
  PageCardNavigationEvents,
} from '../../../components/PageCardNavigation';
import { SimpleTable, IconButton, Typography } from '@imagene/components';
import XIcon from '@imagene/components/icons/XIcon';

import classes from '../analysis.module.css';
import { RunsLimitRangeCompact } from '../../../components/RunLimitRange/RunsLimitRangeCompact';
import { useAnalysisCreation } from '../use-create-analysis';

export function FileManager(props: Required<PageCardNavigationEvents>) {
  const [details, setDetails] = useAnalysisCreation();

  function onClear() {
    setDetails('files', []);
    props.onBack();
  }

  function onDelete(index: number) {
    setDetails('files', (prev) => prev.filter((_, i) => i !== index));
    if (!details.files.length) props.onBack();
  }

  const columnHelper = createColumnHelper<File>();

  return (
    <PageCardNavigation
      title="Add slides"
      description="Review selected files"
      onNext={props.onNext}
      onBack={onClear}
      backLabel="Remove All Slides"
      valid
      footerPadding
      subfooter={
        <div class={classes['runs-limit']}>
          <RunsLimitRangeCompact />
        </div>
      }
    >
      <SimpleTable
        class={classes['files-table']}
        columns={[
          columnHelper.display({
            id: 'actions',
            size: 150,
            cell: (props) => (
              <RowIdentifier
                index={props.row.index}
                onDelete={() => onDelete(props.row.index)}
              />
            ),
          }),
          columnHelper.accessor('name', { header: 'Name' }),
        ]}
        data={details.files}
        onDelete={onDelete}
      />
    </PageCardNavigation>
  );
}

function RowIdentifier(props: { index: number; onDelete: VoidFunction }) {
  return (
    <div class={classes.identifier}>
      <Typography>{props.index + 1}</Typography>
      <IconButton onClick={props.onDelete} shape="circle" color="secondary">
        <XIcon />
      </IconButton>
    </div>
  );
}
