import { createSignal } from 'solid-js';
import { isAxiosError } from 'axios';
import { LoginFlow, RecoveryFlow, SettingsFlow } from '@ory/client';
import { useSearchParams } from '@solidjs/router';
import { useToast } from '@imagene/components';
import { createMutation, createQuery } from '@tanstack/solid-query';
import { useAuthErrorHandler } from './authClient';
import { getUiNode } from './auth';

type UseFlowGetter<F> = (flowId?: string) => Promise<F>;
type UseFlowSubmitter<D, R> = (flowId: string, data: D) => Promise<R>;
type OnSuccess<R> = (data: R) => void;
type OnError = (
  error: unknown,
  flowId: undefined | string
) => boolean | undefined;

interface UseFlowOptions<F, D, R> {
  pageName: string;
  getter: UseFlowGetter<F>;
  submitter: UseFlowSubmitter<D, R>;
  onSuccess: OnSuccess<R>;
  onError?: OnError;
}

export function useFlow<
  F extends LoginFlow | SettingsFlow | RecoveryFlow,
  D,
  R
>(options: UseFlowOptions<F, D, R>) {
  const [searchParams, setSearchParams] = useSearchParams();
  const [flow, setFlow] = createSignal<F>();
  const errorHandler = useAuthErrorHandler<F>();
  const { toast } = useToast();

  createQuery(
    () => [options.pageName, searchParams.flow],
    ({ queryKey }) => options.getter(queryKey[1]),
    {
      onSuccess: (result) => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        setFlow(result as any);
        setSearchParams({ flow: result.id });
      },
      onError: async (e) => {
        if (!options.onError) {
          await errorHandler(e);
          return;
        }

        if (!options.onError(e, searchParams.flow)) {
          await errorHandler(e);
        }
      },
      retry: (_, error) => {
        // do not retry if error status code is in 400-500 range
        return (
          isAxiosError(error) && !!error.response && error.response.status > 499
        );
      },
    }
  );

  const submit = createMutation(
    (data: D) => {
      const currentFlow = flow();
      if (!currentFlow) throw new Error('flow is not initiated');
      return options.submitter(currentFlow.id, data);
    },
    {
      onSuccess: options.onSuccess,
      onError: async (e) => {
        const { badRequestFlow } = await errorHandler(e);
        if (badRequestFlow) {
          const passwordNode = getUiNode(badRequestFlow.ui.nodes, 'password');
          const passwordError = passwordNode?.messages?.find(
            (m) => m.id === 4000005 || m.id === 4000031
          );
          if (passwordError) {
            toast({
              title: 'Invalid password',
              description: passwordError.text,
              type: 'error',
            });
          }
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          setFlow(badRequestFlow as any);
        }
      },
    }
  );

  return {
    data: flow,
    setFlow,
    submit: submit.mutate,
  };
}
