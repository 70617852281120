import { SetStoreFunction, createStore } from 'solid-js/store';
import { ParentProps, createContext, useContext } from 'solid-js';

import { UploadAnalysisDetails } from './types';

type AnalysisCreationContext = [
  UploadAnalysisDetails,
  SetStoreFunction<UploadAnalysisDetails>
];

const AnalysisCreateContext = createContext<AnalysisCreationContext>(
  [] as unknown as AnalysisCreationContext
);

export const useAnalysisCreation = () => useContext(AnalysisCreateContext);

export function AnalysisDetailsProvider(props: ParentProps) {
  const store = createStore<UploadAnalysisDetails>({
    accession: '',
    file: {
      name: '',
      size: 0,
      format: 'UNKNOWN',
    },
    slide_id: '',
    wsi_id: '',
    panel: {
      id: '',
      biomarkers: [],
      label: '',
    },
    dataset_id: '',
    files: [],
    datasetName: '',
    images: [],
  });

  return (
    <AnalysisCreateContext.Provider value={store}>
      {props.children}
    </AnalysisCreateContext.Provider>
  );
}
