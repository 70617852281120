export function CircleIcon(props: {
  ariaLabel?: string;
  class?: string;
  classList?: {
    [k: string]: boolean | undefined;
  };
}) {
  return (
    <svg
      aria-label={`${props.ariaLabel}_ongoing`}
      class={props.class}
      classList={props.classList}
      width="20"
      height="20"
      viewBox="0 0 200 200"
      color="#3f51b5"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <defs>
        <linearGradient id="spinner-secondHalf">
          <stop offset="0%" stop-opacity="0" stop-color="currentColor" />
          <stop offset="100%" stop-opacity="0.5" stop-color="currentColor" />
        </linearGradient>
        <linearGradient id="spinner-firstHalf">
          <stop offset="0%" stop-opacity="1" stop-color="currentColor" />
          <stop offset="100%" stop-opacity="0.5" stop-color="currentColor" />
        </linearGradient>
      </defs>

      <g stroke-width="8">
        <path
          stroke="url(#spinner-secondHalf)"
          d="M 4 100 A 96 96 0 0 1 196 100"
        />
        <path
          stroke="url(#spinner-firstHalf)"
          d="M 196 100 A 96 96 0 0 1 4 100"
        />

        <path
          stroke="currentColor"
          stroke-linecap="round"
          d="M 4 100 A 96 96 0 0 1 4 98"
        />
      </g>
    </svg>
  );
}
