export default function ResultsCardIcon() {
  return (
    <svg
      width="126"
      height="97"
      viewBox="0 0 126 97"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="1.5"
        y="2.36621"
        width="123"
        height="92.2769"
        rx="6.5"
        fill="#F4F2F8"
        stroke="#6745B1"
        stroke-width="3"
      />
      <line
        x1="24.5"
        y1="29.0078"
        x2="50.5"
        y2="29.0078"
        stroke="#6745B1"
        stroke-width="3"
        stroke-linecap="round"
      />
      <line
        x1="59.5"
        y1="29.0078"
        x2="71.5"
        y2="29.0078"
        stroke="#6745B1"
        stroke-width="3"
        stroke-linecap="round"
      />
      <line
        x1="79.5"
        y1="29.0078"
        x2="91.5"
        y2="29.0078"
        stroke="#6745B1"
        stroke-width="3"
        stroke-linecap="round"
      />
      <line
        x1="98.5"
        y1="29.0078"
        x2="102.5"
        y2="29.0078"
        stroke="#6745B1"
        stroke-width="3"
        stroke-linecap="round"
      />
      <line
        x1="24.5"
        y1="40.6523"
        x2="50.5"
        y2="40.6523"
        stroke="#6745B1"
        stroke-width="3"
        stroke-linecap="round"
      />
      <line
        x1="59.5"
        y1="40.6523"
        x2="71.5"
        y2="40.6523"
        stroke="#6745B1"
        stroke-width="3"
        stroke-linecap="round"
      />
      <line
        x1="79.5"
        y1="40.6523"
        x2="91.5"
        y2="40.6523"
        stroke="#6745B1"
        stroke-width="3"
        stroke-linecap="round"
      />
      <line
        x1="98.5"
        y1="40.6523"
        x2="102.5"
        y2="40.6523"
        stroke="#6745B1"
        stroke-width="3"
        stroke-linecap="round"
      />
      <line
        x1="24.5"
        y1="52.2979"
        x2="50.5"
        y2="52.2979"
        stroke="#6745B1"
        stroke-width="3"
        stroke-linecap="round"
      />
      <line
        x1="59.5"
        y1="52.2979"
        x2="71.5"
        y2="52.2979"
        stroke="#6745B1"
        stroke-width="3"
        stroke-linecap="round"
      />
      <line
        x1="79.5"
        y1="52.2979"
        x2="91.5"
        y2="52.2979"
        stroke="#6745B1"
        stroke-width="3"
        stroke-linecap="round"
      />
      <line
        x1="98.5"
        y1="52.2979"
        x2="102.5"
        y2="52.2979"
        stroke="#6745B1"
        stroke-width="3"
        stroke-linecap="round"
      />
      <line
        x1="24.5"
        y1="63.9424"
        x2="50.5"
        y2="63.9424"
        stroke="#6745B1"
        stroke-width="3"
        stroke-linecap="round"
      />
      <line
        x1="59.5"
        y1="63.9424"
        x2="71.5"
        y2="63.9424"
        stroke="#6745B1"
        stroke-width="3"
        stroke-linecap="round"
      />
      <line
        x1="79.5"
        y1="63.9424"
        x2="91.5"
        y2="63.9424"
        stroke="#6745B1"
        stroke-width="3"
        stroke-linecap="round"
      />
      <line
        x1="98.5"
        y1="63.9424"
        x2="102.5"
        y2="63.9424"
        stroke="#6745B1"
        stroke-width="3"
        stroke-linecap="round"
      />
    </svg>
  );
}
