import { createMemo } from 'solid-js';
import { useTenant } from '../../utils/use-tenant';
import { KPI, PerformanceBarChart } from './PerformanceBarChart';
import { BatchViewSwitch } from './BatchViewSwitch';
import { SlideGenePerformance, useValidation } from './use-validation';
import { useBiomarkerQuery } from './use-biomarker-query';
import { useThresholds } from './use-thresholds';
import { AccuracyType } from '@imagene/biomarker-results';

import EGFR_EXPECTED from './egfr_expected_performance.json';
import ALK_EXPECTED from './alk_expected_performance.json';
import classes from './validation.module.css';

function thresholdToExpectedKey(thresholds: number[]) {
  return `${thresholds[0] / 100}#${
    thresholds[1] / 100
  }` as keyof typeof EGFR_EXPECTED;
}

export function PerformanceGraph() {
  const tenant = useTenant();
  const validation = useValidation();
  const bioQuery = useBiomarkerQuery();
  const thresholds = useThresholds();

  function sumAccuracy(type: AccuracyType) {
    return (prev: number, curr: SlideGenePerformance) =>
      curr.accuracy === type ? prev + 1 : prev;
  }

  function getExpectedPerformance() {
    return bioQuery.selectedBiomarker() === 'egfr'
      ? EGFR_EXPECTED[thresholdToExpectedKey(thresholds.current())]
      : ALK_EXPECTED[thresholdToExpectedKey(thresholds.current())];
  }

  const kpi = (kpiName: AccuracyType) =>
    validation
      .slides()
      .filter((s) => !s.excluded)
      .reduce(sumAccuracy(kpiName), 0);

  const tn = () => (validation.isEmpty() ? 26 : kpi('TN'));

  const tp = () => (validation.isEmpty() ? 3 : kpi('TP'));

  const fn = () => (validation.isEmpty() ? 1 : kpi('FN'));

  const fp = () => (validation.isEmpty() ? 1 : kpi('FP'));

  const actualPPV = () => Math.round((tp() / (tp() + fp())) * 100);
  const expectedPPV = () => getExpectedPerformance().ppv * 100;
  const ppv = createMemo(() => ({
    expected: expectedPPV(),
    actual: actualPPV(),
  }));

  const actualNPV = () => Math.round((tn() / (tn() + fn())) * 100);
  const expectedNPV = () => getExpectedPerformance().npv * 100;
  const npv = createMemo(() => ({
    expected: expectedNPV(),
    actual: actualNPV(),
  }));

  const actualCohort = () =>
    validation.isEmpty()
      ? 50
      : Math.round(
          ((validation.positives().filter((s) => !s.excluded).length +
            validation.negatives().filter((s) => !s.excluded).length) /
            validation.slides().filter((s) => !s.excluded).length) *
            100
        );
  const expectedCohort = () => getExpectedPerformance().cohort * 100;
  const cohort = createMemo(() => ({
    expected: expectedCohort(),
    actual: actualCohort(),
  }));

  const actualSensitivity = () =>
    validation.isEmpty() ? 66 : Math.round((tp() / (tp() + fn())) * 100);
  const expectedSensitivity = () => getExpectedPerformance().sensitivity * 100;
  const sensitivity = createMemo<KPI>(() => ({
    expected: expectedSensitivity(),
    actual: actualSensitivity(),
  }));

  const actualSpecificity = () =>
    validation.isEmpty() ? 66 : Math.round((tn() / (tn() + fp())) * 100);
  const expectedSpecificity = () => getExpectedPerformance().specificity * 100;
  const specificity = createMemo<KPI>(() => ({
    expected: expectedSpecificity(),
    actual: actualSpecificity(),
  }));

  return (
    <div class={classes.graph}>
      <p>
        Click on "View AI Results" to see likelihood of a Positive result per
        slide
      </p>
      <BatchViewSwitch />
      <div classList={{ [classes.overlay]: validation.isEmpty() }}>
        <div class={classes.performance}>
          <span data-indicator="tn">TN: {tn()}</span>
          <span data-indicator="fn">FN: {fn()}</span>
          <span data-indicator="tp">TP: {tp()}</span>
          <span data-indicator="fn">FP: {fp()}</span>
        </div>
        <p class={classes['no-results']}>
          Missing molecular results: {validation.numNoMolResults() ?? 0}
        </p>
        <PerformanceBarChart
          tenantName={tenant().name}
          npv={npv()}
          ppv={ppv()}
          cohort={cohort()}
          sensitivity={sensitivity()}
          specificity={specificity()}
        />
      </div>
    </div>
  );
}
