import { SettingsFlow, UpdateSettingsFlowBody } from '@ory/client';
import { Typography, useToast } from '@imagene/components';
import { Show } from 'solid-js';
import { authClient } from '../../utils/authClient';
import { useFlow } from '../../utils/use-flow';
import { ChangePasswordForm } from './ChangePasswordForm';
import classes from './account-details.module.css';
import { ChangeDetailsForm } from './ChangeDetailsForm';
import queryClient from '../../utils/queryClient';
import { Transition } from 'solid-transition-group';

export function AccountDetails() {
  const { toast } = useToast();

  const flow = useFlow<SettingsFlow, UpdateSettingsFlowBody, SettingsFlow>({
    pageName: 'account-details',
    getter: (flowId) => {
      return flowId
        ? authClient.getSettingsFlow({ id: flowId }).then((res) => res.data)
        : authClient.createBrowserSettingsFlow().then((res) => res.data);
    },
    submitter: (flowId, data: UpdateSettingsFlowBody) =>
      authClient
        .updateSettingsFlow({
          flow: flowId,
          updateSettingsFlowBody: data,
        })
        .then((res) => {
          return res.data;
        }),

    onSuccess: () => {
      toast({ title: 'Updated successfully', dismissAfter: 5000 });
      queryClient.invalidateQueries({ queryKey: ['whoami'] });
    },
    onError: (e, flowId) => {
      toast({
        title: `Failed to update ${flowId}`,
        description: String(e),
        type: 'error',
        dismissAfter: 5000,
      });
      return false;
    },
  });

  return (
    <Show when={flow.data()}>
      {(f) => (
        <Transition appear name="fade">
          <div class="page-margin">
            <Typography class={classes.title} weight="bold">
              My Account
            </Typography>
            <ChangePasswordForm flow={f()} onSubmit={flow.submit} />
            <ChangeDetailsForm flow={f()} onSubmit={flow.submit} />
          </div>
        </Transition>
      )}
    </Show>
  );
}
