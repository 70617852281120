import { ParentProps, JSX, Show } from 'solid-js';
import classes from './context-menu.module.css';

export interface MenuItemProps {
  text: string;
  header?: boolean;
  bottom?: boolean;
  onClick?: VoidFunction;
  icon?: JSX.Element;
}

export function ContextMenuItem(props: ParentProps<MenuItemProps>) {
  return (
    <li
      classList={{
        [classes.item]: true,
        [classes.header]: props.header,
        [classes.bottom]: props.bottom,
      }}
      onClick={() => props.onClick && props.onClick()}
    >
      <Show when={props.icon}>
        <div class={classes.icon}>{props.icon}</div>
      </Show>
      <div class={classes.text}>{props.text}</div>
    </li>
  );
}
