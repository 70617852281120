import { Show } from 'solid-js';
import { isAxiosError } from 'axios';
import { UpdateSettingsFlowWithPasswordMethod } from '@ory/client';
import { useNavigate } from '@solidjs/router';
import { authClient } from '../../utils/authClient';
import { useFlow } from '../../utils/use-flow';
import { ResetPasswordForm } from './ResetPasswordForm';

export function ResetPassword() {
  const navigate = useNavigate();

  const flow = useFlow({
    pageName: 'reset-password',
    getter: (flowId) => {
      return flowId
        ? authClient.getSettingsFlow({ id: flowId }).then((res) => res.data)
        : authClient.createBrowserSettingsFlow().then((res) => res.data);
    },
    submitter: (flowId, data: UpdateSettingsFlowWithPasswordMethod) =>
      authClient
        .updateSettingsFlow({
          flow: flowId,
          updateSettingsFlowBody: {
            ...data,
            method: 'password',
          },
        })
        .then((res) => res.data),
    onSuccess: () => navigate('/'),
    onError: (e, flowId) => {
      if (isAxiosError(e)) {
        if (e.response?.status === 403) {
          navigate(
            `/login/2fa?return_to=/reset-password${
              flowId ? `?flow=${flowId}` : ''
            }`,
            { replace: true }
          );
          return true;
        }
      }
      return false;
    },
  });

  return (
    <Show when={flow.data()}>
      {(f) => <ResetPasswordForm flow={f()} onSubmit={flow.submit} />}
    </Show>
  );
}
