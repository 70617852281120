export function PlusIcon() {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="11" cy="11" r="10" stroke="currentColor" stroke-width="2" />
      <circle
        cx="11"
        cy="11"
        r="10"
        stroke="black"
        stroke-opacity="0.2"
        stroke-width="2"
      />
      <path
        d="M6 11C6 11 8.13317 11 9.5 11C12.4289 11 16 11 16 11"
        stroke="currentColor"
        stroke-width="2"
        stroke-linecap="round"
      />
      <path
        d="M6 11C6 11 8.13317 11 9.5 11C12.4289 11 16 11 16 11"
        stroke="currentColor"
        stroke-opacity="0.2"
        stroke-width="2"
        stroke-linecap="round"
      />
      <path
        d="M11 16C11 16 11 13.8668 11 12.5C11 9.57107 11 6 11 6"
        stroke="currentColor"
        stroke-width="2"
        stroke-linecap="round"
      />
      <path
        d="M11 16C11 16 11 13.8668 11 12.5C11 9.57107 11 6 11 6"
        stroke="black"
        stroke-opacity="0.2"
        stroke-width="2"
        stroke-linecap="round"
      />
    </svg>
  );
}
