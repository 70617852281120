export function CircleFillIcon(props: {
  ariaLabel?: string;
  class?: string;
  classList?: {
    [k: string]: boolean | undefined;
  };
}) {
  return (
    <svg
      aria-label={`${props.ariaLabel}_circle_fill`}
      class={props.class}
      classList={props.classList}
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="11"
        cy="11"
        r="10"
        fill="white"
        stroke="#A0A5AC"
        stroke-width="2"
      />
    </svg>
  );
}
