import { For, Show, createEffect, createSignal } from 'solid-js';
import { Accordion, RangeSlider, Typography } from '@imagene/components';
import AddSlideCardIcon from '@imagene/components/icons/AddSlideCardIcon';
import { Transition } from 'solid-transition-group';
import {
  SlideStatisticsItem,
  SlideStatisticsItemProps,
} from './SlideStatisticsItem';
import { SlideGenePerformance, useValidation } from './use-validation';
import { useThresholds } from './use-thresholds';

import classes from './validation.module.css';
import { useQuotaLimit } from '../../utils/use-quota-limit';

export function ValidationStatistics() {
  const validation = useValidation();
  const thresholds = useThresholds();
  const [accordionState, setAccordionState] = createSignal<string[]>([
    'positive',
    'negative',
  ]);

  const min = () => validation.noDataThresholds()[0];
  const max = () => validation.noDataThresholds()[1];

  createEffect(() => {
    const [first, ...reset] = accordionState();
    if (validation.isEmpty() && (reset.length > 0 || first !== 'negative')) {
      setAccordionState(['negative']);
    }
  });

  function onThresholdsChange(newThresholds: number[]) {
    const [low, high] = newThresholds;
    const newThresholdsCopy = [...newThresholds];
    if (low < min()) {
      newThresholdsCopy[0] = min();
    }
    if (high > max()) {
      newThresholdsCopy[1] = max();
    }
    thresholds.setThresholds(newThresholdsCopy);
  }

  return (
    <Transition name="fade" appear>
      <div class={classes.statistics}>
        <div class={classes.likelihood}>
          <h2>Likelihood of a Positive Result</h2>
          <p>Adjust Positive and Negative thresholds by dragging the sliders</p>
          <div class={classes.range}>
            <span>Negative</span>
            <RangeSlider
              name="thresholds"
              min={min()}
              max={max()}
              inverse
              value={thresholds.current()}
              onValueChange={({ value }) => onThresholdsChange(value)}
            />
            <span>Positive</span>
          </div>
        </div>
        <Accordion
          multiple
          sticky
          collapsible
          value={accordionState()}
          onValueChange={(e) => setAccordionState(e.value)}
          disabled={validation.isEmpty()}
          items={[
            {
              id: 'positive',
              title: `AI Positive (${validation.positives().length})`,
              content: (
                <SlideStatistics
                  color="primary"
                  items={validation.positives()}
                />
              ),
              color: validation.isEmpty() ? 'default' : 'primary',
            },
            {
              id: 'equivocal',
              title: `AI Equivocal (${validation.equivocal().length})`,
              content: (
                <SlideStatistics
                  color="default"
                  items={validation.equivocal()}
                />
              ),
            },
            {
              id: 'negative',
              title: `AI Negative (${validation.negatives().length})`,
              content: (
                <Show
                  when={!validation.isEmpty()}
                  fallback={NoSlidesAvailable()}
                >
                  <SlideStatistics
                    color="secondary"
                    items={validation.negatives()}
                  />
                </Show>
              ),
              color: validation.isEmpty() ? 'default' : 'secondary',
            },
          ]}
        ></Accordion>
      </div>
    </Transition>
  );
}

type SlideStatisticsProps = Pick<SlideStatisticsItemProps, 'color'> & {
  items: SlideGenePerformance[];
};

function SlideStatistics(props: SlideStatisticsProps) {
  return (
    <ul>
      <For each={props.items.sort((a, b) => b.value - a.value)}>
        {(item) => (
          <SlideStatisticsItem
            color={props.color}
            name={item.name}
            value={item.value}
            displayValue={item.label}
            accuracy={item.accuracy}
            excluded={item.excluded}
          />
        )}
      </For>
    </ul>
  );
}

function NoSlidesAvailable() {
  const quota = useQuotaLimit();
  return (
    <div class={classes.empty}>
      <p>
        Please add slides to receive AI results and proceed to panel performance
        evaluation.
      </p>
      <p>
        It is recommended to add a minimum of 30 slides, including 10 Positives
        for each biomarker.
      </p>
      <button class={classes.add_slide} onClick={quota}>
        <AddSlideCardIcon />
        <Typography component="h2" weight="semi-bold">
          Add Slides
        </Typography>
      </button>
    </div>
  );
}
