import { Show, createSignal } from 'solid-js';
import AboutIcon from '@imagene/components/icons/AboutIcon';
import AddSlideIcon from '@imagene/components/icons/AddSlideIcon';
import LogoIcon from '@imagene/components/icons/LogoIcon';
import ResultsIcon from '@imagene/components/icons/ResultsIcon';
import UserGuideIcon from '@imagene/components/icons/UserGuideIcon';
import BatchIcon from '@imagene/components/icons/BatchIcon';
import GraphIcon from '@imagene/components/icons/Graph';
import { SideBarItem } from './SidebarItem';
import { UserProfileMenu } from './UserProfileMenu';
import { useTenant } from '../../../utils/use-tenant';
import { useUpload } from '../../../utils/use-upload';
import { AbortModal } from './AbortModal';
import { useQuotaLimit } from '../../../utils/use-quota-limit';

import classes from './sidebar.module.css';

export function Sidebar() {
  const tenant = useTenant();
  const { isUploading } = useUpload();
  const [showModal, setShowModal] = createSignal(false);

  const handleAddSlideClick = useQuotaLimit();

  const onDisabledClick = () => {
    if (isUploading()) {
      setShowModal(true);
    }
  };

  return (
    <aside class={classes.container}>
      <ul class={classes.items}>
        <SideBarItem
          icon={<LogoIcon />}
          text="Home"
          classList={{
            [classes.static]: true,
            [classes.logo]: true,
          }}
          route="/"
          id="sidebar_home"
          disabled={isUploading()}
          onDisabledClick={onDisabledClick}
          visible
        />
        <div class={classes['action-items']}>
          <SideBarItem
            id="sidebar_add_slide"
            icon={<AddSlideIcon />}
            text="Add Slides"
            route="/analysis"
            disabled={isUploading()}
            onDisabledClick={onDisabledClick}
            visible
            onClick={handleAddSlideClick}
          />
          <SideBarItem
            id="sidebar_results"
            icon={<ResultsIcon />}
            text="Results"
            route="/assays"
            disabled={isUploading()}
            onDisabledClick={onDisabledClick}
            visible
          />
          <SideBarItem
            id="sidebar_batches"
            icon={<BatchIcon />}
            text="Batches"
            route="/batches"
            visible={tenant().batch_enabled}
            disabled={isUploading()}
            onDisabledClick={onDisabledClick}
          />
          <SideBarItem
            id="sidebar_validation"
            icon={<GraphIcon />}
            text="Validation"
            route="/validation"
            disabled={isUploading()}
            onDisabledClick={onDisabledClick}
            visible={tenant().validation_enabled}
          />
        </div>
        <div class={classes['info-items']}>
          <SideBarItem
            id="sidebar_about"
            icon={<AboutIcon />}
            text="About"
            route="/about"
            disabled={isUploading()}
            onDisabledClick={onDisabledClick}
            visible
          />
          <SideBarItem
            id="sidebar_user_guide"
            icon={<UserGuideIcon />}
            text="User Guide"
            route="https://drive.google.com/file/d/1MHuhK5t6VRv1wotIpX6Z7CK2Iofakpy4/view?usp=sharing"
            target="_blank"
            disabled={isUploading()}
            onDisabledClick={onDisabledClick}
            visible
          />
        </div>
        <UserProfileMenu
          disabled={isUploading()}
          onDisabledClick={onDisabledClick}
        />
        <Show when={isUploading()}>
          <AbortModal open={showModal()} onClose={() => setShowModal(false)} />
        </Show>
      </ul>
    </aside>
  );
}
