import {
  flexRender,
  getCoreRowModel,
  createSolidTable,
  PaginationState,
} from '@tanstack/solid-table';
import type { Row, Header, ColumnDef } from '@tanstack/solid-table';
import { For, JSX, ParentProps, Show, Suspense } from 'solid-js';
import { Typography } from '../Typography';
import classes from './table.module.css';
import { Link } from '../inputs/Link';
import { Skeleton } from '../Skeleton';
export { usePagination } from './use-pagination';

interface ColumnMetadata {
  headerPadStart?: boolean;
  headerPadEnd?: boolean;
  headerAlignStart?: boolean;
  headerAlignEnd?: boolean;
  cellWithBg?: boolean;
}

interface TableProps<T> {
  data: T[];
  onPageChange?: (newState: PaginationState) => void;
  pageState?: PaginationState;
  pageCount?: number;
  columns: ColumnDef<T, unknown>[];
  //each table looks different so it should provide its own skeleton
  TableSkeleton?: JSX.Element;
  //each table may want different no results message, so it should provide its own
  noResultsMsg?: string;
  //each table has different layout, so tableRowClass is required to set the grid columns template
  tableRowClass?: string;
}

export function Table<TData>(props: TableProps<TData>) {
  const canGoNextPage = () => {
    if (
      typeof props.pageState === 'undefined' ||
      typeof props.pageCount === 'undefined'
    )
      return;
    if (props.pageCount === 0) {
      return false;
    }
    return props.pageState.pageIndex < props.pageCount;
  };

  const canGoPrevPage = () => {
    if (typeof props.pageState === 'undefined') return;
    return props.pageState.pageIndex > 1;
  };

  const handleNextPage = () => {
    if (typeof props.pageState === 'undefined') return;
    props.onPageChange?.({
      ...props.pageState,
      pageIndex: props.pageState.pageIndex + 1,
    });
  };

  const handlePrevPage = () => {
    if (typeof props.pageState === 'undefined') return;
    props.onPageChange?.({
      ...props.pageState,
      pageIndex: props.pageState.pageIndex - 1,
    });
  };

  const table = createSolidTable({
    get data() {
      return props.data;
    },
    get columns() {
      return props.columns;
    },
    getCoreRowModel: getCoreRowModel(),
    pageCount: props.pageCount,
    initialState: {
      pagination: props.pageState,
    },
    manualPagination: true,
  });

  return (
    <section class={classes['table']}>
      <div
        classList={{
          [classes['header-row']]: true,
          [props.tableRowClass ?? '']: true,
        }}
      >
        <For each={table.getHeaderGroups()}>
          {(headerGroup) => (
            <For each={headerGroup.headers}>
              {(header) => <Header header={header} />}
            </For>
          )}
        </For>
      </div>
      <div
        class={classes['table-rows-wrapper']}
        classList={{ [classes.scrollable]: props.data.length > 7 }}
      >
        <Suspense fallback={props.TableSkeleton}>
          <Show
            when={props.data.length}
            fallback={NoResults(props.noResultsMsg ?? 'No data')}
          >
            <For each={table.getRowModel().rows}>
              {(row, idx) => (
                <Row
                  id={`table_row_${idx()}`}
                  row={row}
                  tableRowClass={props.tableRowClass ?? ''}
                />
              )}
            </For>
          </Show>
        </Suspense>
      </div>
      <Show when={props.pageCount}>
        <div class={classes['table-footer']}>
          <Suspense fallback={PaginationSkeleton()}>
            <div class={classes['paging-text']}>
              {!props.data.length ? '0' : props.pageState?.pageIndex}
              &nbsp;of {props.pageCount}
              &nbsp;pages
            </div>
            <Link
              class={classes.link}
              onClick={() => handlePrevPage()}
              disabled={!canGoPrevPage()}
            >
              Prev
            </Link>
            <Link
              class={classes.link}
              onClick={() => handleNextPage()}
              disabled={!canGoNextPage()}
            >
              Next
            </Link>
          </Suspense>
        </div>
      </Show>
    </section>
  );
}

function PaginationSkeleton() {
  return (
    <>
      <Skeleton variant="rectangle" width={80} />
      <Skeleton variant="rectangle" width={30} />
      <Skeleton variant="rectangle" width={30} />
    </>
  );
}

function Row<TData>(
  props: ParentProps<{
    id: string;
    row: Row<TData>;
    tableRowClass: string;
  }>
) {
  return (
    <div
      id={props.id}
      classList={{
        [props.tableRowClass]: true,
        [classes['table-row']]: true,
      }}
    >
      <For each={props.row.getVisibleCells()}>
        {(cell) => {
          const withBg = Boolean(
            (cell.column.columnDef.meta as ColumnMetadata)?.cellWithBg
          );
          return (
            <div
              classList={{
                [classes.cell]: true,
                [classes.bg]: withBg,
              }}
            >
              {flexRender(cell.column.columnDef.cell, cell.getContext())}
            </div>
          );
        }}
      </For>
    </div>
  );
}

function Header<TData>(props: ParentProps<{ header: Header<TData, unknown> }>) {
  const alignStart = Boolean(
    (props.header.column.columnDef.meta as ColumnMetadata)?.headerAlignStart
  );
  const padStart = Boolean(
    (props.header.column.columnDef.meta as ColumnMetadata)?.headerPadStart
  );

  const alignEnd = Boolean(
    (props.header.column.columnDef.meta as ColumnMetadata)?.headerAlignEnd
  );

  const padEnd = Boolean(
    (props.header.column.columnDef.meta as ColumnMetadata)?.headerPadEnd
  );

  return (
    <Typography
      id={props.header.id}
      weight="bold"
      classList={{
        [classes.header]: true,
        [classes['justify-start']]: alignStart,
        [classes['padding-start']]: padStart,
        [classes['justify-end']]: alignEnd,
        [classes['padding-end']]: padEnd,
      }}
      component="div"
    >
      {flexRender(
        props.header.column.columnDef.header,
        props.header.getContext()
      )}
    </Typography>
  );
}

function NoResults(msg: string) {
  return (
    <div class={classes['no-results']} aria-label="table_no_results_msg">
      {msg}
    </div>
  );
}
