import { JSX, Show } from 'solid-js';
import { Spinner, Typography } from '..';
import XIcon from '../icons/XIcon';

import classes from './tag.module.css';

export type TagProps = JSX.HTMLAttributes<HTMLDivElement> & {
  value: string;
  clearable?: boolean;
  loading?: boolean;
};

export function Tag(props: TagProps) {
  return (
    <div
      onClick={props.onClick}
      classList={{ [classes.tag]: true, [classes.clearable]: props.clearable }}
      title={props.value}
    >
      <Show when={!props.loading} fallback={<Spinner />}>
        <Typography component="span" class={classes.text}>
          {props.value}
        </Typography>
      </Show>
      <Show when={props.clearable}>
        <XIcon />
      </Show>
    </div>
  );
}
