export default function AddSlideCardIcon() {
  return (
    <svg
      width="126"
      height="97"
      viewBox="0 0 126 97"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="18.5"
        y="2.36621"
        width="89"
        height="92.2769"
        rx="6.5"
        fill="#F4F2F8"
        stroke="#6745B1"
        stroke-width="3"
      />
      <line
        x1="62.9878"
        y1="28.832"
        x2="62.9878"
        y2="66.0775"
        stroke="#6745B1"
        stroke-width="3"
        stroke-linecap="round"
      />
      <line
        x1="45.5"
        y1="47.5645"
        x2="80.5165"
        y2="47.5645"
        stroke="#6745B1"
        stroke-width="3"
        stroke-linecap="round"
      />
    </svg>
  );
}
