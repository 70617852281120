import { Typography } from '@imagene/components';
import classes from './hide-small-device.module.css';

export function HideSmallDevice() {
  return (
    <div class={classes['hide-small-device']}>
      <Typography weight="normal" component="div">
        Current screen resolution is not supported. ImageneOI provides the best
        user experience with screen resolution above 1200 x 900
      </Typography>
    </div>
  );
}
