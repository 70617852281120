import { Typography } from '@imagene/components';
import { Range, RangeProps } from '../../../components/Range';

import classes from './assayBiomarkerTable.module.css';

export type BiomarkerResultRangeProps = RangeProps;

export function BiomarkerResultRange(props: BiomarkerResultRangeProps) {
  return (
    <div class={classes.bio_range}>
      <Typography>Negative</Typography>
      <Range {...props} scaleLow />
      <Typography>Positive</Typography>
    </div>
  );
}
