import { JSX, ParentProps } from 'solid-js';
import XIcon from '../../icons/XIcon';

import classes from './close-button.module.css';

export type CloseButtonProps = ParentProps<
  JSX.ButtonHTMLAttributes<HTMLButtonElement>
>;

export function CloseButton(props: CloseButtonProps) {
  return (
    <button {...props} class={classes.btn}>
      <XIcon />
    </button>
  );
}
