import { ParentProps } from 'solid-js';
import { CircleIcon } from '../icons/CircleIcon';

import classes from './spinner.module.css';

interface SpinnerProps {
  center?: boolean;
}

export function Spinner(props: ParentProps<SpinnerProps>) {
  return (
    <CircleIcon
      classList={{ [classes.loading]: true, [classes.center]: props.center }}
    />
  );
}
