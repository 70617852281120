import { SettingsFlow, UiNodeTextAttributes } from '@ory/client';
import { createEffect, ParentProps } from 'solid-js';
import { getUiNode, getUiNodeByType } from '../../utils/auth';
import { AMOUNT_OF_DIGITS, TwoFAData, use2FA } from '../TwoFA/use2FA';

import classes from './enroll-2fa.module.css';
import { QRCode } from './QRCode';
import { Button, CopyButton, Digits, Typography } from '@imagene/components';

interface Enroll2FAProps {
  flow: SettingsFlow;
  onSubmit: (data: TwoFAData) => void;
}

export function Enroll2FAForm(props: ParentProps<Enroll2FAProps>) {
  const { onDigitsComplete, isValid, data } = use2FA(props.flow.ui.nodes);
  const codeTextNode = getUiNodeByType(props.flow.ui.nodes, 'text');
  const codeText = (codeTextNode.attributes as UiNodeTextAttributes).text.text;

  let submit: HTMLButtonElement | null = null;
  createEffect(() => {
    if (submit && isValid()) {
      submit.focus();
    }
  });

  async function onCopy() {
    await window.navigator.clipboard.writeText(codeText);
  }

  return (
    <div class={classes.container}>
      <Typography component="h2" weight="bold">
        2FA enrollment
      </Typography>
      <Step index={1}>Install authenticator app on your mobile phone</Step>
      <Step index={2}>Scan the QR code with your app</Step>
      <QRCode node={getUiNodeByType(props.flow.ui.nodes, 'img')} />
      <Typography component="p">
        If you can’t scan the QR code, enter this code:
      </Typography>
      <CopyButton onClick={onCopy}>{codeText}</CopyButton>
      <Step index={3}>
        Enter the 6 digit code from the app once the scan is complete
      </Step>
      <Digits
        class="align-start"
        amount={AMOUNT_OF_DIGITS}
        onComplete={onDigitsComplete}
        error={getCodeInvalidMessage(props.flow.ui)}
      />
      <Button
        ref={(v) => (submit = v)}
        class="align-start"
        disabled={!isValid()}
        onClick={() => props.onSubmit(data)}
      >
        Submit
      </Button>
    </div>
  );
}

function Step(props: ParentProps<{ index: number }>) {
  return (
    <Typography component="p">
      <Typography class={classes.index} weight="bold">
        {props.index}
      </Typography>
      {props.children}
    </Typography>
  );
}

function getCodeInvalidMessage(ui: SettingsFlow['ui']) {
  const totpNode = getUiNode(ui.nodes, 'totp_code');
  const isInvalidCode = totpNode.messages.some((msg) => msg.id === 4000008);
  return isInvalidCode ? 'Incorrect code' : undefined;
}
