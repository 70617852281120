import {
  Button,
  Checkbox,
  Dialog,
  DialogProps,
  Divider,
  Link,
  Typography,
} from '@imagene/components';
import { TickIcon } from '@imagene/components/icons/TickIcon';

import classes from './inclusion-criteria-modal.module.css';
import { Show, createSignal } from 'solid-js';

export type InclusionCriteriaModalProps = Pick<
  DialogProps,
  'open' | 'onClose'
> & {
  headline: string;
  onDecline: VoidFunction;
  onConfirm: VoidFunction;
};

export function InclusionCriteriaModal(props: InclusionCriteriaModalProps) {
  const [checked, setChecked] = createSignal(false);
  return (
    <Dialog {...props}>
      <form method="dialog" class={classes.confirmation}>
        <Typography weight="bold">{props.headline}</Typography>
        <section>
          <ul>
            <Row key="Primary origin" value="NSCLC" />
            <Row
              key="Tissue type"
              value="Biopsy/Surgical"
              extra="Not FNA or Cytology"
            />
            <Row
              key="Slide type"
              value="FFPE and H&E"
              extra="Not freshly frozen"
            />
          </ul>
          <Divider />
          <div>
            <Checkbox
              containerStyle={{
                'flex-direction': 'row-reverse',
                'justify-content': 'space-between',
              }}
              onChange={(e) => setChecked(e.currentTarget.checked)}
              label="I confirm all criteria"
            />
          </div>
        </section>
        <div>
          <Link onClick={props.onDecline}>Cancel</Link>
          <Button
            variant="confirm"
            onClick={props.onConfirm}
            disabled={!checked()}
          >
            Upload
          </Button>
        </div>
      </form>
    </Dialog>
  );
}

function Row(props: { key: string; value: string; extra?: string }) {
  return (
    <li>
      <Typography>{props.key}:</Typography>
      <Typography weight="bold">{props.value}</Typography>
      <TickIcon />
      <Show when={props.extra}>
        <Typography class={classes.extra}>* {props.extra}</Typography>
      </Show>
    </li>
  );
}
