import {
  For,
  ParentProps,
  createMemo,
  createUniqueId,
  splitProps,
} from 'solid-js';
import { normalizeProps, useMachine } from '@zag-js/solid';
import * as tabs from '@zag-js/tabs';

import classes from './tabs.module.css';

export interface Tab {
  id: string;
  label: string;
  disabled?: boolean;
}

export interface TabsProps {
  tabs: Tab[];
  value?: string;
  centered?: boolean;
  onTabSelect?: (tabId: string) => void;
}

export function Tabs(props: ParentProps<TabsProps>) {
  const [context, local] = splitProps(props, ['value']);
  const [state, send] = useMachine(
    tabs.machine({
      id: createUniqueId(),
      activationMode: 'manual',
      onValueChange: (details) => props.onTabSelect?.(details.value),
    }),
    { context }
  );

  const api = createMemo(() => tabs.connect(state, send, normalizeProps));

  return (
    <div
      {...api().getRootProps()}
      classList={{ [classes.centered]: local.centered }}
    >
      <div {...api().getListProps()}>
        <For each={local.tabs}>
          {(item) => (
            <button
              {...api().getTriggerProps({
                value: item.id,
                disabled: item.disabled,
              })}
            >
              {item.label}
            </button>
          )}
        </For>
      </div>
      {props.children}
    </div>
  );
}
