import { createSignal, ParentProps, Show } from 'solid-js';
import { createStore } from 'solid-js/store';
import { SettingsFlow, UpdateSettingsFlowWithProfileMethod } from '@ory/client';
import {
  AssignedTenantsResponse,
  ChangeTenantDto,
} from '@imagene/api-interfaces';
import {
  Button,
  Input,
  Select,
  Typography,
  useToast,
} from '@imagene/components';
import {
  createMutation,
  createQuery,
  useQueryClient,
} from '@tanstack/solid-query';

import { getUiNodeAttributes } from '../../utils/auth';
import { useAuth } from '../../utils/session';
import { axiosClient } from '../../utils/axios';

import classes from './account-details.module.css';

type ProfileSettingsFlowValues = UpdateSettingsFlowWithProfileMethod & {
  method: 'profile';
};

interface ChangeDetailsFormProps {
  flow: SettingsFlow;
  onSubmit: (values: ProfileSettingsFlowValues) => void;
}

export function ChangeDetailsForm(props: ParentProps<ChangeDetailsFormProps>) {
  let form: HTMLFormElement;
  const { tenantId } = useAuth();
  const { toast } = useToast();
  const queryClient = useQueryClient();
  const csrf = getUiNodeAttributes(props.flow.ui.nodes, 'csrf_token');
  const { email, name } = props.flow.identity.traits;
  const [isValid, setIsValid] = createSignal(true);
  const [formValues, setFormValues] = createStore({
    traits: {
      email,
      name: {
        first: name.first,
        last: name.last,
      },
    },
    csrf_token: csrf.value,
  });

  const tenants = createQuery(
    () => ['tenants'],
    () =>
      axiosClient
        .get<AssignedTenantsResponse>('/api/v1/profile/tenants')
        .then((res) => res.data)
  );

  const changeTenant = createMutation(
    (dto: ChangeTenantDto) => axiosClient.put('/api/v1/profile/tenant', dto),
    {
      onSuccess: (_, dto) => {
        queryClient.invalidateQueries(['whoami']);
        const tenant = tenants.data?.find((t) => t.id === dto.tenant);
        toast({
          title: `Organization changed to ${tenant?.name ?? 'unknown'}`,
          dismissAfter: 3000,
        });
      },
    }
  );

  function validateForm() {
    setIsValid(form.checkValidity());
  }

  function onSubmit(e: SubmitEvent) {
    e.preventDefault();
    props.onSubmit({ ...formValues, method: 'profile' });
  }

  return (
    <form class={classes.card} onSubmit={onSubmit} ref={(r) => (form = r)}>
      <div class={classes['card-header']}>
        <Typography weight="bold">Account Details</Typography>
      </div>
      <div
        classList={{ [classes['card-body']]: true, [classes.details]: true }}
      >
        <Input
          label="First name"
          name="first"
          onInput={(e) => {
            setFormValues('traits', 'name', 'first', e.target.value);
            validateForm();
          }}
          required
          value={name.first}
          minlength={2}
          maxlength={30}
        />
        <Input label="Email" disabled disableOptionalTxt value={email} />

        <Input
          label="Last Name"
          name="last"
          required
          onInput={(e) => {
            setFormValues('traits', 'name', 'last', e.target.value);
            validateForm();
          }}
          value={name.last}
          minlength={2}
          maxlength={30}
        />
        <Show
          when={tenants.data && tenants.data?.length > 1}
          //fallback provided to not break grid styling when not showing select
          fallback={<div aria-label="org-placeholder" />}
        >
          <Select
            label="Selected Organization"
            options={
              tenants.data?.map((t) => ({
                id: t.id,
                label: t.name,
                value: t.id,
              })) ?? []
            }
            onSelect={(option) => changeTenant.mutate({ tenant: option.value })}
            value={tenantId()}
          />
        </Show>
        <Button class={classes.btn} disabled={!isValid()}>
          Save Changes
        </Button>
      </div>
    </form>
  );
}
