/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { useNavigate } from '@solidjs/router';
import { useQueryClient } from '@tanstack/solid-query';
import { Show } from 'solid-js';
import { authClient } from '../../utils/authClient';
import { useFlow } from '../../utils/use-flow';
import { TwoFAData } from '../TwoFA/use2FA';
import { Enroll2FAForm } from './Enroll2FAForm';
import { Spinner } from '@imagene/components';

export function Enroll2FA() {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const flow = useFlow({
    pageName: 'enroll-2fa',
    getter: (flowId) =>
      flowId
        ? authClient.getSettingsFlow({ id: flowId }).then((res) => res.data)
        : authClient.createBrowserSettingsFlow().then((res) => res.data),
    submitter: (flowId, data: TwoFAData) =>
      authClient
        .updateSettingsFlow({
          flow: flowId,
          updateSettingsFlowBody: {
            method: 'totp',
            ...data,
          },
        })
        .then((res) => res.data),
    onSuccess: () => {
      queryClient.invalidateQueries(['whoami']);
      navigate('/', { replace: true });
    },
  });

  return (
    <Show when={flow.data()} fallback={<Spinner center />}>
      {(f) => <Enroll2FAForm flow={f()} onSubmit={flow.submit} />}
    </Show>
  );
}
