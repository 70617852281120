import {
  createEffect,
  createSignal,
  For,
  ParentProps,
  Show,
  Suspense,
} from 'solid-js';
import { ERROR_CODE } from '@imagene/error-codes';
import { sortResults } from '@imagene/biomarker-results';
import { Card, Typography } from '@imagene/components';
import SearchIcon from '@imagene/components/icons/SearchIcon';
import { ExtendedDetailedAnalysisRun } from '@imagene/api-interfaces';
import { SkeletonTable } from '../Skeletons/SkeletonTable';
import { BiomarkerItem } from './BiomarkerItem';

import classes from './assayBiomarkerTable.module.css';

interface AssayBiomarkerTableProps {
  run: ExtendedDetailedAnalysisRun | undefined;
}

export function AssayBiomarkerTable(props: AssayBiomarkerTableProps) {
  const [tableMessage, setTableMessage] = createSignal('');

  const results = () =>
    sortResults(props.run?.results ?? [], props.run?.probability_enabled);

  const showInconclusiveProbability = () =>
    props.run?.inconclusive_probability_enabled ?? false;
  const showProbability = () => props.run?.probability_enabled ?? false;

  createEffect(() => {
    if (props.run && props.run.status) {
      handleStatus(props.run);
    }
  });

  const handleStatus = (run: ExtendedDetailedAnalysisRun) => {
    switch (run.status) {
      case 'IN_PROGRESS':
        setTableMessage(
          'Biomarker analysis results will be ready within minutes'
        );
        break;
      case 'QC_FAILED':
        switch (run.status_code) {
          case ERROR_CODE.CORRUPTED_FILE:
            setTableMessage(
              `Uploaded file is corrupted or has unsupported format.
               Supported formats: .svs,.ndpi,.tiff,.isyntax.`
            );
            break;
          case ERROR_CODE.INFO_QC_LOW_TISSUE:
          case ERROR_CODE.INFO_WSI_LOW_MAGNIFICATION:
          case ERROR_CODE.INFO_WSI_MAGNIFICATION_NA:
          case ERROR_CODE.INFO_TISSUE_STRUCTURE_QC:
            setTableMessage(
              `The examination of biomarkers could not be completed due to inadequate quality of the whole slide image or insufficient tissue content.`
            );
            break;
          default:
            setTableMessage(
              `Biomarker results are not available due to technical issues.
               Try to submit the same WSI file for analysis again.`
            );
            break;
        }
        break;
      case 'ERROR':
        switch (run.status_code) {
          case ERROR_CODE.CORRUPTED_FILE:
            setTableMessage(
              `Uploaded file is corrupted or has unsupported format.
               Supported formats: .svs,.ndpi,.tiff,.isyntax.`
            );
            break;
          default:
            setTableMessage(
              `Biomarkers results are not available due to technical issues. 
               Try to upload the same WSI file for analysis again.`
            );
            break;
        }
        break;
    }
  };

  return (
    <Card>
      <Suspense fallback={<SkeletonTable />}>
        <table class={classes.biomarkersTable}>
          <thead>
            <Show when={props.run}>
              <tr class={classes.tableRow}>
                <Typography
                  component="th"
                  weight="bold"
                  class={classes.biomarkersTableHeader}
                >
                  Biomarker
                </Typography>
                <Typography
                  component="th"
                  weight="bold"
                  class={classes.biomarkersTableHeader}
                >
                  {showProbability()
                    ? 'Likelihood of positive result'
                    : 'Result'}
                </Typography>
              </tr>
            </Show>
          </thead>
          <tbody class={classes.biomarkersTableContent}>
            <Show
              when={results().length}
              fallback={<TableContentMessage message={tableMessage()} />}
            >
              <For each={results()}>
                {(bio) => (
                  <BiomarkerItem
                    result={bio}
                    showProbability={showProbability()}
                    showInconclusiveProbability={showInconclusiveProbability()}
                    capitalize={
                      !showProbability() && !showInconclusiveProbability()
                    }
                    class={classes.tableRow}
                  />
                )}
              </For>
            </Show>
          </tbody>
        </table>
      </Suspense>
    </Card>
  );
}

function TableContentMessage(props: ParentProps<{ message: string }>) {
  return (
    <div class={classes.messageContainer}>
      <SearchIcon class={classes.searchIcon} />
      <Typography component="div" aria-label="biomarkers_table_msg">
        {props.message}
      </Typography>
    </div>
  );
}
