export default function LogoutIcon() {
  return (
    <svg
      width="15"
      height="15"
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.25 15C0.916667 15 0.625 14.875 0.375 14.625C0.125 14.375 0 14.0833 0 13.75V1.25C0 0.916667 0.125 0.625 0.375 0.375C0.625 0.125 0.916667 0 1.25 0H7.3125V1.25H1.25V13.75H7.3125V15H1.25ZM11.375 11.1458L10.4792 10.25L12.6042 8.125H5.3125V6.875H12.5625L10.4375 4.75L11.3333 3.85417L15 7.52083L11.375 11.1458Z"
        fill="black"
      />
    </svg>
  );
}
