import { ParentProps, createSignal } from 'solid-js';
import { Typography } from '@imagene/components';
import {
  SlidesDragAndDrop,
  SlidesDragAndDropProps,
} from '../../../components/SlidesDragAndDrop';

import classes from './slide-selection.module.css';
import { RunsLimitRangeCompact } from '../../../components/RunLimitRange/RunsLimitRangeCompact';
import { PageCardNavigation } from '../../../components/PageCardNavigation';

export interface SlideSelectionProps
  extends Pick<SlidesDragAndDropProps, 'onFileSelected'> {
  onNext: VoidFunction;
  onBack: VoidFunction;
  files: File[];
}

export function SlideSelection(props: ParentProps<SlideSelectionProps>) {
  const [files, setFiles] = createSignal<File[]>(props.files);
  const isFileSelected = () => Boolean(files().length);
  const name = () =>
    files().length > 1
      ? `${files().length} files selected`
      : files().at(0)?.name;

  const onFileSelect = (files: File[]) => {
    setFiles(files);
    props.onFileSelected(files);
  };

  return (
    <PageCardNavigation
      title="Add slides"
      description="Select file(s)"
      onNext={props.onNext}
      onBack={props.onBack}
      nextBtnId="select_slide_next"
      valid={isFileSelected()}
      footerPadding
      subfooter={
        <div class={classes['runs-limit']}>
          <RunsLimitRangeCompact />
        </div>
      }
    >
      <div class={classes.container}>
        <Typography component="h1" weight="bold" data-cy="add_slide_title">
          Select a file or a batch of files with prepared slides
        </Typography>
        <SlidesDragAndDrop
          onFileSelected={onFileSelect}
          isFileSelected={isFileSelected()}
          fileName={name()}
          multiple
        />
      </div>
    </PageCardNavigation>
  );
}
