import { useTenant } from './use-tenant';
import { DateTime } from 'luxon';
import { createEffect, createSignal } from 'solid-js';

export function useEarlyAccessEnded() {
  const [ended, setEnded] = createSignal(false);
  const config = useTenant();
  createEffect(() => {
    const end_date = config().early_access_end_date;
    if (!end_date) {
      setEnded(false);
    }
    const now = DateTime.now().startOf('day');
    const end = DateTime.fromISO(end_date as unknown as string);

    if (now > end) {
      setEnded(true);
    }
  });

  return ended;
}
