import { ParentProps, JSX } from 'solid-js';

import classes from './SideBySide.module.css';

interface SideBySideProps {
  left?: JSX.Element;
  right?: JSX.Element;
  class?: string;
}

export function SideBySide(props: ParentProps<SideBySideProps>) {
  return (
    <div
      classList={{ [classes.layout]: true, [props.class ?? '']: !!props.class }}
    >
      <div>{props.left}</div>
      <div>{props.right}</div>
    </div>
  );
}
