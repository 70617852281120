import { Show } from 'solid-js';
import { ColumnDef } from '@tanstack/solid-table';
import { useNavigate } from '@solidjs/router';

import { Button } from '@imagene/components';
import { UserActions } from './Actions';
import { UsersTableType } from '.';

// FIXME: should not import external css module file
import classes from '@imagene/components/Table/table.module.css';
import classes2 from './users.module.css';

export function useTableColumns(): ColumnDef<UsersTableType>[] {
  const navigate = useNavigate();
  const columns: ColumnDef<UsersTableType>[] = [
    {
      accessorFn: (row) => ({
        user_id: row.user.user_id,
        email: row.user.email,
        status: row.status,
      }),
      id: 'user',
      meta: {
        headerAlignStart: true,
        headerPadStart: true,
      },
      cell: (info) => {
        const { email, user_id, status } = info.getValue<{
          email: string;
          user_id: string;
          status: string;
        }>();
        return (
          <div class={classes2['table-btn']}>
            <Show
              when={status !== 'inactive'}
              fallback={<div class={classes['email-disabled']}>{email}</div>}
            >
              <Button
                variant="secondary"
                onClick={() => {
                  navigate(`/admin/users/${user_id}`);
                }}
                size="large"
                fullWidth
              >
                {email}
              </Button>
            </Show>
          </div>
        );
      },
      header: 'Email',
    },
    {
      accessorKey: 'status',
      meta: {
        headerAlignStart: true,
      },
      cell: (info) => (
        <div
          classList={{
            [classes['justify-start']]: true,
            [classes.capitalize]: true,
          }}
        >
          {info.getValue<string>()}
        </div>
      ),
      header: 'Status',
    },
    {
      accessorKey: 'name',
      meta: {
        headerAlignStart: true,
      },
      cell: (info) => (
        <div class={classes['justify-start']}>{info.getValue<string>()}</div>
      ),
      header: 'Name',
    },
    {
      accessorKey: 'role',
      meta: {
        headerAlignStart: true,
      },
      cell: (info) => (
        <div
          classList={{
            [classes['justify-start']]: true,
            [classes.capitalize]: true,
          }}
        >
          {info.getValue<string>().toLowerCase()}
        </div>
      ),
      header: 'Role',
    },
    {
      accessorFn: (row) => row,
      id: 'actions',
      cell: (info) => {
        const { status, user } = info.getValue<UsersTableType>();
        return (
          <UserActions
            inactive={status === 'inactive'}
            email={user.email}
            userId={user.user_id}
          />
        );
      },
      header: '',
    },
  ];
  return columns;
}
