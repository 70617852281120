import { JSX, ParentProps } from 'solid-js';
import classes from './progress.module.css';

export type ProgressProps = ParentProps<
  Omit<JSX.ProgressHTMLAttributes<HTMLProgressElement>, 'class'> & {
    loading?: boolean;
  }
>;

export function Progress(props: ProgressProps) {
  return (
    <progress
      {...props}
      classList={{
        [classes.loading]: props.loading,
        [classes.progress]: true,
      }}
    />
  );
}
