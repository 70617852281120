import { Modal, Typography } from '@imagene/components';
import classes from './sidebar.module.css';

interface AbortModalProps {
  onClose: VoidFunction;
  open: boolean;
}

export function AbortModal(props: AbortModalProps) {
  return (
    <Modal
      open={props.open}
      title="Upload in progress"
      contentComponent={
        <div class={classes['modal-text']}>
          <Typography>We are uploading the selected slide.</Typography>
          <Typography>
            Navigating to other page will interrupt the uploading process and
            will require re-uploading the file.
          </Typography>
          <Typography>
            You can open ImageneOI in a new tab in order to continue using the
            platform.
          </Typography>
        </div>
      }
      actionBtnText="Open ImageneOI in a new tab"
      disableOutsideClick={true}
      onAction={() => {
        props.onClose();
        window.open('/', '_blank');
      }}
      onClose={props.onClose}
    />
  );
}
