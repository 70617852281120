import { UiNode, UiNodeInputAttributes } from '@ory/client';
import { ParentProps } from 'solid-js';
import { Input } from '../Input';

export interface AuthInputProps {
  node: UiNode;
  type?: HTMLInputElement['type'];
  class?: string;
  label: string;
  placeholder: string;
  onInput: (value: string) => void;
}

export function AuthInput(props: ParentProps<AuthInputProps>) {
  if (props.node.attributes.node_type !== 'input')
    throw new TypeError(`${props.node.attributes.node_type} is unsupported`);
  const attr = props.node.attributes as UiNodeInputAttributes;
  return (
    <Input
      name={attr.name}
      label={props.label}
      type={props.type ?? attr.type}
      value={attr.value}
      onInput={(e) => props.onInput(e.currentTarget.value)}
      disabled={attr.disabled}
      required={attr.required}
      class={props.class}
      placeholder={props.placeholder}
    />
  );
}
