import { useNavigate } from '@solidjs/router';
import { useQueryClient } from '@tanstack/solid-query';
import { Show } from 'solid-js';
import { authClient } from '../../utils/authClient';
import { useFlow } from '../../utils/use-flow';
import { TwoFAForm } from './TwoFAForm';
import { TwoFAData } from './use2FA';
import { useReturnTo } from '../../utils/use-return-to';
import { SuccessfulNativeLogin } from '@ory/client';
import * as Sentry from '@sentry/browser';
import { useTenant } from '../../utils/use-tenant';
import { useAuth } from '../../utils/session';

export function TwoFA() {
  const returnTo = useReturnTo();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { isAAL2Required } = useAuth();

  if (!isAAL2Required()) {
    navigate('/');
    return;
  }

  useTenant();

  const flow = useFlow({
    pageName: '2fa',
    getter: (flowId) =>
      flowId
        ? authClient.getLoginFlow({ id: flowId }).then((res) => res.data)
        : authClient
            .createBrowserLoginFlow({ aal: 'aal2' })
            .then((res) => res.data),
    submitter: (flowId, data: TwoFAData) =>
      authClient
        .updateLoginFlow({
          flow: flowId,
          updateLoginFlowBody: {
            method: 'totp',
            totp_code: data.totp_code,
            csrf_token: data.csrf_token,
          },
        })
        .then((res) => res.data),
    onSuccess: (data: SuccessfulNativeLogin) => {
      queryClient.invalidateQueries(['whoami']);
      navigate(returnTo ?? '/', { replace: true });
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const identity = data.session.identity!;
      Sentry.addBreadcrumb({
        category: 'auth',
        level: 'info',
        message: `User [${
          (identity.metadata_public as { full_name: string })?.['full_name']
        }] finished 2FA and is now logged in`,
      });
    },
  });

  return (
    <Show when={flow.data()}>
      {(f) => <TwoFAForm flow={f()} onSubmit={flow.submit} />}
    </Show>
  );
}
