import { Link, useSearchParams } from '@solidjs/router';
import {
  Button,
  Table,
  Typography,
  usePagination,
  Search,
} from '@imagene/components';

import classes from './batches.module.css';
import { createSignal } from 'solid-js';
import { useBatches } from './use-batches';
import { createQuery } from '@tanstack/solid-query';
import { Transition } from 'solid-transition-group';
import { useQuotaLimit } from '../../utils/use-quota-limit';

export function Batches() {
  const [params] = useSearchParams();
  const [term, setTerm] = createSignal(params['search'] ?? '');
  const { changePage, pageCount, pageState } = usePagination();
  const { columns, getBatches } = useBatches();
  const onCreateClick = useQuotaLimit();
  const batchQuery = createQuery(
    () => ['batches', pageState().pageIndex, term()],
    () => getBatches(pageState().pageIndex, term()),
    {
      refetchInterval: 1_000 * 60, //1 minutes
    }
  );

  return (
    <Transition appear name="fade">
      <div class="page-margin" classList={{ [classes.batches]: true }}>
        <header>
          <Typography component="h1" weight="bold" class={classes.title}>
            Batches
          </Typography>
          <Search
            onSearch={setTerm}
            placeholder="Find a batch"
            id="batch_query"
            showBtn
          />
          <Link
            href="/analysis"
            onClick={(e) => {
              e.preventDefault();
              onCreateClick();
            }}
          >
            <Button>Create Batch</Button>
          </Link>
        </header>
        <Table
          columns={columns}
          data={batchQuery.data?.data ?? []}
          onPageChange={changePage}
          pageCount={pageCount(batchQuery.data?.count)}
          pageState={pageState()}
          noResultsMsg="There are no processed batches yet."
        />
      </div>
    </Transition>
  );
}
