export default function DownloadIcon() {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.5 16.3125V12.7906H1.1875V16.3125V16.8125H1.6875H16.3125H16.8125V16.3125V12.7906H17.5V16.3125C17.5 16.6212 17.3902 16.8902 17.1402 17.1402C16.8902 17.3902 16.6212 17.5 16.3125 17.5H1.6875C1.37878 17.5 1.10985 17.3902 0.859803 17.1402C0.609758 16.8902 0.5 16.6212 0.5 16.3125ZM9 12.9898L4.27898 8.26875L4.78125 7.76648L7.8027 10.7879L8.65625 11.6415V10.4344V0.5H9.34375V10.4344V11.6415L10.1973 10.7879L13.2188 7.76648L13.721 8.26875L9 12.9898Z"
        fill="currentColor"
        stroke="currentColor"
      />
    </svg>
  );
}
