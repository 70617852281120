export function useDateFormat(
  date: Date | string | undefined | null,
  options: { time?: boolean } = { time: true }
) {
  if (!date) return null;
  return new Date(date).toLocaleDateString('en-US', {
    month: 'short',
    day: '2-digit',
    year: 'numeric',
    hour: options.time ? '2-digit' : undefined,
    minute: options.time ? '2-digit' : undefined,
    second: options.time ? '2-digit' : undefined,
    hour12: false,
  });
}
