import { ColumnDef } from '@tanstack/solid-table';
import { createMutation, useQueryClient } from '@tanstack/solid-query';
import {
  Batch,
  BatchesResponse,
  CreateBatchDto,
} from '@imagene/api-interfaces';
import { Button } from '@imagene/components';
import { useDateFormat } from '@imagene/dates/lib/use-date-format';
import { DownloadLink } from './DownloadLink';
import { StatusIndicator } from './StatusIndicator';
import { axiosClient } from '../../utils/axios';

import classes from './batches.module.css';
import { Link, useNavigate } from '@solidjs/router';
import { datadogLogs } from '@datadog/browser-logs';

export interface UseBatchHookProps {
  onSubmitError: VoidFunction;
}

export function useBatches(props?: UseBatchHookProps) {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const columns: ColumnDef<Batch>[] = [
    {
      header: 'Batch Name',
      accessorFn: (row) => ({ name: row.name, id: row.id }),
      cell: (info) => {
        const { id, name } = info.getValue<{ name: string; id: string }>();
        return (
          <div class={classes['table-btn']}>
            <Link href={`/assays?batch_id=${id}`}>
              <Button variant="secondary" fullWidth size="large">
                {name}
              </Button>
            </Link>
          </div>
        );
      },
    },
    {
      header: 'Files',
      accessorKey: 'files',
      meta: {
        headerAlignEnd: true,
        headerPadEnd: true,
      },
      cell: (info) => {
        return (
          <div
            classList={{
              [classes['justify-end']]: true,
              [classes['padding-end']]: true,
            }}
          >
            {info.getValue<number>()}
          </div>
        );
      },
    },
    {
      header: 'Start date',
      meta: {
        headerAlignStart: true,
      },
      accessorFn: (batch) => useDateFormat(batch.start_date),
      cell: (info) => {
        return (
          <div class={classes['justify-start']}>{info.getValue<string>()}</div>
        );
      },
    },
    {
      header: 'Complete date',
      meta: {
        headerAlignStart: true,
      },
      accessorFn: (batch) => useDateFormat(batch.complete_date) ?? '---',
      cell: (info) => {
        return (
          <div class={classes['justify-start']}>{info.getValue<string>()}</div>
        );
      },
    },
    {
      header: 'Status',
      accessorKey: 'status',
      id: classes.status_header,
      cell: (info) => (
        <StatusIndicator
          status={info.getValue<Batch['status']>()}
          count={info.row.original.files_in_progress}
          total={info.row.original.files}
        />
      ),
    },
    {
      header: 'Results',
      accessorFn: (batch) =>
        batch.status === 'DONE' ? `/api/v1/batch/${batch.id}/export` : null,
      cell: (info) => <DownloadLink href={info.getValue<string>() ?? ''} />,
    },
  ];

  const submitBatch = createMutation(
    (dto: CreateBatchDto) =>
      axiosClient.post('/api/v1/batch', dto).then((res) => res.data),
    {
      onSuccess: (_, data) => {
        datadogLogs.logger.info(
          `Batch analysis started for dataset ${data.dataset_id} with panel ${data.panel_id}`,
          {
            dataset_id: data.dataset_id,
          }
        );

        queryClient.invalidateQueries(['batches', 'quota']);
        navigate('/batches');
      },
      onError: (e, data) => {
        datadogLogs.logger.error(
          `error creating a batch with dataset [${
            data.dataset_id
          }] and panel [${data.panel_id}], error: ${JSON.stringify(e)}`,
          {
            dataset_id: data.dataset_id,
          }
        );

        props?.onSubmitError();
      },
    }
  );

  const getBatches = (page: number, filter: string) =>
    axiosClient
      .get<BatchesResponse>('/api/v1/batch', {
        params: {
          page,
          filter,
        },
      })
      .then(({ data }) => data);

  return {
    submitBatch,
    getBatches,
    columns,
  };
}
