import { Show, createEffect, JSX } from 'solid-js';
import { Portal } from 'solid-js/web';
import { Typography, Button } from '..';

import classes from './modal.module.css';

interface ModalProps {
  open: boolean;
  title?: string;
  text?: string | JSX.Element;
  wide?: boolean;
  onClose?: VoidFunction;
  actionBtnText?: string;
  closeBtnText?: string;
  onAction?: VoidFunction;
  contentComponent?: JSX.Element | JSX.Element[];
  disableOutsideClick?: boolean;
}

export function Modal(props: ModalProps) {
  let modalRef: HTMLDialogElement;

  function onOutsideClick(e: MouseEvent) {
    if (e.target === modalRef && !props.disableOutsideClick) {
      props.onClose?.();
      modalRef.close();
    }
  }

  function toggleModal() {
    props.open ? modalRef?.showModal() : modalRef?.close();
  }

  function onCancel(e: Event) {
    if (props.disableOutsideClick) {
      e.preventDefault();
    }
  }

  createEffect(() => {
    toggleModal();
    modalRef?.scrollTo({ top: 0 });
  });

  return (
    <Portal>
      <dialog
        classList={{ [classes.modal]: true, [classes.wide]: props.wide }}
        onClick={(e) => onOutsideClick(e)}
        ref={(r) => (modalRef = r)}
        onClose={props.onClose}
        onCancel={onCancel}
      >
        <form class={classes['modal-wrapper']} method="dialog">
          <Show when={props.title}>
            <Typography weight="bold" component="h3" class={classes.title}>
              {props.title}
            </Typography>
          </Show>
          <Show when={props.text}>
            <Typography weight="normal" component="div" class={classes.text}>
              {props.text}
            </Typography>
          </Show>
          <Show when={props.contentComponent}>{props.contentComponent}</Show>

          <div class={classes['btn-wrapper']}>
            <Show when={props.onClose}>
              <Button
                variant="secondary"
                onClick={props.onClose}
                class={classes['btn-larger']}
              >
                {props.closeBtnText ?? 'Cancel'}
              </Button>
            </Show>
            <Show when={props.onAction}>
              <Button onClick={props.onAction} class={classes['btn-larger']}>
                {props.actionBtnText ?? 'Okay'}
              </Button>
            </Show>
          </div>
        </form>
      </dialog>
    </Portal>
  );
}
