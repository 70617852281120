import { createEffect, ParentProps } from 'solid-js';
import classes from './digits.module.css';

export function Digit(
  props: ParentProps<{
    value: number | null;
    focus: boolean;
    onDelete: VoidFunction;
    onInput: (value: number) => void;
    onTab: (backward?: boolean) => void;
    onFocus: VoidFunction;
    onPaste: (digits: number[]) => void;
  }>
) {
  let digitInput: HTMLInputElement | null = null;

  createEffect(() => {
    if (props.focus) {
      digitInput?.focus();
    }
  });

  async function onKeyDown(e: KeyboardEvent) {
    if (e.key === 'Tab') {
      props.onTab(e.shiftKey);
    }

    if (e.key === 'Backspace') {
      props.onDelete();
    }

    if (e.key === 'v' && (e.metaKey || e.ctrlKey)) {
      // allow paste
      return;
    }

    if (props.value !== null) {
      e.preventDefault();
      return;
    }
    const num = Number.parseInt(e.key);
    if (Number.isNaN(num)) {
      e.preventDefault();
      return;
    }
  }

  function onInput(val: string) {
    const num = Number.parseInt(val);
    props.onInput(num);
  }

  function onPaste(e: ClipboardEvent) {
    const text = e.clipboardData?.getData('text');
    e.preventDefault();
    if (!text) return;

    props.onPaste(text.trim().split('').map(Number));
  }

  return (
    <input
      type="number"
      autocomplete="off"
      value={props.value ?? ''}
      class={classes.digit}
      onKeyDown={onKeyDown}
      onPaste={onPaste}
      onInput={(e) => onInput(e.currentTarget.value)}
      onClick={props.onFocus}
      ref={(v) => (digitInput = v)}
    />
  );
}
