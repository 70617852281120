import axios from 'axios';
import { useToast } from '@imagene/components';
import * as Sentry from '@sentry/browser';
import { datadogLogs } from '@datadog/browser-logs';
import { ERROR_CAUSE } from '@imagene/error-codes';

export const ignoredPathsForLogs = ['/api/v1/configuration'];

export const axiosClient = axios.create();
let unexpectedErrorToast: number | null = null;
let networkErrorToast: number | null = null;
let notFoundErrorToast: number | null = null;
let notAcceptableErrorToast: number | null = null;
let badRequestErrorToast: number | null = null;

axiosClient.interceptors.response.use(
  (res) => res,
  (error) => {
    const { toast } = useToast();

    if (!error.response) {
      if (networkErrorToast === null) {
        networkErrorToast = toast({
          title: 'Network issue',
          type: 'error',
          onDismiss: () => (networkErrorToast = null),
        });
      }
    } else {
      if (error?.response?.status >= 500) {
        if (unexpectedErrorToast === null) {
          unexpectedErrorToast = toast({
            title:
              'Unexpected technical error. We are trying to fix it. Please try again later.',
            type: 'error',
            onDismiss: () => (unexpectedErrorToast = null),
          });
        }
      } else if (error?.response?.status === 400) {
        if (
          badRequestErrorToast === null &&
          error?.response?.data?.cause === ERROR_CAUSE.WSI_WAS_NOT_UPLOADED
        ) {
          badRequestErrorToast = toast({
            title:
              'Request failed. Please contact our support with the provided reference ID.',
            description: error?.response?.data?.description ?? '',
            type: 'error',
            onDismiss: () => (badRequestErrorToast = null),
          });
        }
      } else if (error?.response?.status === 404) {
        if (notFoundErrorToast === null) {
          notFoundErrorToast = toast({
            title: 'Requested data is not available',
            type: 'error',
            onDismiss: () => (notFoundErrorToast = null),
          });
        }
      } else if (error?.response?.status === 406) {
        if (notAcceptableErrorToast === null) {
          notAcceptableErrorToast = toast({
            title:
              error?.response?.data?.message ?? 'Request is not acceptable',
            description: error?.response?.data?.description ?? '',
            type: 'warning',
            onDismiss: () => (notAcceptableErrorToast = null),
          });
        }
      }
    }

    //only catch exception on sentry if it is not in the ignored paths
    if (!ignoredPathsForLogs.includes(error.config.url)) {
      Sentry.captureException(error);
    }
    datadogLogs.logger.error(
      `axios interceptor error: ${JSON.stringify(error)}`
    );
    return Promise.reject(error);
  }
);
