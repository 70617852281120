import { InclusionCriteriaTable } from './InclusionCriteriaTable';
import type { InclusionCriteriaWithLabel } from '@imagene/api-interfaces';

import classes from './qualityControl.module.css';

interface QualityControlProps {
  criteria: InclusionCriteriaWithLabel[];
}

export function QualityControl(props: QualityControlProps) {
  return (
    <div class={classes.container}>
      <InclusionCriteriaTable results={props.criteria} />
    </div>
  );
}
