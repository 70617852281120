import { createEffect, createSignal, Show, Suspense } from 'solid-js';
import { ExtendedDetailedAnalysisRun } from '@imagene/api-interfaces';
import { Card, Skeleton, Typography } from '@imagene/components';
import { AssayGeneralDataHeader } from './Header';
import { ProgressIndicator } from '../../../components/Progress';
import { useDateFormat } from '@imagene/dates/lib/use-date-format';

import classes from './assayGeneralData.module.css';

interface AssayGeneralDataProps {
  run: ExtendedDetailedAnalysisRun | undefined;
}

export function AssayGeneralData(props: AssayGeneralDataProps) {
  const [showThumbnail, setShowThumbnail] = createSignal(false);
  const [thumbnail, setThumbnail] = createSignal('');

  createEffect(() => {
    // only set once we have a thumbnail url
    if (props.run?.thumbnail_url && !thumbnail()) {
      setThumbnail(props.run.thumbnail_url);
    }
  });

  return (
    <Card
      header={
        <AssayGeneralDataHeader
          slide_id={props.run?.wsi.slide?.identifier}
          accession={props.run?.wsi.slide.accession}
        />
      }
    >
      <div class={classes.slideContent}>
        <Suspense fallback={<SlideInfoSkeleton />}>
          <div class={classes['slide-info']}>
            <div class={classes['slide-info-grid']}>
              <Typography weight="bold">Submitted by</Typography>
              <Typography aria-label="submitter_title">
                {props.run?.submitter_name}
              </Typography>

              <Typography weight="bold">Panel</Typography>
              <span class={classes.panel}>
                <Typography aria-label="panel_title">
                  {props.run?.panel.label}
                </Typography>
                <Typography aria-label="panel_version">
                  {`(${props.run?.panel.version})`}
                </Typography>
              </span>
            </div>

            <div class={classes['slide-info-grid']}>
              <Typography weight="bold">Submission date</Typography>
              <Typography aria-label="submit_date_title">
                {useDateFormat(props.run?.wsi?.upload_start_at)}
              </Typography>

              <Typography weight="bold">Result date</Typography>
              <Typography aria-label="result_date_title">
                {useDateFormat(props.run?.results.at(0)?.created_at) ?? '---'}
              </Typography>
            </div>
          </div>
        </Suspense>
        <div class={classes.slideContentProgress}>
          <Show
            when={props.run}
            fallback={<Skeleton width={265} height={58} />}
          >
            {(run) => (
              <ProgressIndicator
                status={run().status}
                stage={run().stage}
                mode="full"
              />
            )}
          </Show>
          <div />
          <Show when={!showThumbnail() && props.run?.status === 'IN_PROGRESS'}>
            <Skeleton width={176} height={58} />
          </Show>
          <img
            id="thumbnail_img"
            src={thumbnail()}
            alt="slide thumbnail"
            onload={() => setShowThumbnail(true)}
            classList={{ [classes.hidden]: !showThumbnail() }}
          />
        </div>
      </div>
    </Card>
  );
}

export function SlideInfoSkeleton() {
  return (
    <div class={classes['skeleton-slide-info']}>
      <Skeleton width={175} />
      <Skeleton width={175} />
      <Skeleton width={205} />
      <Skeleton width={205} />
    </div>
  );
}
