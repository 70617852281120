import { JSX, ParentProps, Show } from 'solid-js';
import { Transition } from 'solid-transition-group';
import { Button, Typography } from '@imagene/components';
import GreaterThanIcon from '@imagene/components/icons/GreaterThanIcon';

import classes from './page-card-nav.module.css';
import { Subheader } from './Subheader';

export interface PageCardNavigationEvents {
  onBack?: VoidFunction;
  onNext?: VoidFunction;
}

interface PageCardNavigationProps extends PageCardNavigationEvents {
  title: string;
  description: string;
  subheader?: JSX.Element;
  subfooter?: JSX.Element;
  valid?: boolean;
  nextLabel?: string;
  nextBtnId?: string;
  footerPadding?: boolean;
  hideFooterLine?: boolean;
  backLabel?: string;
  headerElement?: JSX.Element;
}

export function PageCardNavigation(
  props: ParentProps<PageCardNavigationProps>
) {
  const isValid = () => props.valid ?? true;

  return (
    <Transition appear name="swipe">
      <section
        classList={{
          [classes.container]: true,
          'page-margin': true,
        }}
      >
        <header class={classes['title-with-bg']}>
          <Typography component="h1" weight="bold">
            {props.title}
          </Typography>
          <GreaterThanIcon />
          <Typography class={classes.subtitle} component="h2" weight="thin">
            {props.description}
          </Typography>
          <Show when={props.headerElement}>{props.headerElement}</Show>
        </header>
        <Show when={props.subheader} fallback={props.children}>
          <div class={classes.wrapper}>
            <Subheader>{props.subheader}</Subheader>
            {props.children}
          </div>
        </Show>
        <footer
          classList={{
            [classes.footer]: true,
            [classes['footer-line']]: !props.hideFooterLine,
            [classes['footer-padding']]:
              !props.subfooter || props.footerPadding,
          }}
        >
          <Show when={props.subfooter}>{props.subfooter}</Show>
          <Show when={props.onBack || props.onNext}>
            <div class={classes['btns-wrapper']}>
              <Show when={props.onBack}>
                <Button
                  id="back"
                  variant="secondary"
                  onClick={props.onBack}
                  class={classes['footer-btn']}
                >
                  {props.backLabel ?? 'Back'}
                </Button>
              </Show>
              <Show when={props.onNext}>
                <Button
                  id={props.nextBtnId ?? 'next'}
                  onClick={props.onNext}
                  disabled={!isValid()}
                  class={classes['footer-btn']}
                >
                  {props.nextLabel ?? 'Next'}
                </Button>
              </Show>
            </div>
          </Show>
        </footer>
      </section>
    </Transition>
  );
}
