import { For } from 'solid-js';
import classes from './range.module.css';

export interface RangeProps {
  range: number[];
  value: number;
  lowInnerLine?: boolean;
  scaleLow?: boolean;
}

export function Range(props: RangeProps) {
  const middleRanges = () => props.range.slice(1, -1);
  const low = () => props.range[0];
  const high = () => props.range.at(-1) ?? 100;
  const value = () => {
    if (props.value < 0) return 0;
    if (props.value > 100) return 100;
    return props.value;
  };

  return (
    <div
      class={classes.range}
      style={{
        '--low': `${low() + (props.scaleLow ? 1 : 0)}%`,
        '--high': `${100 - high()}%`,
        '--value': `${value()}%`,
      }}
    >
      <span
        class={classes.low}
        classList={{
          [classes.highlight]: value() <= low(),
          [classes.inner]: props.lowInnerLine,
        }}
      >
        <span>{low()}%</span>
      </span>
      <span class={classes.middle}></span>
      <span
        class={classes.high}
        classList={{ [classes.highlight]: value() >= high() }}
      >
        <span>{high()}%</span>
      </span>
      <For each={middleRanges()}>
        {(range) => (
          <span class={classes.marker} style={{ '--point-value': `${range}%` }}>
            {range}%
          </span>
        )}
      </For>
    </div>
  );
}
