import {
  createContext,
  createEffect,
  createSignal,
  For,
  ParentProps,
  Show,
  useContext,
} from 'solid-js';
import { Portal } from 'solid-js/web';
import { TransitionGroup } from 'solid-transition-group';
import ErrorIcon from '../icons/ErrorIcon';
import { CloseButton } from '../inputs/CloseButton';
import { Typography } from '../Typography';

import classes from './toast.module.css';

interface ToastInternalProps {
  type?: 'default' | 'warning' | 'error';
  title: string;
  description?: string;
  dismissAfter?: number;
  id?: string;
  onDismiss?: VoidFunction;
  remove: VoidFunction;
}

export type ToastProps = Omit<ToastInternalProps, 'remove'>;
const [toasts, setToasts] = createSignal<ToastProps[]>([]);

const addToast = (toast: ToastProps) => {
  setToasts((prev) => [...prev, toast]);
  return toasts().length - 1;
};

const untoast = (index: number) => {
  setToasts((prev) => {
    prev.splice(index, 1);
    return [...prev];
  });
};

const clearAllToasts = () => {
  setToasts([]);
};

const defaultContext = { toast: addToast, untoast, clearAllToasts };

const ToastsContext = createContext(defaultContext);

export const useToast = () => useContext(ToastsContext);

export function ToastsProvider(props: ParentProps) {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const portalNode = document.getElementById('toasts')!;

  const removeToast = (index: number) => () => untoast(index);

  return (
    <ToastsContext.Provider value={defaultContext}>
      <Portal mount={portalNode}>
        <ul class={classes.container}>
          <TransitionGroup name="swipe">
            <For each={toasts().reverse()}>
              {(toast, i) => (
                <Toast
                  id={i().toString()}
                  {...toast}
                  remove={removeToast(i())}
                />
              )}
            </For>
          </TransitionGroup>
        </ul>
      </Portal>
      {props.children}
    </ToastsContext.Provider>
  );
}

function Toast(props: ParentProps<ToastInternalProps>) {
  createEffect(() => {
    if (props.dismissAfter) {
      setTimeout(() => {
        props.remove();
        if (props.onDismiss) props.onDismiss();
      }, props.dismissAfter);
    }
  });

  return (
    <li
      id={props.id}
      class={classes.toast}
      data-level={props.type ?? 'default'}
    >
      <ErrorIcon />
      <div class={classes.text}>
        <Typography component="div">{props.title}</Typography>
        <Show when={props.description}>
          {(desc) => (
            <Typography component="div" class={classes.description}>
              {desc()}
            </Typography>
          )}
        </Show>
      </div>
      <CloseButton
        onClick={() => {
          props.remove();
          if (props.onDismiss) props.onDismiss();
        }}
      />
    </li>
  );
}
