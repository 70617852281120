import { useNavigate } from '@solidjs/router';
import { useShowLimit } from '../components/RunLimitRange/use-show-limit';
import { CheckQuotaDto, QuotaResponse } from '@imagene/api-interfaces';
import { createMutation, createQuery } from '@tanstack/solid-query';
import { axiosClient } from './axios';
import { useEarlyAccessEnded } from './use-early-access';
import { useToast } from '@imagene/components';
export interface UseCheckQuotaProps {
  successCallback: VoidFunction;
}

export function useCheckQuota(props: UseCheckQuotaProps) {
  return createMutation(
    (dto: CheckQuotaDto) =>
      axiosClient.post('/api/v1/analysis/check-quota', dto),
    {
      onSuccess: props.successCallback,
    }
  );
}

export function useQuotaLimit() {
  const navigate = useNavigate();
  const { values } = useShowLimit();
  const { toast } = useToast();
  const mutation = useCheckQuota({
    successCallback: () => navigate('/analysis'),
  });

  const ended = useEarlyAccessEnded();

  return () => {
    if (ended()) {
      return toast({
        title: 'You have completed the 6 months of the Early Access Program.',
        description:
          'We would be glad to continue the collaboration, please contact our business representatives.',
        type: 'warning',
      });
    }
    if (values().cur >= values().total) {
      mutation.mutate({
        amount: 1,
      });
    } else {
      navigate('/analysis');
    }
  };
}

export function useQuota() {
  return createQuery(
    () => ['quota'],
    () =>
      axiosClient.get<QuotaResponse>('/api/v1/quota').then((res) => res.data),
    {
      refetchInterval: 1_000 * 60, //1 minute
    }
  );
}
