import { Show, createSignal } from 'solid-js';
import {
  ExtendedAnalysisRun,
  GetBatchResponse,
  Paginated,
} from '@imagene/api-interfaces';
import { createQuery } from '@tanstack/solid-query';
import {
  Table,
  Tag,
  Typography,
  usePagination,
  Search,
} from '@imagene/components';
import { useTableColumns } from './use-table-columns';
import { Transition } from 'solid-transition-group';
import { ResultsTableSkeleton } from './ResultsTableSkeleton';
import { Export } from './Export';
import { axiosClient } from '../../utils/axios';

import classes from './assays.module.css';
import { useSearchParams } from '@solidjs/router';

export function Assays() {
  const { pageState, changePage, pageCount, setSearchParams } = usePagination();
  const [searchParams] = useSearchParams();
  const [slideSearch, setSlideSearch] = createSignal(searchParams['slide_id']);
  const [batchId, setBatchId] = createSignal<string | undefined>(
    searchParams['batch_id']
  );

  const columns = useTableColumns();

  const query = createQuery(
    () => ['results', pageState().pageIndex, slideSearch(), batchId()],
    async () => {
      const res = await axiosClient.get<Paginated<ExtendedAnalysisRun>>(
        '/api/v1/analysis',
        {
          params: {
            slide_id: slideSearch(),
            batch_id: batchId(),
            page: pageState().pageIndex,
            extended: true,
          },
        }
      );
      return res.data;
    },
    {
      refetchOnWindowFocus: false,
      refetchInterval: 15000,
    }
  );

  const batch = createQuery(
    () => ['batch', batchId()],
    () =>
      axiosClient
        .get<GetBatchResponse>(`/api/v1/batch/${batchId()}`)
        .then((res) => res.data),
    {
      get enabled() {
        return Boolean(batchId());
      },
      refetchOnWindowFocus: false,
    }
  );

  const clearBatchFilter = () => {
    setBatchId(undefined);
    setSearchParams({ ...searchParams, batch_id: undefined });
  };

  const tableData = () => query.data?.data ?? [];
  const batchName = () => batch.data?.dataset.name ?? '';

  return (
    <Transition appear name="fade">
      <div
        classList={{
          [classes.container]: true,
          'page-margin': true,
        }}
      >
        <header>
          <div class={classes['title-wrapper']}>
            <Typography weight="bold" component="h1" class={classes.title}>
              Results
            </Typography>
            <Show when={batchId()}>
              <Tag
                clearable
                value={batchName()}
                onClick={clearBatchFilter}
                loading={batch.isLoading}
              />
            </Show>
          </div>
          <Search
            id="slide_query"
            placeholder="Enter slide ID"
            onSearch={setSlideSearch}
            searchParamName="slide_id"
            showBtn
          />
          <Export />
        </header>
        <Table
          data={tableData()}
          pageCount={pageCount(query?.data?.count)}
          pageState={pageState()}
          onPageChange={changePage}
          columns={columns()}
          TableSkeleton={<ResultsTableSkeleton />}
          noResultsMsg={
            slideSearch()
              ? 'No records matched your search. Check Slide ID and try again.'
              : 'No results found. Click the "Add Slide" button on the left side menu to submit a new slide.'
          }
          tableRowClass={classes['table-row']}
        />
      </div>
    </Transition>
  );
}
