import { Modal } from '@imagene/components';
import { useTermsOfService } from '../../../utils/use-terms-of-service';

export function TermsOfServiceModal() {
  const { sign, needSignature, link } = useTermsOfService();

  return (
    <Modal
      open={needSignature()}
      title="Update to our Terms of Use"
      text={
        <>
          By clicking "I acknowledge" below, you agree to our updated&nbsp;
          <a target="_blank" href={link()}>
            Terms of Use
          </a>
        </>
      }
      actionBtnText="I acknowledge"
      onAction={() => sign.mutate()}
      disableOutsideClick
    ></Modal>
  );
}
