import { createUniqueId, JSX, ParentProps } from 'solid-js';

import classes from './checkbox.module.css';

type CheckboxExtendProps = {
  label?: string | JSX.Element;
  containerStyle?: JSX.CSSProperties;
};
type CheckboxProps = ParentProps<JSX.InputHTMLAttributes<HTMLInputElement>> &
  CheckboxExtendProps;

export function Checkbox(props: CheckboxProps) {
  const id = props.id ?? createUniqueId();
  return (
    <span
      style={props.containerStyle ?? {}}
      classList={{
        [classes.container]: true,
        [props.class ?? '']: !!props.class,
      }}
    >
      <input
        id={id}
        {...props}
        type="checkbox"
        classList={{
          [classes.checkbox]: true,
        }}
      />
      <label for={id}>{props.label}</label>
    </span>
  );
}
