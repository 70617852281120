import { Link, Typography } from '@imagene/components';
import classes from './invitation-expired.module.css';

export function InvitationExpired() {
  return (
    <div class={classes.container}>
      <Typography color="primary" component="h1">
        Invitation Link Expired
      </Typography>
      <Typography>Invitation link access expires after 24 hours</Typography>
      <div class={classes.text}>
        <Typography>
          Please contact your administrator or
          <br /> Imagene support by email
        </Typography>
        <Link
          class={classes.link}
          href="mailto:support@imagene-ai.com?subject=Invitation link expired"
          target="_blank"
        >
          support@imagene-ai.com
        </Link>
      </div>
    </div>
  );
}
