import { createEffect } from 'solid-js';
import { WsiState } from '@prisma/client';
import UploadWorker from '../../../utils/upload-web-worker?worker';
import { useUpload } from '../../../utils/use-upload';
import {
  UploadError,
  UploadOutput,
  UploadStartEvent,
} from '../../../utils/upload-worker-utils';
import { createStore } from 'solid-js/store';
import { datadogLogs } from '@datadog/browser-logs';
import { useExtendSession } from '../../../utils/use-extend-session';
import { useTenant } from '../../../utils/use-tenant';

export interface WsiUploadState {
  id: string;
  state: WsiState;
  percentage?: number;
}

const worker = new UploadWorker();

export function useWsiUpload(wsi_id: string, file: File) {
  const config = useTenant();
  const [wsi, setWsi] = createStore<WsiUploadState>({
    state: 'INIT',
    id: wsi_id,
    percentage: 0,
  });
  const { setIsUploading } = useUpload();
  useExtendSession();

  createEffect(() => {
    if (wsi.state === 'INIT') {
      const uploadEvent: UploadStartEvent = {
        file,
        wsi_id,
        deidentify: config().deidentify,
      };
      worker.postMessage(uploadEvent);
      setIsUploading(true);
      datadogLogs.logger.info(`starting upload for file ${file?.name}`, {
        wsi_id,
      });
      setWsi('state', 'IN_PROGRESS');
      return;
    }
  });

  worker.onmessage = (ev: MessageEvent<UploadOutput>) => {
    if (!ev.data) return;
    const { type, wsi_id } = ev.data;

    if (wsi.id !== wsi_id) {
      datadogLogs.logger.warn(
        `current wsi [${wsi.id}] is not the same as wsi_id [${wsi_id}] received from worker`,
        {
          wsi_id,
        }
      );
      return;
    }

    switch (type) {
      case 'PROGRESS':
        setWsi('percentage', ev.data.percentage);
        break;
      case 'DONE':
        datadogLogs.logger.log(`wsi [${wsi_id}] upload done`, {
          wsi_id,
        });
        setWsi('state', 'DONE');
        break;
      case 'PART_DONE':
        datadogLogs.logger.info(
          `uploaded part ${ev.data.partNumber}/${ev.data.total} ${
            ev.data.success ? 'successfully' : 'with errors'
          } for wsi [${wsi_id}]`,
          {
            wsi_id,
          }
        );
        break;
      case 'TIMEOUT':
        datadogLogs.logger.error(`wsi [${wsi_id}] upload timedout`, {
          wsi_id,
        });
        setWsi('state', 'TIMEOUT');
        break;
      case 'ERROR':
        handleUploadError(ev.data);
        setWsi('state', 'ERROR');
        break;
      case 'DEIDENTIFY':
        if (ev.data.error) {
          datadogLogs.logger.error(`wsi [${wsi_id}] failed deidentify`, {
            wsi_id,
            error: ev.data.error.message,
          });
        } else {
          datadogLogs.logger.info(`wsi [${wsi_id}] successfully deidentify`, {
            wsi_id,
          });
        }
        break;
      default:
        datadogLogs.logger.warn(`unknown message type [${type}]`, {
          wsi_id,
        });
        break;
    }

    if (type === 'DONE' || type === 'TIMEOUT' || type === 'ERROR') {
      setIsUploading(false);
    }
  };

  return wsi;
}

function handleUploadError(data: UploadError) {
  const { error, wsi_id } = data;

  if (error.url && error.status && error.bodyText !== undefined) {
    let msg = `response error [${error.url}] (status=${error.status}): ${error.bodyText}`;

    //no etag header returned
    if (error.etagMissing) {
      msg = `upload part error [${error.url}] (status=${error.status}): ETag header is missing`;
    }

    datadogLogs.logger.error(
      `wsi [${wsi_id}] upload error`,
      {
        wsi_id,
        bodyText: error.bodyText,
        url: error.url,
        responseStatus: error.status,
        method: error?.method ?? 'Not provided',
      },
      new Error(msg)
    );
  } else {
    datadogLogs.logger.error(
      `wsi [${wsi_id}] upload network error`,
      {
        wsi_id,
      },
      error
    );
  }
}
