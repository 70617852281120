import { createUniqueId, JSXElement, Show } from 'solid-js';
import ArrowIcon from '../icons/ArrowIcon';
import { useOutsideClickListener } from '../hooks/use-outside-click-listener';
import classes from './drawer.module.css';

interface DrawerProps {
  class?: string;
  containerClass?: string;
  styles?: Record<string, string | number>;
  headerLabel?: JSXElement;
  children?: JSXElement;
  open: boolean;
  onClick?: VoidFunction;
  onClose: VoidFunction;
  disable: boolean;
  ariaLabel: string;
}

export function Drawer(props: DrawerProps) {
  let drawerRef: HTMLDivElement;
  const drawerId = createUniqueId();
  const onClick = () => {
    if (props.disable) return;

    props.open ? props.onClose() : props.onClick && props.onClick();
  };

  useOutsideClickListener({
    el: () => drawerRef,
    targetId: drawerId,
    onOutsideClick: props.onClose,
  });

  return (
    <>
      <Show when={props.open}>
        <div
          classList={{
            [classes.overlay]: true,
            'fade-half-enter': props.open,
          }}
        ></div>
      </Show>
      <div
        class={props.containerClass ?? ''}
        classList={{
          [classes.index]: props.open,
          [classes.disable]: props.disable,
        }}
        ref={(r) => (drawerRef = r)}
        id={drawerId}
        aria-label={props.ariaLabel}
        aria-disabled={props.disable}
        aria-expanded={props.open}
      >
        <div class={props.class ?? ''} style={props.styles} onClick={onClick}>
          {props.headerLabel}
          <Show when={!props.disable}>
            <div
              class={classes.arrow}
              classList={{ [classes.rotate]: props.open }}
            >
              <ArrowIcon />
            </div>
          </Show>
        </div>
        <Show when={!props.disable}>
          <div
            aria-label={`${props.ariaLabel}_inner`}
            class={classes.popupContainer}
            classList={{
              [classes.popupClicked]: props.open,
            }}
          >
            {props.children}
          </div>
        </Show>
      </div>
    </>
  );
}
