export default function XIcon() {
  return (
    <svg
      width="14"
      height="15"
      viewBox="0 0 14 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <line
        x1="1"
        y1="-1"
        x2="16.7115"
        y2="-1"
        transform="matrix(0.707107 0.707107 -0.695997 0.718044 0 2)"
        stroke="currentColor"
        stroke-width="2"
        stroke-linecap="round"
      />
      <line
        x1="1"
        y1="-1"
        x2="16.7115"
        y2="-1"
        transform="matrix(0.707107 -0.707107 0.718044 0.695997 1.47607 14.524)"
        stroke="currentColor"
        stroke-width="2"
        stroke-linecap="round"
      />
    </svg>
  );
}
