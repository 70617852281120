export default function CircleXIcon() {
  return (
    <svg
      width="95"
      height="95"
      viewBox="0 0 95 95"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="47.5" cy="47.5" r="45" stroke="#C73434" stroke-width="5" />
      <line
        x1="32"
        y1="65.4645"
        x2="62.4056"
        y2="35.0589"
        stroke="#C73434"
        stroke-width="5"
        stroke-linecap="round"
      />
      <line
        x1="33.5355"
        y1="35"
        x2="63.9411"
        y2="65.4056"
        stroke="#C73434"
        stroke-width="5"
        stroke-linecap="round"
      />
    </svg>
  );
}
