import { JSX, ParentProps } from 'solid-js';

import classes from './typography.module.css';

type TypographyExtendProps = {
  color?: 'primary' | 'secondary' | 'error' | 'default' | 'disabled';
  weight?: 'bold' | 'semi-bold' | 'thin' | 'normal';
  wrap?: string;
};

type TypographyProps = (
  | (ParentProps<JSX.HTMLAttributes<HTMLSpanElement>> & { component?: 'span' })
  | (ParentProps<JSX.HTMLAttributes<HTMLDivElement>> & { component: 'div' })
  | (ParentProps<JSX.HTMLAttributes<HTMLHeadingElement>> & {
      component: 'h1' | 'h2' | 'h3';
    })
  | (ParentProps<JSX.HTMLAttributes<HTMLParagraphElement>> & { component: 'p' })
  | (ParentProps<JSX.HTMLAttributes<HTMLTableCellElement>> & {
      component: 'th';
    })
  | (ParentProps<JSX.HTMLAttributes<HTMLTableCellElement>> & {
      component: 'td';
    })
  | (ParentProps<JSX.HTMLAttributes<HTMLPreElement>> & { component: 'pre' })
) &
  TypographyExtendProps;

export function Typography(props: TypographyProps) {
  const css = () => ({
    [classes.error]: props.color === 'error',
    [classes.primary]: props.color === 'primary',
    [classes.disabled]: props.color === 'disabled',
    [classes.default]: props.color === 'default',
    [classes.bold]: props.weight === 'bold',
    [classes['semi-bold']]: props.weight === 'semi-bold',
    [classes.thin]: props.weight === 'thin',
    [props.class ?? '']: !!props.class,
    [classes.wrap]: Boolean(props.wrap),
    ...props.classList,
  });

  const style = props.wrap
    ? {
        'max-width': props.wrap,
      }
    : {};

  if (props.component === 'div') {
    return (
      <div {...props} class={classes.text} classList={css()} style={style}>
        {props.children}
      </div>
    );
  }

  if (props.component === 'p') {
    return (
      <p {...props} class={classes.text} classList={css()} style={style}>
        {props.children}
      </p>
    );
  }

  if (props.component === 'h1') {
    return (
      <h1 {...props} class={classes.text} classList={css()} style={style}>
        {props.children}
      </h1>
    );
  }

  if (props.component === 'h2') {
    return (
      <h2 {...props} class={classes.text} classList={css()} style={style}>
        {props.children}
      </h2>
    );
  }

  if (props.component === 'h3') {
    return (
      <h3 {...props} class={classes.text} classList={css()} style={style}>
        {props.children}
      </h3>
    );
  }

  if (props.component === 'th') {
    return (
      <th {...props} class={classes.text} classList={css()} style={style}>
        {props.children}
      </th>
    );
  }

  if (props.component === 'td') {
    return (
      <td {...props} class={classes.text} classList={css()} style={style}>
        {props.children}
      </td>
    );
  }

  if (props.component === 'pre') {
    return (
      <pre {...props} class={classes.text} classList={css()} style={style}>
        {props.children}
      </pre>
    );
  }

  return (
    <span
      {...(props as ParentProps<JSX.HTMLAttributes<HTMLSpanElement>>)}
      class={classes.text}
      classList={css()}
      style={style}
    >
      {props.children}
    </span>
  );
}
