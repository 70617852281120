import DownloadIcon from '@imagene/components/icons/DownloadIcon';
import { Link } from '@solidjs/router';

import classes from './batches.module.css';

export function DownloadLink(props: { href: string }) {
  return (
    <Link
      href={props.href}
      download
      classList={{ [classes.disabled]: !props.href }}
    >
      <DownloadIcon />
    </Link>
  );
}
