import { Typography } from '@imagene/components';
import classes from '../error.module.css';

export function UnsupportedBrowserPage() {
  return (
    <div class={classes.container}>
      <Typography component="h1">Update or switch browsers</Typography>
      <div class={classes.text}>
        <Typography>
          We have noticed your browser or its version is unsupported.
        </Typography>
        <Typography>
          Please use one of the supported browsers or update your browser to
          continue using ImageneOI.
        </Typography>
        <Typography component="span" weight="semi-bold">
          Supported browsers:
        </Typography>
        <ul>
          <li>Google Chrome</li>
          <li>Mozilla Firefox</li>
          <li>Apple Safari</li>
          <li>Microsoft Edge</li>
        </ul>
      </div>
    </div>
  );
}
