import { datadogLogs } from '@datadog/browser-logs';
import { ignoredPathsForLogs } from './utils/axios';

if (import.meta.env.VITE_ENV === 'production') {
  datadogLogs.init({
    clientToken: import.meta.env.VITE_DATADOG_CLIENT_TOKEN,
    site: import.meta.env.VITE_DATADOG_SITE,
    service: 'client',
    env: import.meta.env.VITE_ENV,
    forwardConsoleLogs: 'all',
    storeContextsAcrossPages: true, // save user session context in local-storage
    beforeSend: (event) => {
      const ignored = ignoredPathsForLogs.includes(event.view.url);
      return !ignored;
    },
  });
}
