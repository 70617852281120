export function DoubleErrorIcon(props: {
  ariaLabel?: string;
  class?: string;
  classList?: {
    [k: string]: boolean | undefined;
  };
}) {
  return (
    <svg
      aria-label={`${props.ariaLabel}_error`}
      class={props.class}
      classList={props.classList}
      width="25"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="15"
        cy="15"
        r="10"
        fill="white"
        stroke="#6745B1"
        stroke-width="2"
      />
      <circle cx="15" cy="15" r="14" stroke="#6745B1" stroke-width="2" />
      <path
        d="M15 16L15 10"
        stroke="#6745B1"
        stroke-width="2"
        stroke-linecap="round"
      />
      <path
        d="M15 20H15"
        stroke="#6745B1"
        stroke-width="2"
        stroke-linecap="round"
      />
    </svg>
  );
}
