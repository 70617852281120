import { Progress, Typography } from '@imagene/components';
import { Show } from 'solid-js';
import classes from './run-limit-range.module.css';
import { useShowLimit } from './use-show-limit';
import { useEarlyAccessEnded } from '../../utils/use-early-access';

export function RunsLimitRange() {
  const { show, values } = useShowLimit();
  const ended = useEarlyAccessEnded();

  const limit = () =>
    values().cur && values().total ? values().cur >= values().total : false;
  const title = () =>
    ended()
      ? 'You have completed the 6 months of the Early Access Program.'
      : limit()
      ? `Thank you for participating in the Early Access Program. You have reached a limit of ${
          values().total
        } slides.`
      : 'Free slide analyses by Early Access Program';

  const cur = () => (ended() ? 1 : values().cur);
  const total = () => (ended() ? 1 : values().total);

  return (
    <Show when={show() || ended()}>
      <div class={classes.container}>
        <div class={classes.text}>
          <Typography color="primary" class={classes.title}>
            {title()}
          </Typography>
          <Show when={!limit() && !ended()}>
            <Typography color="primary" class={classes.counter}>
              {values().cur} / {values().total}
            </Typography>
          </Show>
        </div>
        <Progress max={total()} value={cur()} />
        <Show when={limit() || ended()}>
          <Typography color="primary" class={classes.center}>
            We would be glad to continue the collaboration, please contact our
            business representatives
          </Typography>
        </Show>
      </div>
    </Show>
  );
}
