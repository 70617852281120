import { RecoveryFlow } from '@ory/client';
import { ParentProps, createEffect } from 'solid-js';
import { Button, Digits, Typography } from '@imagene/components';
import { RegistrationFormData, useCode, AMOUNT_OF_DIGITS } from './useCode';
import { getGlobalError } from '../../utils/auth';
import classes from './code.module.css';

export interface CodeFormProps {
  flow: RecoveryFlow;
  onSubmit: (data: RegistrationFormData) => void;
}

export function CodeForm(props: ParentProps<CodeFormProps>) {
  const { onDigitsComplete, data, isValid } = useCode(props.flow.ui.nodes);
  let submit: HTMLButtonElement | null = null;

  createEffect(() => {
    if (submit && isValid()) {
      submit.focus();
    }
  });

  return (
    <div class={classes.form}>
      <Typography component="p">
        Enter the 6 digit code from your registration email
      </Typography>
      <Digits
        id="recovery-digits"
        class="align-start"
        amount={AMOUNT_OF_DIGITS}
        onComplete={onDigitsComplete}
        error={getGlobalError(props.flow.ui)}
      />
      <Button
        id="submit"
        ref={(v) => (submit = v)}
        class="align-start"
        disabled={!isValid()}
        onClick={() => props.onSubmit(data)}
      >
        Submit
      </Button>
    </div>
  );
}
