import { Accessor, ParentProps, createContext, useContext } from 'solid-js';
import { createStore } from 'solid-js/store';
import { makePersisted } from '@solid-primitives/storage';
import { useBiomarkerQuery } from './use-biomarker-query';
import { DEFAULT_THRESHOLDS } from '@imagene/biomarker-results';

interface ThresholdContext {
  values: {
    egfr: number[];
    alk: number[];
  };
  current: Accessor<number[]>;
  setThresholds: (newThresholds: number[]) => void;
}

const ThresholdContext = createContext<ThresholdContext>(
  {} as ThresholdContext
);

export const useThresholds = () => useContext(ThresholdContext);

export function ThresholdsProvider(props: ParentProps) {
  const bioQuery = useBiomarkerQuery();
  const [thresholds, setInternalThresholds] = makePersisted(
    createStore(DEFAULT_THRESHOLDS),
    {
      name: 'thresholds',
      storage: localStorage,
    }
  );

  const current = () =>
    bioQuery.selectedBiomarker() === 'egfr'
      ? [...thresholds.egfr]
      : [...thresholds.alk];

  const setThresholds = (newThresholds: number[]) => {
    setInternalThresholds(
      bioQuery.selectedBiomarker() === 'egfr' ? 'egfr' : 'alk',
      newThresholds
    );
  };

  return (
    <ThresholdContext.Provider
      value={{ values: thresholds, current, setThresholds }}
    >
      {props.children}
    </ThresholdContext.Provider>
  );
}
