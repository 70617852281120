import { ParentProps, JSX } from 'solid-js';
import classes from './button-card.module.css';

export type ButtonCardProps = ParentProps<JSX.HTMLAttributes<HTMLDivElement>>;

export function ButtonCard(props: ButtonCardProps) {
  return (
    <div
      classList={{
        ...props.classList,
        [props.class ?? '']: Boolean(props.class),
        [classes.card]: true,
        [classes.clickable]: Boolean(props.onClick),
      }}
      onClick={props.onClick}
    >
      {props.children}
    </div>
  );
}
