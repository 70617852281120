import { Button, Link } from '@imagene/components';
import classes from './assays.module.css';
import { useSearchParams } from '@solidjs/router';

export function Export() {
  const [searchParams] = useSearchParams();
  const params = () => new URLSearchParams(searchParams);

  return (
    <Link
      href={`/api/v1/analysis/export?${params()}`}
      class={classes.export}
      download
    >
      <Button>Export</Button>
    </Link>
  );
}
