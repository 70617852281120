import {
  createMemo,
  createUniqueId,
  mergeProps,
  ParentProps,
  Show,
  splitProps,
} from 'solid-js';
import { normalizeProps, useMachine } from '@zag-js/solid';
import * as tooltip from '@zag-js/tooltip';

import './tooltip.module.css';

export type TooltipProps = Pick<
  tooltip.Context,
  | 'closeDelay'
  | 'closeOnEscape'
  | 'closeOnPointerDown'
  | 'interactive'
  | 'openDelay'
  | 'open'
  | 'onOpenChange'
  | 'disabled'
> & { text: string; placement?: tooltip.Placement };

export type Placement = tooltip.Placement;

export function Tooltip(props: ParentProps<TooltipProps>) {
  const [local, machine] = splitProps(props, ['text', 'children']);
  const context = mergeProps(machine, {
    positioning: { placement: machine.placement },
  });
  const [state, send] = useMachine(
    tooltip.machine({
      id: createUniqueId(),
    }),
    { context }
  );

  const api = createMemo(() => tooltip.connect(state, send, normalizeProps));

  return (
    <span>
      <button {...api().getTriggerProps()}>{local.children}</button>
      <Show when={api().open}>
        <div {...api().getPositionerProps()}>
          <div {...api().getContentProps()}>{local.text}</div>
        </div>
      </Show>
    </span>
  );
}
