import { JSX, ParentProps, Show } from 'solid-js';
import { Typography } from '../../Typography';
import { Spinner } from '../../Spinner';

import classes from './button.module.css';

type ButtonSize = 'small' | 'medium' | 'large';

type ButtonProps = ParentProps<JSX.ButtonHTMLAttributes<HTMLButtonElement>> & {
  variant?:
    | 'default'
    | 'secondary'
    | 'confirm'
    | 'default-light'
    | 'confirm-light';
  classList?: Record<string, boolean | undefined>;
  loading?: boolean;
  size?: ButtonSize;
  fullWidth?: boolean;
};

export function Button(props: ButtonProps) {
  const variant = props.variant ?? 'default';
  const size = props.size ?? 'medium';
  return (
    <button
      {...props}
      class={classes.btn}
      classList={{
        [props.class ?? '']: !!props.class,
        [classes.secondary]: variant === 'secondary',
        [classes.confirm]: variant === 'confirm',
        [classes.small]: size === 'small',
        [classes.medium]: size === 'medium',
        [classes.large]: size === 'large',
        [classes.loading]: props.loading,
        [classes['full-width']]: props.fullWidth,
        [classes['default-light']]: variant === 'default-light',
        [classes['confirm-light']]: variant === 'confirm-light',
      }}
      disabled={props.disabled}
    >
      <Typography
        weight={variant === 'default' ? 'semi-bold' : 'normal'}
        title={props.children?.toString()}
      >
        {props.children}
      </Typography>
      <Show when={props.loading}>
        <Spinner />
      </Show>
    </button>
  );
}
