export default function Graph() {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="1"
        y="1"
        width="30"
        height="30"
        rx="1.5"
        stroke="currentColor"
        stroke-width="2"
      />
      <path
        d="M30.4957 17.0013L23.9957 8.50134L20.4957 23.5013L13.9957 15.5013L10.9957 23.0013L5.49565 23.5013L1.49573 30.5013"
        stroke="currentColor"
        stroke-width="2"
      />
    </svg>
  );
}
