import {
  Accessor,
  ParentProps,
  createContext,
  createEffect,
  createSignal,
  useContext,
} from 'solid-js';
import { BiomarkerQuery, useBiomarkerQuery } from './use-biomarker-query';
import { ExtendedAnalysisRun, ResultType } from '@imagene/api-interfaces';
import {
  displayProbability,
  getAccuracy,
  AccuracyType,
} from '@imagene/biomarker-results';
import { MolecularResultTableType } from './MolecularResults';
import { useThresholds } from './use-thresholds';

export interface ValidationDetails {
  slides: ExtendedAnalysisRun[] | undefined;
}

export interface SlideGenePerformance {
  name: string;
  value: number;
  label: string;
  accuracy: AccuracyType | null;
  excluded: boolean;
}

interface ValidationDetailsContext {
  slides: Accessor<SlideGenePerformance[]>;
  positives: Accessor<SlideGenePerformance[]>;
  negatives: Accessor<SlideGenePerformance[]>;
  equivocal: Accessor<SlideGenePerformance[]>;
  isEmpty: Accessor<boolean>;
  numNoMolResults: Accessor<number>;
  molecularResultsTableData: Accessor<MolecularResultTableType[]>;
  noDataThresholds: Accessor<number[]>;
}

const ValidationDetailsContext = createContext<ValidationDetailsContext>(
  {} as ValidationDetailsContext
);

export const useValidation = () => useContext(ValidationDetailsContext);

export function ValidationProvider(props: ParentProps<ValidationDetails>) {
  const bioQuery = useBiomarkerQuery();
  const [internalSlides, setSlides] = createSignal(props.slides ?? []);
  const thresholds = useThresholds();

  const noDataThresholds = () =>
    bioQuery.selectedBiomarker() === 'egfr' ? [2, 80] : [1, 75];

  createEffect(() => setSlides(props.slides ?? []));

  const slides = () =>
    internalSlides()
      .map<SlideGenePerformance>((slide) => ({
        excluded: Boolean(slide.exclude_validation),
        name: slide.wsi.slide.identifier,
        value: getCurrentProbability(bioQuery.selectedBiomarker(), slide),
        label: getProbabilityLabel(
          bioQuery.selectedBiomarker(),
          noDataThresholds(),
          slide
        ),
        accuracy: getAccuracy(
          getCurrentResult(bioQuery.selectedBiomarker(), slide)?.official_result
            ?.value,
          getCurrentProbability(bioQuery.selectedBiomarker(), slide),
          {
            positive: thresholds.current()[1],
            negative: thresholds.current()[0],
          }
        ) as AccuracyType,
      }))
      .filter((sp) => Boolean(sp.value));

  const isEmpty = () => slides().length === 0;

  const numNoMolResults = () =>
    internalSlides().filter(
      (s) =>
        !s.exclude_validation &&
        s.results
          .flatMap((r) => r.official_result ?? {})
          .every((res) => !res.value)
    ).length;

  const positives = () =>
    slides().filter((s) => s.value >= thresholds.current()[1]);

  const negatives = () =>
    slides().filter((s) => s.value <= thresholds.current()[0]);

  const equivocal = () =>
    slides().filter(
      (s) =>
        s.value > thresholds.current()[0] && s.value < thresholds.current()[1]
    );

  const molecularResultsTableData = () =>
    internalSlides()
      .map((s) => {
        const egfr_result_id = s.results.find((r) =>
          r.biomarker.label.includes('EGFR')
        )?.id;
        const egfr_result = s.results?.find((r) => r.id === egfr_result_id)
          ?.official_result?.value;
        const alk_result_id = s.results.find((r) =>
          r.biomarker.label.includes('ALK')
        )?.id;
        const alk_result = s.results?.find((r) => r.id === alk_result_id)
          ?.official_result?.value;
        const assay = s.results.find((r) => Boolean(r?.official_result?.assay))
          ?.official_result?.assay;

        return {
          excluded: Boolean(s.exclude_validation),
          results: s.results,
          //NOTE: we trim because whitespace may be present from old slides (new slides are created with trim)
          slide_id: s.wsi.slide.identifier.trim(),
          run_id: s.id,
          egfr_result,
          alk_result,
          assay,
        };
      })
      .sort(sortSlidesByName);

  return (
    <ValidationDetailsContext.Provider
      value={{
        slides,
        positives,
        negatives,
        equivocal,
        isEmpty,
        numNoMolResults,
        molecularResultsTableData,
        noDataThresholds,
      }}
    >
      {props.children}
    </ValidationDetailsContext.Provider>
  );
}

function getCurrentProbability(
  gene: BiomarkerQuery,
  slide: ExtendedAnalysisRun
) {
  const prob = getCurrentResult(gene, slide)?.raw_probability;
  return displayProbability(prob);
}

function getCurrentResult(
  gene: BiomarkerQuery,
  slide: ExtendedAnalysisRun
): ResultType | undefined {
  return gene === 'egfr'
    ? slide.results.find((r) => r.biomarker.label.includes('EGFR'))
    : slide.results.find((r) => r.biomarker.label.includes('ALK'));
}

function getProbabilityLabel(
  gene: BiomarkerQuery,
  cap: number[],
  slide: ExtendedAnalysisRun
) {
  const prob = getCurrentProbability(gene, slide);
  const minCap = cap[0];
  const maxCap = cap[1];

  if (prob <= minCap) {
    return `<${minCap}%`;
  }
  if (prob >= maxCap) {
    return `>${maxCap}%`;
  }

  return prob.toLocaleString() + '%';
}

function sortSlidesByName(
  a: MolecularResultTableType,
  b: MolecularResultTableType
) {
  if (a.slide_id < b.slide_id) {
    return -1;
  }
  if (a.slide_id > b.slide_id) {
    return 1;
  }

  return 0;
}
