import { JSX, ParentProps, createRenderEffect, splitProps } from 'solid-js';
import { Portal } from 'solid-js/web';

import classes from './dialog.module.css';

export type DialogProps = Omit<
  ParentProps<JSX.DialogHtmlAttributes<HTMLDialogElement>>,
  'class'
>;

export function Dialog(props: DialogProps) {
  let dialog: HTMLDialogElement;
  const [dialogProps, directDialogProps] = splitProps(props, ['open']);

  createRenderEffect(() => {
    dialogProps.open ? dialog?.showModal() : dialog?.close();
  });

  return (
    <Portal>
      <dialog
        {...directDialogProps}
        ref={(d) => (dialog = d)}
        class={classes.dialog}
      >
        {props.children}
      </dialog>
    </Portal>
  );
}
